import Legends from "./Legends";
import ProfileCard from "./ProfileCard";
import ProfileCardSquare from "./ProfileCardSquare";
import styles from "./styles/PageGrid.module.css";
import stylesBg from "./styles/PrintIndex.module.css";
import React from "react";
import classNames from "classnames";

function PageGrid({
    page_no,
    data,
    per_page,
    page_heading,
    gender,
    PAGE_NO_OFFSET,
    SERIAL_NO_OFFSET,
}) {
    console.log("Page data", data);
    return (
        <div className={styles.page_wrapper}>
            <div className={classNames(styles.wrapper, stylesBg.bg)}>
                <div className={styles.grid_wrapper}>
                    {data.map((item, index) => (
                        <ProfileCardSquare
                            data={item}
                            number={
                                (page_no - 1) * per_page +
                                index +
                                1 +
                                SERIAL_NO_OFFSET
                            }
                        />
                    ))}
                </div>
                {/* <span className={styles.website}>www.vikasmadalmulund.com</span> */}
                <span className={styles.legends}>
                    <Legends />
                </span>

                <div className={styles.top_left_bar}>
                    <img
                        // src="https://api.qureal.com/passets/16480c10-a1de-4546-9a03-68323ab3b412.png"
                        src="https://matrimonialapi.qureal.com/assets/f187d1a4-8bd5-4ec6-bd8d-4f907c787f7b"
                        alt="LOGO"
                        className={styles.logo}
                    />
                    <span
                        className={styles.title}
                        style={{
                            backgroundColor:
                                gender === "Male" ? "blue" : "hotpink",
                        }}
                    >
                        {page_heading}
                    </span>
                    <span className={styles.page_no}>
                        {" "}
                        {page_no + PAGE_NO_OFFSET}
                    </span>
                </div>
            </div>
        </div>
    );
}

export default PageGrid;
