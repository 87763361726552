import React from "react";
import { getImageURL } from "../_helpers/files";
import { useMediaQuery } from "@uidotdev/usehooks";

function CenterCard({ width = "350px", children, height = "auto" }) {
    const isMobile = useMediaQuery("only screen and (max-width : 768px)");
    return (
        <div
            style={{
                width: "100%",
                // height: "auto",
                height: height,
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                // flexDirection: "column",
                // justifyContent: "center",
                justifyContent: isMobile ? "center" : "space-between",
                padding: isMobile ? 0 : "30px 50px",
                alignItems: isMobile ? "center" : "flex-start",
                gap: "20px",
                backgroundColor: "#aa0c34",
                // backgroundImage: `url("https://matrimonialapi.qureal.com/assets/f761c2b6-989a-40e8-b7d8-0dbf23e5d84c")`,
                backgroundImage: `url("https://matrimonialapi.qureal.com/assets/19db7211-c50b-47e4-af34-f6d43c9cd570")`,
                backgroundPosition: "bottom",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
            }}
        >
            <img
                // src="https://api.qureal.com/passets/16480c10-a1de-4546-9a03-68323ab3b412.png"
                src="https://matrimonialapi.qureal.com/assets/f187d1a4-8bd5-4ec6-bd8d-4f907c787f7b"
                alt="LOGO"
                style={{
                    width: "auto",
                    maxWidth: isMobile ? "15em" : "20em",
                    height: isMobile ? "6em" : "10em",
                    cursor: "default",
                    objectFit: "contain",
                    marginRight: "50px",
                    padding: "7px 0px",
                }}
            />
            <div
                style={{
                    backgroundColor: "white",
                    padding: "20px",
                    borderRadius: "7px",
                    width: width,
                    maxWidth: "100%",
                    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
                    marginBottom: "20px",
                }}
            >
                {children}
            </div>
        </div>
    );
}

export default CenterCard;
