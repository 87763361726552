import React, { useEffect, useState } from "react";
import { Form, Dropdown, Input, TextArea } from "semantic-ui-react";
import { REJECTION_REASONS } from "../constants";

function RejectionReasonDropdown({ value, setValue }) {
    const val = REJECTION_REASONS.includes(value) ? value : "Other";
    const [other_reason, setOtherReason] = useState("");

    useEffect(() => {
        if (other_reason) {
            setValue(other_reason);
        }
    }, [other_reason]);

    console.log("Value in rd", value);
    console.log("Val in rd", val);

    return (
        <div>
            <Form.Field>
                <label>Rejection reason</label>
                <Dropdown
                    placeholder="Please selct rejection reason"
                    value={val}
                    onChange={(e, { value }) => {
                        if (value === "Other") {
                            setValue("");
                        } else {
                            setValue(value);
                        }
                    }}
                    selection
                    options={REJECTION_REASONS.map((item) => ({
                        key: item,
                        text: item,
                        value: item,
                    }))}
                />
            </Form.Field>
            {val === "Other" && (
                <Form.Field>
                    <label>Please specify the reason</label>
                    <TextArea
                        rows={4}
                        value={other_reason}
                        onChange={(e) => setOtherReason(e.target.value)}
                    />
                </Form.Field>
            )}
        </div>
    );
}

export default RejectionReasonDropdown;
