import React from "react";
import Screen from "../commons/Screen";
import AllProfiles from "../showcase/AllProfiles";
import { useItemsFilter } from "../_actions/items";
import ProfileCards from "../showcase/ProfileCards";
import { useUpdateItem } from "../_actions/mutations/items";

function DispatchedOrdered() {
    const { isLoading, isSuccess, data } = useItemsFilter(
        "directus_users",
        "?fields=id,qd_payments.*,matrimonial_profiles.*,delivery_address.*&filter[suchi_book_order][_eq]=dispatched"
    );

    // (data);

    return (
        <Screen
            // title="Suchi Book Orders Dispatched"
            wrapper_styles={{
                // marginTop: "-30px",
                padding: 0,
            }}
        >
            {" "}
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                }}
            >
                {data?.map((ele) => {
                    return (
                        <ProfileCards
                            item={ele?.matrimonial_profiles[0]}
                            // orderOn={ele.payment}
                            user_id={ele?.id}
                            name={ele?.suchi_name}
                            address={
                                ele?.delivery_address
                                    ? ele?.delivery_address[0]
                                    : {}
                            }
                            mode={"dispatched"}
                        />
                    );
                })}{" "}
            </div>
        </Screen>
    );
}

export default DispatchedOrdered;
