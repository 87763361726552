import React from "react";
// import styles from "./styles/AllProfiles.module.css";
import styles from "../showcase/styles/AllProfiles.module.css";
import { getProfilePhoto } from "../_helpers/files";
import { Button, Icon } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { useUpdateItem } from "../_actions/mutations/items";
import AddressView from "./AddressView";
import { getTime } from "../_helpers/dt";

export default function SubscriptionCard({
    order,
    user,
    profile,
    payment = {},
    mode = "pending",
}) {
    const navigate = useNavigate("/");

    const updateStatus = useUpdateItem("qd_order");

    const order_status = order.order_status;

    const markDispatched = () => {
        updateStatus.mutate({
            id: order?.id,
            data: { order_status: "dispatched" },
        });
    };

    const markDelivered = () => {
        updateStatus.mutate({
            id: order?.id,
            data: { order_status: "delivered" },
        });
    };

    return (
        <>
            <div className={styles.item_wrapper}>
                <div className={styles.item}>
                    <img
                        src={getProfilePhoto(
                            profile?.main_photo?.id,
                            profile?.gender
                        )}
                        alt="profile"
                    />
                    <div>
                        <h4>
                            {user?.first_name} {user?.last_name}
                        </h4>
                        {payment.payment_id && (
                            <p>
                                Razorpay Payment ID: <b>{payment.payment_id}</b>
                            </p>
                        )}
                        <p>
                            Razorpay Order ID: <b>{payment.order_id}</b>
                        </p>
                        <p>
                            Order Date: <b>{getTime(order.start_date, "od")}</b>
                        </p>
                    </div>
                </div>

                <div
                    style={{
                        backgroundColor: "#DBDBDB",
                        display: "flex",
                        padding: "0.5em",
                    }}
                >
                    {profile?.id && (
                        <Button
                            primary
                            onClick={() => {
                                navigate(`/profile/${profile?.id}`);
                            }}
                            icon
                            labelPosition="left"
                        >
                            <Icon name="eye" />
                            View
                        </Button>
                    )}
                    {order_status === "ordered" && (
                        <Button
                            color="green"
                            loading={updateStatus.isLoading}
                            onClick={markDispatched}
                            icon
                            labelPosition="left"
                        >
                            <Icon name="truck" />
                            Dispatch
                        </Button>
                    )}
                    {order_status === "dispatched" && (
                        <Button
                            positive
                            onClick={markDelivered}
                            loading={updateStatus.isLoading}
                            icon
                            labelPosition="left"
                        >
                            <Icon name="checkmark" />
                            Mark delivered
                        </Button>
                    )}
                </div>
            </div>
        </>
    );
}
