import React from "react";
import { useCurrentRole } from "../_actions/roles";
import { useCurrentUser } from "../_actions/auth";
import { useItemsFilter } from "../_actions/items";
import { useUpdateItem } from "../_actions/mutations/items";
import { Checkbox } from "semantic-ui-react";
import Screen from "../commons/Screen";

function AdminSettings() {
    const { role_name } = useCurrentRole();
    const user_me = useCurrentUser();
    const { data: settingInfo } = useItemsFilter(
        "admin_setting",
        "?fields=isactive_search,isactive_suchibook,enable_search_subscription"
    );

    const updateStatus = useUpdateItem("admin_setting");

    const handleOnChange = (e, data) => {
        updateStatus.mutate({ id: "", data: { [data.name]: data.checked } });
    };

    return (
        <Screen title="Admin Settings">
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                }}
            >
                <Checkbox
                    toggle
                    // checked={false}
                    name="isactive_suchibook"
                    checked={settingInfo?.isactive_suchibook}
                    label="Enable Suchi Book Orders"
                    onChange={handleOnChange}
                />
                <Checkbox
                    color="success"
                    toggle
                    checked={settingInfo?.enable_search_subscription}
                    // checked={false}
                    label="Enable Searh Subscription"
                    name="enable_search_subscription"
                    onChange={handleOnChange}
                />
                {settingInfo?.enable_search_subscription && (
                    <Checkbox
                        color="success"
                        toggle
                        checked={settingInfo?.enable_gender_switch}
                        // checked={false}
                        label="Enable Gender Switch"
                        name="enable_gender_switch"
                        onChange={handleOnChange}
                    />
                )}
            </div>
        </Screen>
    );
}

export default AdminSettings;
