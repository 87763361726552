import axios from "axios";
import { history } from "./history";

import { BASE_URL, EXTERNAL_URL } from "../constants";

//===================================================
export function saveTokens(data) {
  localStorage.setItem("access_token", data?.access_token);
  localStorage.setItem("refresh_token", data?.refresh_token);
}

export function restoreAccessToken() {
  return localStorage.getItem("access_token") || undefined;
}

export function restoreRefreshToken() {
  return localStorage.getItem("refresh_token") || undefined;
}

export function resetTokens() {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
}
//=====================================================

export function saveUser(data) {
  //   console.log(data);
  localStorage.setItem("userid", JSON.stringify(data?.id));
  localStorage.setItem("user", JSON.stringify(data));
}

export function restoreUserId() {
  return localStorage.getItem("userid") || undefined;
}

export function restoreUser() {
  return JSON.parse(localStorage.getItem("user") || {});
}
//=====================================================

axios.defaults.baseURL = BASE_URL;

export const externalAxios = axios.create({
  baseURL: EXTERNAL_URL,
});

export const publicAxios = axios.create({
  baseURL: BASE_URL,
});

publicAxios.interceptors.response.use(
  function (res) {
    return res.data;
  },
  function (err) {
    return Promise.reject(err);
  }
);

export const secureAxios = axios.create({
  baseURL: BASE_URL,
  headers: {},
});

secureAxios.interceptors.response.use(
  function (res) {
    return res.data;
  },
  function (err) {
    // return Promise.reject(err);
    const originalReq = err.config;

    if (
      err.response.status === 401 &&
      originalReq.url.endsWith("/auth/refresh")
    ) {
      console.log("Unauthorized, sending to login");
      // router.push("/login")
      history.push("/login");
      return Promise.reject(err);
    }

    if (err.response.status === 401 && !originalReq._retry) {
      console.log("Unauthorized, try refreshing token");
      originalReq._retry = true;

      const refresh_token = restoreRefreshToken();

      return axios
        .post("/auth/refresh", {
          refresh_token: refresh_token,
        })
        .then((res) => {
          console.log("Toke refresh response", res);
          if (res.statusText === "OK") {
            const data = res.data?.data;

            saveTokens(data);

            setAuthToken(data.access_token);
            return secureAxios({
              ...originalReq,
              headers: {
                ...originalReq.headers,
                Authorization: `Bearer ${data?.access_token}`,
              },
            });
          }
        });
    }
    return Promise.reject(err);
  }
);

export function setAuthToken(token) {
  if (token) {
    secureAxios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete secureAxios.defaults.headers.common["Authorization"];
  }
}
