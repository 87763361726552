import React from "react";
import CandidateCard from "./CandidateCard";
import { useCurrentUser } from "../_actions/auth";
import { useItemsFilter } from "../_actions/items";

function CandidateList({ data }) {
    const me = useCurrentUser();
    const { data: users_fav } = useItemsFilter(
        "junction_directus_users_qd_matrimonial_profile",
        `?filter[directus_users_id][_eq]=${me?.id}`,
        !!me?.id
    );

    const { data: users_blocked } = useItemsFilter(
        "junction_directus_users_qd_matrimonial_profile_1",
        `?filter[directus_users_id][_eq]=${me?.id}`,
        !!me?.id
    );

    const checkFav = (ele) => {
        const arr = users_fav?.filter(
            (e) => e?.qd_matrimonial_profile_id === ele
        );
        return arr?.length > 0 ? arr[0].id : false;
    };

    const checkBlock = (ele) => {
        const arr = users_blocked?.filter(
            (e) => e?.qd_matrimonial_profile_id === ele
        );
        return arr?.length > 0 ? true : false;
    };

    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(22em, 1fr))",
                gap: "10px",
                alignItems: "start",
                paddingBottom: "20px",
            }}
        >
            {data?.map((item, index) => {
                return (
                    <CandidateCard
                        data={item}
                        key={index}
                        currUser={me?.id}
                        my_fav={checkFav(item.id)}
                    />
                );
            })}
        </div>
    );
}

export default CandidateList;
