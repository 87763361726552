import React, { useState } from "react";
import Screen from "../commons/Screen";
import AllProfiles from "../showcase/AllProfiles";
import DownloadProfilesButton from "./DownloadProfilesButton";
import { Input } from "semantic-ui-react";
import { useDebounce } from "@uidotdev/usehooks";
import ColdMailSendProfileBtn from "../mail/ColdMailSendProfileBtn";

function CandidatesDeactivated() {
    const [search_query, setSearchQuery] = useState("");
    const debounced_search_query = useDebounce(search_query, 300);
    return (
        <Screen
            title="Deactivated Candidates"
            wrapper_styles={{
                marginTop: "-30px",
            }}
            actions={[
                <Input
                    icon="search"
                    value={search_query}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />,
                <ColdMailSendProfileBtn
                    status={false}
                    // filter={"&filter[user][is_reactivated][_eq]=false"}
                    filter={`&filter={"_and":[{"date_created":{"_lt":"2024-07-01"}},{"user":{"is_reactivated": {"_eq":false}}}]}`}
                />,
                <DownloadProfilesButton
                    // status="Active"
                    status={false}
                    // filter={"&filter[user][is_reactivated][_eq]=false"}
                    filter={`&filter={"_and":[{"date_created":{"_lt":"2024-07-01"}},{"user":{"is_reactivated": {"_eq":false}}}]}`}
                />,
            ]}
        >
            <AllProfiles
                // status="Active"
                status={false}
                // filter={"&filter[user][is_reactivated][_eq]=false"}
                filter={`&filter={"_and":[{"date_created":{"_lt":"2024-07-01"}},{"user":{"is_reactivated": {"_eq":false}}}]}`}
                search_query={
                    debounced_search_query?.length > 2
                        ? `search=${debounced_search_query}`
                        : ""
                }
                note="Candidates registered before 01-07-2024 and haven't reactivated the account yet."
            />
        </Screen>
    );
}

export default CandidatesDeactivated;
