import React from "react";
import { useState } from "react";
import { Button, Card, Form, Icon, Input } from "semantic-ui-react";
import { useChangePassword } from "../_actions/auth";
// import { logout } from "../_helper/constant/auth";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Screen from "../commons/Screen";

export default function ResetPassword() {
    const [data, setData] = useState({});
    const [isdisable, setIsDisable] = useState(true);
    const [err, setErr] = useState("");

    const navigate = useNavigate();
    const reset_mutation = useChangePassword();

    const onSubmit = () => {
        reset_mutation.mutate(
            { password: data.cpassword },
            {
                onSuccess: () => {
                    // logout();
                    navigate("/login");
                },
            }
        );
    };

    useEffect(() => {
        if (data.cpassword != data.password) {
            setErr("Password do not match");
            setIsDisable(true);
        } else {
            setErr("");
            setIsDisable(false);
        }
    }, [data]);

    return (
        <Screen
            title="Reset Password"
            wrapper_styles={{
                marginTop: "-30px",
            }}
        >
            <Form onSubmit={onSubmit}>
                <Form.Field
                    control={Input}
                    required
                    onChange={(e) => {
                        setData({ ...data, password: e.target.value });
                    }}
                    value={data.password}
                    label="New Password"
                />
                <Form.Field
                    control={Input}
                    required
                    label="Confirm Password"
                    onChange={(e) => {
                        setData({ ...data, cpassword: e.target.value });
                    }}
                    value={data.cpassword}
                />
                <p
                    style={{
                        color: "red",
                    }}
                >
                    {err}
                </p>
                <Button
                    primary
                    type="submit"
                    loading={reset_mutation.isLoading}
                    disabled={isdisable}
                    icon
                    labelPosition="left"
                >
                    <Icon name="key" />
                    Reset Password
                </Button>
            </Form>
        </Screen>
    );
}
