import React, { useEffect } from "react";
import { Button, Header, Icon, Segment } from "semantic-ui-react";
import PaymentPage from "./PaymentPage";
import MessageBox from "../commons/MessageBox";
import { useRefreshOrder } from "../_actions/mutations/items";
import { useItemsFilter } from "../_actions/items";
import { useCurrentUser } from "../_actions/auth";

function BuySuchiCard({ order, buyMore, is_pending_order }) {
    const refreshMutation = useRefreshOrder();
    const { data: settings } = useItemsFilter("admin_setting", "");
    const cuser = useCurrentUser();

    console.log("Orderr", order);

    useEffect(() => {
        if (order?.status !== "paid") {
            console.log("Refreshign order status");
            refreshMutation.mutate({ order_id: order?.razorpay_order_id });
        }
    }, [order]);

    const status = order?.status;

    if (status === "paid") {
        let title = "Your order is successfully booked for Suchi."
        let content = "*It will be delivered to delivery address mentioned while booking the printed Suchi."
        if (order.order_status === "delivered") {
            title = "Your suchi is delivered"
            content = false
        } else if (order.order_status === "dispatched") {
            title = "Your suchi is dispatched"
        }

        return (
            <MessageBox
                ukey={order?.id}
                allow_close={true}
                type="success"
                title={title}
                content={content}
            // actions={
            //     is_pending_order
            //         ? []
            //         : [
            //               <Button
            //                   positive
            //                   icon
            //                   labelPosition="right"
            //                   onClick={buyMore}
            //               >
            //                   <Icon name="arrow right" />
            //                   Buy More Suchi
            //               </Button>,
            //           ]
            // }
            />
        );
    }

    if (!settings?.isactive_suchibook) {
        return <></>;
    }

    return (
        <Segment placeholder loading={refreshMutation.isLoading}>
            <Header icon>
                <Icon name="book" style={{ color: "#0003", fontSize: "3em" }} />
                Buy Suchi Hard Copy
                <br />
                <div
                    style={{
                        marginTop: "10px",
                        color: "blue",
                    }}
                >
                    INR 600/-
                </div>
            </Header>

            <p style={{ textAlign: "center", fontSize: "1.1em" }}>
                *It will be delivered to delivery address mentioned while
                booking the printed Suchi.
            </p>
            {!refreshMutation.isLoading && (
                <PaymentPage
                    // name="Shivam Agrawal"
                    // email="shivam@qureal.com"
                    // mobile="8871830420"
                    name={`${cuser?.first_name} ${cuser.last_name}`}
                    email={cuser.email}
                    mobile={cuser.mobile}
                    price={order?.amount}
                    // price={11}
                    user_id={cuser.id}
                    order={order}
                    order_id={order?.razorpay_order_id}
                    payment_notes={{
                        quser_id: cuser.id,
                        qorder_id: order?.razorpay_order_id,
                        order: JSON.stringify(order),
                    }}
                    onSuccess={() =>
                        refreshMutation.mutate({
                            order_id: order?.razorpay_order_id,
                        })
                    }
                    onFailed={() =>
                        refreshMutation.mutate({
                            order_id: order?.razorpay_order_id,
                        })
                    }
                />
            )}
            <p style={{
                marginTop: "15px",
                textAlign: "center",
                color: "#8f6022"
            }}>
                <b>Note: </b>Book deliveries for Suchi orders are expected to begin after November 12, 2024, and may take approximately 4-5 days, depending on the delivery location. If you require the book before November 17, 2024, and plan to order after November 12, please note that delivery may not arrive in time.  If possible, we recommend paying in person and picking up your copy from our office in Mulund East (after November 12) instead of opting for online courier delivery. We are not responsible for any delays caused by the courier process.
            </p>
        </Segment>
    );
}

export default BuySuchiCard;
