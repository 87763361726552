import { useEffect, useState } from "react";
import { useMe } from "./auth";
import { capitalizeFirstLetter } from "../utils";

export const PERMISSONS = {
    admin: {
        project_create: true,
        project_edit: true,
        project_delete: true,
        project_view: true,
        timesheet_fill: true,
        timesheet_view: "all", // all, own
        reports_view: "all", // all, own
        reports_download: true,
        employee_view: "all", // all, own
        employee_create: true,
        lookup_view: true,
        lookup_create: true,
        lookup_type_create: true,
    },
    employee: {
        project_create: false,
        project_edit: false,
        project_delete: false,
        project_view: false,
        timesheet_fill: true,
        timesheet_view: "own", // all, own
        reports_view: "own", // all, own
        reports_download: true,
        employee_view: "own", // all, own
        employee_create: false,
        lookup_view: false,
        lookup_create: false,
        lookup_type_create: false,
    },
};

export const ROLES_MAPPING = {
    admin: "882d9638-26ea-41ca-92f5-04fe4538478b",
    user: "d664c2b6-6f09-4472-b503-df1897e667e6",
};

export const RELESE_REVERSE_MAPPING = {
    "882d9638-26ea-41ca-92f5-04fe4538478b": "admin",
    "d664c2b6-6f09-4472-b503-df1897e667e6": "user",
};

export const usePermissions = () => {
    const { data, isLoading, isSuccess } = useMe();
    const [permissions, setPermissions] = useState({});

    useEffect(() => {
        setPermissions(PERMISSONS[RELESE_REVERSE_MAPPING[data?.data?.role]]);
    }, [isSuccess, data]);

    return {
        permissions: permissions || {},
        isLoading,
        isSuccess,
    };
};

export const useCurrentRole = () => {
    const { data, isLoading, isSuccess } = useMe();
    const [role, setRole] = useState({
        id: null,
        name: "",
    });

    useEffect(() => {
        setRole({
            id: data?.data?.role,
            name: RELESE_REVERSE_MAPPING[data?.data?.role],
        });
    }, [isSuccess, data]);

    return {
        role_id: role.id,
        role_name: role.name,
        role_display_name: capitalizeFirstLetter(role.name),
        isLoading,
        isSuccess,
    };
};
