import React from "react";
import styles from "./styles/AddressView.module.css";

function AddressView({ title, address, name = null }) {
    return (
        <div className={styles.section}>
            <h4 className={styles.heading}>{title}</h4>
            <p>{name}</p>
            <p>{address?.address}</p>
            <p>
                {address?.city}, {address?.pincode}
            </p>
            <p>
                Mobile: {address?.mobile}, {address?.mobile_alternative}
            </p>
        </div>
    );
}

export default AddressView;
