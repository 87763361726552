import styles from "./styles/PrintIndex.module.css";
import React, { useEffect, useState } from "react";
import ProfileCard from "./ProfileCard";
import Page from "./Page";
import { useItemsFilterNew } from "../_actions/items";
import PrintInput from "./PrintInput";
import PageGrid from "./PageGrid";
import IndexPages from "./IndexPages";
import PageLine from "./PageLine";

const COUNT = 270;
const PER_PAGE = 5;
const OFFSET = 0;
const FILTER_MS = "Unmarried";
const FILTER_GENDER = "Female";

const PAGE_NO_OFFSET = 73;
const SERIAL_NO_OFFSET = 0;

const PAGE_HEADING = "UNMARRIED FEMALES";

function PrintHome() {
    const [meta, setMeta] = useState({
        total_records: 1,
        total_pages: 1,
    });
    const { isLoading, isSuccess, data } = useItemsFilterNew(
        "qd_matrimonial_profile",
        `?limit=${COUNT}&offset=${OFFSET}&meta=*&sort=serial_number&fields=*,native_place.name,birth_place.name,location.name,job_type.name,education.name,new_education.qdb_lookup_id.*,addresses.*,addresses.country.name,gotra.name,blood_group.name,complexion.name&filter[status][_eq]=Active&filter[marital_status][_eq]=${FILTER_MS}&filter[gender][_eq]=${FILTER_GENDER}&filter[serial_number][_lt]=700`,
        true,
        true
    );

    useEffect(() => {
        if (isSuccess) {
            const total =
                COUNT < data?.meta?.filter_count
                    ? COUNT
                    : data?.meta?.filter_count;
            // const total = COUNT;
            setMeta({
                total_records: total,
                total_pages: Math.ceil(total / PER_PAGE),
            });
        }
    }, [isSuccess, data]);

    return (
        <div
        // className={styles.bg}
        >
            <PrintInput />
            {true && <IndexPages />}
            {false &&
                isSuccess &&
                Array.from({ length: meta.total_pages }, (_, i) => i + 1).map(
                    (page_no) => {
                        return (
                            <PageLine
                                page_no={page_no}
                                data={data?.data?.slice(
                                    (page_no - 1) * PER_PAGE,
                                    page_no * PER_PAGE
                                )}
                                per_page={PER_PAGE}
                                PAGE_NO_OFFSET={PAGE_NO_OFFSET}
                                SERIAL_NO_OFFSET={SERIAL_NO_OFFSET}
                                page_heading={PAGE_HEADING}
                                gender={FILTER_GENDER}
                            />
                        );
                    }
                )}
        </div>
    );
}

export default PrintHome;
