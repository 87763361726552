import React, { useEffect, useState } from "react";
import { Button, Form, Icon } from "semantic-ui-react";
import "./styles/card.css";
import { getImageURL, getProfilePhoto } from "../_helpers/files";
import { cmToFeetAndInches, findAge } from "../utils";
import { useCreateItem, useDeleteItem } from "../_actions/mutations/items";
// import SearchPreference from "./SearchPreference";
import SearchPreferenceModal from "./modals/SearchPreferenceModal";
import { useSendMail } from "../_actions/mail";
import { useCurrentUser } from "../_actions/auth";

export default function CardsLg({ data, isFav }) {
    const me = useCurrentUser();
    const mailMutation = useSendMail();

    const [favorite, setFavorite] = useState(false);
    const [noteopen, setIsNoteOpen] = useState(false);
    const [openMail, setOpenMail] = useState(false);
    const [mailMsg, setMailMsg] = useState("");
    const [notetxt, setNoteTxt] = useState("");
    const createFav = useCreateItem(
        "junction_directus_users_qd_matrimonial_profile"
    );

    // console.log("data in cardlg", data);

    const removeFav = useDeleteItem(
        "junction_directus_users_qd_matrimonial_profile"
    );

    const createBlock = useCreateItem(
        "junction_directus_users_qd_matrimonial_profile_1"
    );

    const createNote = useCreateItem("matrimonial_profile_notes");

    const getNote = (data) => {
        setNoteTxt(data);
    };

    const AddNote = () => {
        setIsNoteOpen(true);
    };

    const onSaveNote = () => {
        console.log(notetxt);
        createNote.mutate(
            {
                note: notetxt,
                matrinonial_profile: [{ qd_matrimonial_profile_id: data?.id }],
            },
            {
                onSuccess: () => {
                    setIsNoteOpen(false);
                },
            }
        );
    };

    const sendMailNow = () => {
        console.log("Sending mail", me, mailMsg);
        mailMutation.sendMail(
            data?.user?.email,
            `VMM: Message from ${me.first_name} ${me.last_name}`,
            `VMM: Message from ${me.first_name} ${me.last_name}`,
            `Message:- \n${mailMsg}`
        );
    };
    const FavClick = () => {
        // console.log(isFav);
        if (favorite) {
            removeFav.mutate(isFav, {
                onSuccess: () => {
                    setFavorite(false);
                },
            });
        } else {
            createFav.mutate(
                {
                    directus_users_id: localStorage.getItem("userid"),
                    qd_matrimonial_profile_id: data?.id,
                },
                {
                    onSuccess: (data) => {
                        setFavorite(true);
                    },
                }
            );
        }
    };

    const BlockClick = () => {
        createBlock.mutate(
            {
                directus_users_id: localStorage.getItem("userid"),
                qd_matrimonial_profile_id: data?.id,
            },
            {
                onSuccess: (data) => {},
            }
        );
    };
    // console.log(isFav);

    useEffect(() => {
        setFavorite(isFav);
    }, [isFav]);
    // console.log(data);
    return (
        <>
            <SearchPreferenceModal
                open={noteopen}
                setOpen={setIsNoteOpen}
                cmp={<NoteCmp setNote={getNote} />}
                title={"Add Note and Save"}
                action={onSaveNote}
                search_action={() => {}}
            />
            <SearchPreferenceModal
                open={openMail}
                setOpen={setOpenMail}
                cmp={
                    <NoteCmp label="Enter message here" setNote={setMailMsg} />
                }
                title={"Send Message via Mail"}
                action={sendMailNow}
                search_action={() => {}}
                action_label="Send"
            />
            <div
                className="grand"
                style={{
                    height: "45em",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        float: "right",
                    }}
                    onClick={FavClick}
                >
                    {favorite ? (
                        <Icon
                            name="heart"
                            style={{
                                color: "red",
                            }}
                        />
                    ) : (
                        <Icon name="heart outline" />
                    )}
                </div>
                <div
                    style={{
                        display: "flex",
                    }}
                >
                    <img
                        src={getProfilePhoto(
                            data?.main_photo?.id,
                            data?.gender
                        )}
                        alt="profile_img"
                        style={{
                            marginLeft: "16px",
                            // width: "48px",
                            width: "8em",
                            borderRadius: "50%",
                            flex: "auto",
                        }}
                    />
                </div>
                <div>
                    <h3
                        className="word-limit"
                        style={{
                            textAlign: "center",
                            marginTop: "15px",
                        }}
                    >
                        {data?.first_name} {data?.middle_name} {data?.last_name}
                        <p
                            style={{
                                fontWeight: "400",
                                fontSize: "12px",
                            }}
                        >
                            {data?.location?.name},{" "}
                            {data?.country?.name && data?.country?.name}
                        </p>
                    </h3>
                    <p>
                        <span
                            style={{
                                marginRight: "4px",
                            }}
                        >
                            Age: {findAge(data?.brithday)},
                        </span>{" "}
                        Height:{" "}
                        {Math.round(cmToFeetAndInches(data?.height).feet)}'
                        {Math.round(cmToFeetAndInches(data?.height).inches)}
                    </p>
                    <p className="word-limit">Occupation: {data?.occupation}</p>
                    <p className="word-limit">
                        Education: {data?.education_stream}{" "}
                    </p>

                    {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> */}
                    {/* <Button
            style={{
              backgroundColor: "#EE3435",
              color: "white",
              // border: "none",
              // padding: "5px",
              marginTop: "1em",
              width: "100%",
            }}
          >
            Shortlist
          </Button> */}
                    <Button
                        style={{
                            backgroundColor: "#EE3435",
                            color: "white",
                            // border: "none",
                            // padding: "5px",
                            marginTop: "1em",
                            width: "100%",
                        }}
                        onClick={() => {
                            setOpenMail(true);
                        }}
                    >
                        Send Mail
                    </Button>
                    <Button
                        onClick={AddNote}
                        style={{
                            backgroundColor: "#EE3435",
                            color: "white",
                            // border: "none",
                            // padding: "5px",
                            marginTop: "1em",
                            width: "100%",
                        }}
                    >
                        Add Note
                    </Button>
                    <Button
                        onClick={BlockClick}
                        loading={createBlock.isLoading}
                        style={{
                            backgroundColor: "#EE3435",
                            color: "white",
                            // border: "none",
                            // padding: "5px",
                            marginTop: "1em",
                            width: "100%",
                        }}
                    >
                        Block Memeber
                    </Button>
                </div>
            </div>
        </>
    );
}

const NoteCmp = ({ label = "Add Note", setNote = () => {} }) => {
    return (
        <>
            <Form>
                <Form.Field>
                    <label>{label}</label>
                    <textarea
                        onChange={(e) => {
                            setNote(e.target.value);
                        }}
                    />
                </Form.Field>
            </Form>
        </>
    );
};
