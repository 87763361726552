import React from 'react'
import { useState } from 'react'
import { Button, Card, Form, Icon, Input } from 'semantic-ui-react'
import { useChangePassword, useMe } from '../_actions/auth'
// import { logout } from "../_helper/constant/auth";
import { Link, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import Screen from '../commons/Screen'
import { useUpdateItemNew } from '../_actions/mutations/items'
import MessageBox from '../commons/MessageBox'

export default function MarkMarriedMessage () {
  const { isSuccess: successMe, isLoading: loadingMe, data: dataMe } = useMe()

  const profile = dataMe?.data?.matrimonial_profiles?.[0]

  if (!successMe) {
    return <></>
  }

  return (
    <>
      {profile?.status === 'married' && (
        <MessageBox
          type='success'
          title={`Congratulations, You have marked ${profile?.first_name} ${profile?.last_name} as married`}
          content="Since profile is married, we've stopped showing your profile in online search."
          actions={[]}
        />
      )}
      {profile?.status !== 'married' && (
        <MessageBox
          type='info'
          title={`If ${profile?.first_name} ${profile?.last_name} is married. mark married here.`}
          actions={[
            <Link to='/settings/mark_married'>
              <Button primary>Click here to update marital status</Button>
            </Link>
          ]}
        />
      )}
    </>
  )
}
