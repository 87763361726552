import { BASE_URL } from "../constants";
import { restoreAccessToken } from "./auth";

export function getImageURL(id, key = "system-medium-contain") {
    if (id) {
        return `${BASE_URL}assets/${id}?key=${key}&access_token=${restoreAccessToken()}`;
        // return `${BASE_URL}assets/${id}?key=${key}`;
    } else {
        // return AvatarPhoto;
        return null;
    }
}

const IMG_PLACEHOLDER_MAN =
    "https://matrimonialapi.qureal.com/assets/afbff5ec-ffd9-4a09-8b47-1918b7966d5e";
const IMG_PLACEHOLDER_WOMAN =
    "https://matrimonialapi.qureal.com/assets/ccd4de33-30bb-42ff-acd3-60db82820558";

export function getProfilePhoto(main_photo, gender) {
    if (main_photo) {
        return getImageURL(main_photo);
    }
    if (gender === "Female") return IMG_PLACEHOLDER_WOMAN;
    return IMG_PLACEHOLDER_MAN;
}

export function getPhoto(main_photo, gender) {
    if (main_photo) {
        return `${BASE_URL}assets/${main_photo}?&access_token=${restoreAccessToken()}`;
    }
    if (gender === "Female") return IMG_PLACEHOLDER_WOMAN;
    return IMG_PLACEHOLDER_MAN;
}
