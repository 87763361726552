import React, { useEffect, useState } from "react";
import { Button, Icon } from "semantic-ui-react";
import "./styles/card.css";
import { getProfilePhoto } from "../_helpers/files";
import { cmToFeetAndInches, findAge } from "../utils";
import { useNavigate } from "react-router-dom";
import { useCreateItem, useDeleteItem } from "../_actions/mutations/items";

export default function Cards({
    data,
    currUser,
    my_fav,
    mode = "view_profile",
    block_id = null,
    note,
}) {
    const [favorite, setFavorite] = useState(false);
    const navigate = useNavigate("");
    // console.log(my_fav);
    const createFav = useCreateItem(
        "junction_directus_users_qd_matrimonial_profile"
    );

    const removeFav = useDeleteItem(
        "junction_directus_users_qd_matrimonial_profile"
    );

    const removeNoteFav = useDeleteItem("matrimonial_profile_notes");

    const Unblock = useDeleteItem(
        "junction_directus_users_qd_matrimonial_profile_1"
    );

    const handleUnblock = (id) => {
        Unblock.mutate(id, {
            onSuccess: (data) => {
                console.log("sucess");
            },
        });
    };

    const handleRemoveNoteProfile = (id) => {
        removeNoteFav.mutate(id);
    };

    useEffect(() => {
        setFavorite(my_fav);
    }, []);

    // console.log(currUser);
    const FavClick = () => {
        if (my_fav) {
            removeFav.mutate(my_fav, {
                onSuccess: () => {
                    setFavorite(false);
                },
            });
        } else {
            createFav.mutate(
                {
                    directus_users_id: currUser,
                    qd_matrimonial_profile_id: data?.id,
                },
                {
                    onSuccess: (data) => {
                        setFavorite(!favorite);
                    },
                }
            );
        }
    };

    // console.log(data);
    return (
        <>
            <div className="grand">
                {mode === "view_profile" && (
                    <div
                        style={{
                            display: "flex",
                            float: "right",
                        }}
                        onClick={FavClick}
                    >
                        {favorite ? (
                            <Icon
                                name="heart"
                                style={{
                                    color: "red",
                                }}
                            />
                        ) : (
                            <Icon name="heart outline" />
                        )}
                    </div>
                )}
                <div
                    style={{
                        display: "flex",
                    }}
                >
                    <img
                        src={getProfilePhoto(data?.main_photo, data?.gender)}
                        alt="profile_img"
                        style={{
                            marginLeft: "16px",
                            // width: "48px",
                            width: "8em",
                            borderRadius: "50%",
                            flex: "auto",
                        }}
                    />
                </div>
                <div>
                    <h3
                        className="word-limit"
                        style={{
                            textAlign: "center",
                            // marginTop: "4px",
                            paddingTop: "14px",
                            marginBottom: "10px",
                        }}
                    >
                        {data?.first_name} {data?.middle_name} {data?.last_name}
                    </h3>
                    <p
                        style={{
                            fontWeight: "400",
                            fontSize: ".9em",
                            textAlign: "center",
                        }}
                    >
                        {data?.location?.name
                            ? data?.location?.name
                            : data?.new_location}
                        , {data?.country?.name && data?.country?.name}
                    </p>
                    <p>
                        <span
                            style={{
                                marginRight: "4px",
                            }}
                        >
                            Age: {findAge(data.brithday)},
                        </span>{" "}
                        Height:{" "}
                        {Math.round(cmToFeetAndInches(data?.height).feet)}'
                        {Math.round(cmToFeetAndInches(data?.height).inches)}
                    </p>
                    <p className="word-limit">Occupation: {data?.occupation}</p>
                    <p className="word-limit">
                        Education: {data?.education_stream}{" "}
                    </p>
                    {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> */}
                    {mode === "note" && (
                        <p
                            style={{
                                marginTop: "1em",
                                // fontWeight: "600",
                                backgroundColor: "#fff4a3",
                                padding: "10px",
                            }}
                        >
                            Note:- {note}
                        </p>
                    )}{" "}
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "10px",
                        }}
                    >
                        {mode === "view_profile" ||
                        mode === "fav" ||
                        mode === "note" ? (
                            <button
                                onClick={() => {
                                    navigate(`/profile/${data?.id}`);
                                }}
                                style={{
                                    cursor: "pointer",
                                    backgroundColor: "#EE3435",
                                    color: "white",
                                    border: "none",
                                    padding: "10px",
                                    width: "100%",
                                    borderRadius: "5px",
                                }}
                            >
                                View Profile
                            </button>
                        ) : (
                            <Button
                                loading={Unblock.isLoading}
                                onClick={() => {
                                    // unblock_fun(block_);
                                    handleUnblock(block_id);
                                }}
                                style={{
                                    cursor: "pointer",
                                    backgroundColor: "#EE3435",
                                    color: "white",
                                    border: "none",
                                    padding: "10px",
                                    width: "100%",
                                }}
                            >
                                Unblock
                            </Button>
                        )}
                        {mode === "note" && (
                            <Button
                                loading={removeNoteFav.isLoading}
                                onClick={() => {
                                    // unblock_fun(block_);
                                    handleRemoveNoteProfile(block_id);
                                }}
                                style={{
                                    cursor: "pointer",
                                    backgroundColor: "#EE3435",
                                    color: "white",
                                    border: "none",
                                    padding: "10px",
                                    width: "100%",
                                }}
                            >
                                Remove note
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
