import classNames from "classnames";
import { useItemsFilterNew } from "../_actions/items";
import styles from "./styles/IndexPages.module.css";
import stylesBg from "./styles/PrintIndex.module.css";
import React, { Fragment, useEffect, useMemo, useState } from "react";

const PER_COLUMN = 22;
const COLUMN_PER_PAGE = 3;

//===============================
const PER_PAGE = PER_COLUMN * COLUMN_PER_PAGE;

function IndexPages() {
    const [meta, setMeta] = useState({
        total_records: 1,
        total_pages: 1,
    });
    const { isLoading, isSuccess, data } = useItemsFilterNew(
        "qd_matrimonial_profile",
        // `?filter[status][_eq]=Active&filter[marital_status][_eq]=Unmarried&filter[gender][_eq]=Male&limit=-1&meta=*&sort=serial_number&fields=id,serial_number,first_name,middle_name,last_name`,
        `?filter[status][_eq]=Active&filter[serial_number][_lt]=650&limit=-1&meta=*&sort=serial_number&fields=id,serial_number,first_name,middle_name,last_name`,
        true,
        false
    );
    const {
        isLoading2,
        isSuccess2,
        data: data2,
    } = useItemsFilterNew(
        "qd_matrimonial_profile",
        // `?filter[status][_eq]=Active&filter[marital_status][_eq]=Unmarried&filter[gender][_eq]=Male&limit=-1&meta=*&sort=serial_number&fields=id,serial_number,first_name,middle_name,last_name`,
        `?filter[status][_eq]=Active&filter[gender][_eq]=Female&filter[marital_status][_eq]=Unmarried&filter[serial_number][_lt]=650&limit=-1&meta=*&sort=serial_number&fields=*`,
        true,
        false
    );

    // console.log("data2", data2);

    useEffect(() => {
        if (isSuccess) {
            const total = data?.meta?.filter_count;
            // const total = COUNT;
            setMeta({
                total_records: total,
                total_pages: Math.ceil(total / PER_PAGE) + 1,
            });
        }
    }, [isSuccess, data]);

    console.log("Data in index", data);
    console.log("Meta in index", meta);

    const out = useMemo(() => {
        if (isSuccess) {
            const all = data?.data;
            const out = [];
            let start = 0;
            while (start < all.length) {
                console.log("Start: " + start);
                let temp = all.slice(start, start + PER_COLUMN);
                //=========================
                let temp_temp = [...temp];
                temp_temp = temp_temp.slice(1, temp_temp.length);
                let stop = temp_temp.findIndex(
                    // (item) => item.serial_number === 351
                    (item) => [311, 351, 621].includes(item.serial_number)
                );
                console.log(stop);
                if (stop !== -1 && stop !== 0) {
                    stop += 1;
                    temp = all.slice(start, start + stop);
                    start = start + stop;
                    out.push(temp);
                } else {
                    out.push(temp);
                    start = start + PER_COLUMN;
                }
            }
            return out;
        }
        return [];
    });

    console.log("OUT", out);
    return (
        <div>
            {isSuccess &&
                data?.data?.length > 0 &&
                Array.from({ length: meta.total_pages }, (_, i) => i + 1).map(
                    (page_no) => {
                        return (
                            <SinglePage
                                page_no={page_no}
                                data={out.slice(
                                    (page_no - 1) * COLUMN_PER_PAGE,
                                    page_no * COLUMN_PER_PAGE
                                )}
                                column_count={COLUMN_PER_PAGE}
                                per_column={PER_COLUMN}
                                extra={data2?.data || []}
                            />
                        );
                    }
                )}
        </div>
    );
}

export default IndexPages;

function SinglePage({ page_no, data, column_count = 3, per_column = 10, extra }) {
    return (
        <Fragment>
            <div className={styles.page_wrapper}>
                <div className={classNames(styles.wrapper, stylesBg.bg_new)}>
                    {data.map((item) => {
                        return (
                            <SingleColumn
                                data={item}
                                per_column={per_column}
                                extra={extra}
                            />
                        );
                    })}
                    <div className={styles.top_left_bar}>
                        <img
                            // src="https://api.qureal.com/passets/16480c10-a1de-4546-9a03-68323ab3b412.png"
                            src="https://matrimonialapi.qureal.com/assets/f187d1a4-8bd5-4ec6-bd8d-4f907c787f7b"
                            alt="LOGO"
                            className={styles.logo}
                        />
                        <span
                            className={styles.title}
                            style={{
                                backgroundColor: "red",
                            }}
                        >
                            VIKAS MANDAL MULUND VADHU-VAR SUCHI 2024: INDEX
                        </span>
                        <span className={styles.page_no}>
                            {page_no}
                        </span>
                    </div>
                </div>
            </div>

            <div className={styles.page_break}> </div>
        </Fragment>
    );
}

function SingleColumn({ data, per_column = 10, extra = [] }) {
    const top = getTitle(data[1].serial_number);
    return (
        <div className={styles.column}>
            <h4
                className={styles.heading}
                style={{
                    backgroundColor: top?.[1],
                }}
            >
                {top?.[0]}
            </h4>
            <div className={styles.content}>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th>S No.</th>
                            <th>Name</th>
                            <th>P No.</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item) => (
                            <tr>
                                <td>{item.serial_number}</td>
                                <td className={styles.max_lines_1}>
                                    {toStartCase(item.last_name)}{" "}
                                    {toStartCase(item.first_name)}
                                    {item.middle_name &&
                                        ` ${toStartCase(item.middle_name)}`}
                                </td>
                                <td>
                                    {getPageNumber(item.serial_number, extra) + 10}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

function toStartCase(inputString) {
    if (!inputString) {
        return "-";
    }
    return inputString
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
}

function getPageNumber(sn, extra) {
    if (sn <= 305) {
        return Math.floor((sn - 1) / 5) + 1;
    } else if (sn >= 311 && sn <= 319) {
        return Math.floor((sn - 1) / 5) + 0;
    } else if (sn >= 351 && sn <= 617) {
        // const actual_no =
        //     extra.findIndex((item) => item.serial_number === sn) + 401;
        // return Math.floor((actual_no - 71) / 5) + 1;
        return Math.floor((sn - 31) / 5) + 0;
    } else if (sn >= 621 && sn <= 627) {
        return Math.floor((sn - 31) / 5) + 0;
    }
    return 0;
}

function getTitle(sn) {
    if (sn <= 305) {
        return ["Unmarried Males", "blue"];
    } else if (sn >= 311 && sn <= 319) {
        return ["Divorced Males", "blue"];
    } else if (sn >= 351 && sn <= 617) {
        return ["Unmarried Females", "hotpink"];
    } else if (sn >= 621 && sn <= 627) {
        return ["Divorced Females", "hotpink"];
    }
}
