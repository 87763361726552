import React, { useEffect, useMemo, useState } from 'react'
import MessageBox from '../commons/MessageBox'
import { Button, Checkbox, Form, Icon, Modal } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import Confetti from 'react-confetti'
import { useUpdateItemNew, useUpdateMe } from '../_actions/mutations/items'
import LookupDropdown from '../forms/LookupDropdown'
import RelationDropdown from '../forms/RelationDropdown'

function UpdateEducationModal({ profile }) {
    const [open, setOpen] = useState(false)
    const [value, setValue] = useState([])
    const mutationUpdate = useUpdateItemNew(
        'qd_matrimonial_profile',
        () => { },
        'user'
    )


    useEffect(() => {
        const educations = profile?.new_education || [];
        if (educations.length === 0) {
            setOpen(true)
        } else {
            setOpen(false)
        }
    }, [profile?.new_education])



    const proceed = () => {
        if (value.length > 0) {
            //mark as married
            mutationUpdate.mutate({
                id: profile?.id,
                data: {
                    new_education: value,
                }
            })
        }
    }

    return (

        <Modal
            size='small'
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            closeOnDimmerClick={false}
            closeOnDocumentClick={false}
            closeOnEscape={false}
        >
            <Modal.Header>Please update education</Modal.Header>
            <Modal.Content>
                {value.married === 'yes' && (
                    <Confetti
                        // width={width} height={height}
                        width='700px'
                    />
                )}
                <Form>


                    <RelationDropdown
                        collection="qdb_lookup"
                        pk="id"
                        display_field='name'
                        filter_string={`?filter[lookup][_eq]=New Education`}
                        many={true}
                        label="Education Stream/Field"
                        value={value.map(item => item.qdb_lookup_id)}
                        onChange={(newVal) =>
                            setValue(newVal.map(id => ({ qdb_lookup_id: id })))
                        }
                        all={false}
                    />
                    <span style={{ color: "#888" }}>You can select one or more fields</span>

                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    positive
                    icon='checkmark'
                    labelPosition='right'
                    content='Proceed'
                    disabled={value?.length === 0}
                    onClick={proceed}
                    loading={mutationUpdate.isLoading}
                />
            </Modal.Actions>
        </Modal>

    )
}

export default UpdateEducationModal
