export function countWords(sentence) {
    var words = sentence.trim().split(/\s+/);
    return words.length;
}

export function capitalizeFirstLetter(str) {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
}

export function generateOtp() {
    const otp = Math.floor(1000 + Math.random() * 9000);
    return otp.toString();
}

export function findAge(date) {
    let today = new Date();
    let birthDate = new Date(date);
    let age = today.getFullYear() - birthDate.getFullYear();
    return age;
}

export function cmToFeetAndInches(cm) {
    // 1 foot = 30.48 centimeterss
    const totalInches = cm / 2.54;
    const feet = Math.floor(totalInches / 12);
    const inches = totalInches % 12;

    return { feet, inches };
}

export function getDateRangeFromAgeRange(ageRange) {
    // Get the current date
    const currentDate = new Date();

    // Calculate birth years based on the age range
    const minBirthYear = currentDate.getFullYear() - ageRange[1];
    const maxBirthYear = currentDate.getFullYear() - ageRange[0];

    // Create date objects for the calculated birth years
    const minDate = new Date(
        minBirthYear,
        currentDate.getMonth(),
        currentDate.getDate()
    );
    const maxDate = new Date(
        maxBirthYear,
        currentDate.getMonth(),
        currentDate.getDate()
    );

    // Format the date objects as strings (you can customize the format)
    const dateFormatOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
    };
    const minDateStr = minDate.toLocaleDateString(undefined, dateFormatOptions);
    const maxDateStr = maxDate.toLocaleDateString(undefined, dateFormatOptions);

    // Return the date range as an array of two date strings
    return [
        new Date(minDateStr).toISOString(),
        new Date(maxDateStr).toISOString(),
    ];
}

export function TimeShowAmPm(time) {
    console.log(time);
    const arr = time?.split(":");
    return `${time}${" "}${arr[0] >= 12 ? "P.M" : "A.M"}`;
}

export function rangeFeetInchToCm(range) {
    if (range.length !== 2) {
        return "Invalid input. Please provide an array with two elements representing the range in feet.";
    }

    const startFeet = range[0];
    const endFeet = range[1];

    // 1 foot = 30.48 cm
    const startCm = startFeet * 30.48;
    const endCm = endFeet * 30.48;

    return [startCm.toFixed(2), endCm.toFixed(2)];
}

export function toStartCase(text) {
    return text
        ?.toLowerCase()
        ?.replace(/\b\w/g, (match) => match.toUpperCase());
}
