import React from "react";
import Screen from "../commons/Screen";
import AllProfiles from "../showcase/AllProfiles";
import DownloadProfilesButton from "./DownloadProfilesButton";
import ColdMailSendProfileBtn from "../mail/ColdMailSendProfileBtn";

function CandidatesPending() {
    return (
        <Screen
            title="Pending Candidates"
            wrapper_styles={{
                marginTop: "-30px",
            }}
            actions={[
                <ColdMailSendProfileBtn status="Pending" />,
                <DownloadProfilesButton status="Pending" />,
            ]}
        >
            <AllProfiles status="Pending" show_last_updated={true} />
        </Screen>
    );
}

export default CandidatesPending;
