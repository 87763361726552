import React from "react";
import ProfileCard from "./ProfileCard";
import styles from "./styles/Page.module.css";

function Page({ page_no, data, per_page }) {
    console.log("Page data", data);
    return (
        <div className={styles.wrapper}>
            <div className={styles.grid_wrapper}>
                {data.map((item, index) => (
                    <ProfileCard
                        data={item}
                        number={(page_no - 1) * per_page + index + 1}
                    />
                ))}
            </div>
            <span className={styles.page_no}>Page No. {page_no}</span>
            <span className={styles.website}>www.vikasmadalmulund.com</span>
            <span className={styles.other_info}>+91 7021057077</span>
        </div>
    );
}

export default Page;
