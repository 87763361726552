import React from "react";
import Screen from "../commons/Screen";
import AllProfiles from "../showcase/AllProfiles";
import DownloadProfilesButton from "./DownloadProfilesButton";
import ColdMailSendProfileBtn from "../mail/ColdMailSendProfileBtn";

function CandidatesMarried() {
    return (
        <Screen
            title="Married Candidates"
            wrapper_styles={{
                marginTop: "-30px",
            }}
            actions={[
                <ColdMailSendProfileBtn status="married" />,
                <DownloadProfilesButton status="married" />,
            ]}
        >
            <AllProfiles status="married" show_last_updated={true} />
        </Screen>
    );
}

export default CandidatesMarried;
