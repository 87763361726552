import React from "react";
import Screen from "./commons/Screen";
import BlockView from "./home/BlockView";

export default function Blocked() {
  return (
    <Screen title="All Blocked Member">
      <BlockView />
    </Screen>
  );
}
