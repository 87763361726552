import React from "react";
import { useParams } from "react-router-dom";
import Screen from "../commons/Screen";
import { Button, Icon, Table } from "semantic-ui-react";
import { useItems, useItemsFilter } from "../_actions/items";
// import log from "cslog";
import CreateLookupDialog from "./CreateLookupDialog";
import { useDeleteItem, useUpdateItem } from "../_actions/mutations/items";
// import { usePermissions } from "../_helper/roles";

const log = {
    d: (val, name) => (name, val),
};

function LookupTable() {
    const { type } = useParams();
    const { isSuccess, isLoading, data } = useItemsFilter(
        "qdb_lookup",
        `?filter[lookup][_eq]=${type}&sort=-date_created&limit=-1`,
        !!type
    );
    const updateMutation = useUpdateItem("qdb_lookup");
    const deleteMutation = useDeleteItem("qdb_lookup");
    const permissions = {
        lookup_create: true,
    };

    log.d("deleteMutation", deleteMutation);

    return (
        <Screen
            title={`${type} Values`}
            actions={
                permissions.lookup_create
                    ? [
                          <CreateLookupDialog
                              trigger={
                                  <Button primary icon labelPosition="left">
                                      <Icon name="plus" />
                                      Add New
                                  </Button>
                              }
                              type={type}
                          />,
                      ]
                    : []
            }
            wrapper_styles={{
                marginTop: "-30px",
            }}
        >
            <Table striped celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        {type === "Holidays" ? (
                            <>
                                <Table.HeaderCell>State</Table.HeaderCell>
                                <Table.HeaderCell>Date</Table.HeaderCell>
                            </>
                        ) : (
                            ""
                        )}
                        <Table.HeaderCell>Date Created</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {isSuccess &&
                        data.length > 0 &&
                        data.map((item) => (
                            <Table.Row key={item.id}>
                                <Table.Cell>{item.name}</Table.Cell>
                                {type === "Holidays" ? (
                                    <>
                                        <Table.Cell>{item.state}</Table.Cell>
                                        <Table.Cell>
                                            {new Date(item.date).toDateString()}
                                        </Table.Cell>
                                    </>
                                ) : (
                                    ""
                                )}
                                <Table.Cell>
                                    {new Date(item.date_created).toDateString()}
                                </Table.Cell>
                                <Table.Cell>
                                    <Button
                                        icon
                                        basic
                                        color="red"
                                        onClick={() => {
                                            deleteMutation.mutate(item.id);
                                        }}
                                        loading={
                                            deleteMutation.isLoading &&
                                            deleteMutation?.variables ===
                                                item.id
                                        }
                                    >
                                        <Icon name="trash alternate outline" />
                                    </Button>
                                    {item.record_type === "custom" && (
                                        <Button
                                            icon
                                            basic
                                            color="green"
                                            onClick={() => {
                                                updateMutation.mutate({
                                                    id: item.id,
                                                    data: {
                                                        record_type: "standard",
                                                    },
                                                });
                                            }}
                                            loading={
                                                updateMutation.isLoading &&
                                                updateMutation?.variables
                                                    ?.id === item.id
                                            }
                                        >
                                            <Icon name="check" />
                                        </Button>
                                    )}
                                </Table.Cell>
                            </Table.Row>
                        ))}
                </Table.Body>
            </Table>
        </Screen>
    );
}

export default LookupTable;
