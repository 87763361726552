import { useQuery } from "react-query";
import { publicAxios, secureAxios } from "../_helpers/auth";

export const useItems = (collection, preset, enabled) => {
    return useQuery(
        ["items", collection],
        () => secureAxios.get(`/items/${collection}`).then((res) => res.data),
        {
            enabled: enabled,
        }
    );
};

export const useMeSeprateItems = (collection, preset, enabled) => {
    return useQuery(
        ["items", collection],
        () => secureAxios.get(`/users/me?fields=*.*`).then((res) => res.data),
        {
            enabled: enabled,
        }
    );
};

export const useMeItems = (collection, preset, enabled) => {
    return useQuery(
        ["items", collection],
        () => secureAxios.get(`/users/me`).then((res) => res.data),
        {
            enabled: enabled,
        }
    );
};

export const useMeItemsFilter = (collection, queryString, enabled) => {
    return useQuery(
        ["items", collection],
        () =>
            secureAxios.get(`/users/me${queryString}`).then((res) => res.data),
        {
            enabled: enabled,
        }
    );
};

export const useItemsFilter = (collection, queryString, enabled) => {
    const query =
        collection === "directus_users" ? "/users" : `/items/${collection}`;
    return useQuery(
        ["items", collection, queryString],
        () => secureAxios.get(`${query}${queryString}`).then((res) => res.data),
        {
            enabled: enabled,
        }
    );
};

export const useItemsFilterNew = (
    collection,
    queryString,
    enabled,
    keepPreviousData = false
) => {
    const query =
        collection === "directus_users" ? "/users" : `/items/${collection}`;
    return useQuery(
        ["items", collection, queryString],
        () => secureAxios.get(`${query}${queryString}`).then((res) => res),
        {
            enabled: enabled,
            keepPreviousData: keepPreviousData,
        }
    );
};

export const useSingleItem = (collection, id, queryString, enabled) => {
    const query =
        collection === "directus_users" ? "/users" : `/items/${collection}`;
    return useQuery(
        ["items", collection, id, queryString],
        () =>
            secureAxios
                .get(`${query}/${id}${queryString}`)
                .then((res) => res.data),
        {
            enabled: enabled,
        }
    );
};
