import React from "react";
import { Route, Routes } from "react-router-dom";
import Blocked from "./Blocked";
import Favrouite from "./Favrouite";
import Home from "./Home";
import HomePage from "./HomePage";
import SaveNoteProfiles from "./SaveNoteProfiles";
import Admin from "./admin";
import BookOrdered from "./admin/BookOrdered";
import CandidateEdit from "./admin/CandidateEdit";
import CandidateView from "./admin/CandidateView";
import CandidatesActive from "./admin/CandidatesActive";
import CandidatesAll from "./admin/CandidatesAll";
import CandidatesDraft from "./admin/CandidatesDraft";
import CandidatesPending from "./admin/CandidatesPending";
import CandidatesRejected from "./admin/CandidatesRejected";
import DispatchedOrdered from "./admin/DispatchedOrder";
import ForgetPassword from "./auth/ForgotPassword";
import Login from "./auth/Login";
import PrivateRoute from "./auth/PrivateRoute";
import Register from "./auth/Register";
import ResetPassword from "./auth/ResetPassword";
import VerifyOTP from "./auth/VerifyOTP";
import BlogHome from "./blogs/BlogHome";
import DetailPage from "./home/detail_page/DetailPage";
import MailDashboard from "./mail/MailDashboard";
import ListOrders from "./orders/ListOrders";
import OrderManagement from "./orders/OrderManagement";
import SubscriptionManagement from "./orders/SubscriptionManagement";
import PrintHome from "./printing";
import CandidateFormScreen from "./profile/CandidateFormScreen";
import MyCandidate from "./profile/MyCandidate";
import MyMemberShipProfile from "./profile/MyMemberShipProfile";
import Settings from "./settings";
import AdminSettings from "./settings/AdminSettings";
import LookupTable from "./settings/LookupTable";
import TermsAndCondition from "./terms-and-conditions";
import TV from "./tv";
import AllUsers from "./users/AllUsers";
import PrintTV from "./printing/PrintTV";
import MarkMarried from "./auth/MarkMarried";
import CandidatesMarried from "./admin/CandidatesMarried";
import CandidatesReactivated from "./admin/CandidatesReactivated";
import CandidatesDeactivated from "./admin/CandidatesDeactivated";
import CandidatesNew from "./admin/CandidatesNew";
import OrderManagementPast from "./orders/OrderManagementPast";

function AppRoutes() {
    return (
        <Routes>
            <Route exact path="/print_suchi" element={<PrintHome />} />
            <Route exact path="/tv_pdf" element={<PrintTV />} />
            <Route exact path="/tv" element={<TV />} />
            <Route exact path="/blogs" element={<BlogHome />} />
            <Route
                exact
                path="/terms-and-conditions"
                element={<TermsAndCondition />}
            />
            <Route exact path="/login" element={<Login />} />
            <Route
                exact
                path="/admin/reset-password"
                element={<ForgetPassword />}
            />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/verify_otp" element={<VerifyOTP />} />
            <Route element={<PrivateRoute />}>
                <Route path="/*" element={<Home />}>
                    <Route path="blocked_members" element={<Blocked />} />
                    <Route path="fav_members" element={<Favrouite />} />
                    <Route
                        path="save_note_members"
                        element={<SaveNoteProfiles />}
                    />
                    <Route exact path="" element={<HomePage />} />
                    <Route exact path="profile/:id" element={<DetailPage />} />
                    <Route path="candidate/view" element={<MyCandidate />} />
                    <Route
                        path="candidate/create"
                        element={<CandidateFormScreen edit={false} />}
                    />
                    <Route
                        path="candidate/edit"
                        element={<CandidateFormScreen edit={true} />}
                    />
                    <Route exact path="settings/*" element={<Settings />}>
                        <Route
                            path="reset_password"
                            element={<ResetPassword />}
                        />
                        <Route path="mark_married" element={<MarkMarried />} />
                        <Route
                            path="admin_setting"
                            element={<AdminSettings />}
                        />
                        <Route
                            path="my_membership"
                            element={<MyMemberShipProfile />}
                        />
                        <Route path="lookup/:type" element={<LookupTable />} />
                    </Route>
                    <Route exact path="admin/*" element={<Admin />}>
                        <Route
                            path="mail_dashboard"
                            element={<MailDashboard />}
                        />
                        <Route
                            path="search_subscriptions"
                            element={<SubscriptionManagement />}
                        />
                        <Route path="orders/*" element={<OrderManagement />}>
                            <Route
                                path="ordered"
                                element={
                                    <ListOrders
                                        payment_status="paid"
                                        order_status="ordered"
                                    />
                                }
                            />
                            <Route
                                path="dispatched"
                                // element={<DispatchedOrdered />}
                                element={
                                    <ListOrders
                                        payment_status="paid"
                                        order_status="dispatched"
                                    />
                                }
                            />
                            <Route
                                path="delivered"
                                // element={<BooksDelivered />}
                                element={
                                    <ListOrders
                                        payment_status="paid"
                                        order_status="delivered"
                                    />
                                }
                            />

                        </Route>
                        <Route path="past_orders/*" element={<OrderManagementPast />}>
                            <Route
                                path="ordered"
                                element={
                                    <ListOrders
                                        archived={true}
                                        payment_status="paid"
                                        order_status="ordered"
                                    />
                                }
                            />
                            <Route
                                path="dispatched"
                                // element={<DispatchedOrdered />}
                                element={
                                    <ListOrders
                                        archived={true}
                                        payment_status="paid"
                                        order_status="dispatched"
                                    />
                                }
                            />
                            <Route
                                path="delivered"
                                // element={<BooksDelivered />}
                                element={
                                    <ListOrders
                                        archived={true}
                                        payment_status="paid"
                                        order_status="delivered"
                                    />
                                }
                            />

                        </Route>
                        <Route
                            path="candidates/book_ordered"
                            element={<BookOrdered />}
                        />
                        <Route
                            path="candidates/dispached_ordered"
                            element={<DispatchedOrdered />}
                        />

                        <Route
                            path="candidates/all"
                            element={<CandidatesAll />}
                        />
                        <Route
                            path="candidates/pending"
                            element={<CandidatesPending />}
                        />
                        <Route
                            path="candidates/married"
                            element={<CandidatesMarried />}
                        />
                        <Route
                            path="candidates/active"
                            element={<CandidatesActive />}
                        />
                        <Route
                            path="candidates/deactivated"
                            element={<CandidatesDeactivated />}
                        />
                        <Route
                            path="candidates/new"
                            element={<CandidatesNew />}
                        />
                        <Route
                            path="candidates/reactivated"
                            element={<CandidatesReactivated />}
                        />
                        <Route
                            path="candidates/rejected"
                            element={<CandidatesRejected />}
                        />
                        <Route
                            path="candidates/view/:id"
                            element={<CandidateView />}
                        />
                        <Route
                            path="candidates/edit/:id"
                            element={<CandidateEdit />}
                        />
                        <Route
                            path="candidates/draft"
                            element={<CandidatesDraft />}
                        />
                        <Route path="users/all" element={<AllUsers />} />
                    </Route>
                </Route>
            </Route>
        </Routes>
    );
}

export default AppRoutes;
