import React, { useEffect, useMemo, useState } from 'react'
import { useCreateItem } from '../_actions/mutations/items'
import { RAZORPAYKEY } from '../constants'
import { Button, Icon } from 'semantic-ui-react'
import ModalCmp from '../commons/Modal'
import SuchiInfoModal from './SuchiInfoModal'
import { useLocation } from 'react-router-dom'

function useQueryParams() {
    let { search } = useLocation()
    const query = new URLSearchParams(search)
    return query
}

function PaymentPage({
    name,
    email,
    mobile,
    price,
    user_id,
    order_id,
    order,
    payment_notes,
    onSuccess,
    onFailed
}) {
    const [open, setOpen] = useState(false)
    const [status, setStatus] = useState('idle')
    const payment_mutation = useCreateItem('qd_payments')
    const query = useQueryParams()

    const click_open = useMemo(() => {
        return query.get('buysuchi')
    }, [query])

    useEffect(() => {
        if (query.get('buysuchi') > 0) {
            setOpen(true)
        }
    }, [click_open])

    console.log('Payment details', name, email, mobile, user_id)

    function loadScript(src) {
        return new Promise(resolve => {
            const script = document.createElement('script')
            script.src = src
            script.onload = () => {
                resolve(true)
            }
            script.onerror = () => {
                resolve(false)
            }
            document.body.appendChild(script)
        })
    }

    async function displayRazorpay() {
        if (status === 'pending') {
            return
        }
        console.log('Init payment')
        setStatus('pending')
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

        if (!res) {
            alert('Razorpay SDK failed to load. Are you online?')
            return
        }

        // creating a new order

        // Getting the order details back
        const amount = price * 100
        // const amount = 13 * 100;
        // const order_id = 12;
        const currency = 'INR'

        const options = {
            key: RAZORPAYKEY, // Enter the Key ID generated from the Dashboard
            // key: "rzp_live_eq3eIK3njfJTQV",
            // secret: "DR6LyTsjDBshJ9k1O7FlDDd6",
            amount: amount.toString(),
            // amount: "1200",
            currency: currency,
            name: 'Vikas Mandal Mulund',
            description: 'Suchi Payment',
            image:
                'https://matrimonialapi.qureal.com/assets/f187d1a4-8bd5-4ec6-bd8d-4f907c787f7b',
            order_id: order_id, // remove it in test mode
            handler: async function (response) {
                console.log('Success Handler response', response)
                setStatus(JSON.stringify(response))
                onSuccess()
                setStatus('success')
                // const data = {
                //     orderCreationId: order_id,
                //     razorpayPaymentId: response.razorpay_payment_id,
                //     razorpayOrderId: response.razorpay_order_id,
                //     razorpaySignature: response.razorpay_signature,
                // };

                // // onSuccess(response.razorpay_payment_id);
                // console.log(response.razorpay_payment_id);
                // payment_mutation.mutate(
                //     {
                //         payment_id: response.razorpay_payment_id,
                //         directus_user: { id: user_id },
                //     },
                //     {
                //         onSuccess: onSuccess, //onSuccess(data);
                //     }
                // );

                // alert("Payment successfully");
            },
            prefill: {
                name: `${name}`,
                email: `${email}`,
                contact: `${mobile || ''}`
            },
            notes: payment_notes,
            theme: {
                color: '#ee3435'
            }
        }

        const rzpy = new window.Razorpay(options)

        rzpy.on('payment.authorized', function (response) {
            console.log('Payment triggered', response)
            onSuccess()
            setStatus('success')
        })

        rzpy.on('payment.captured', function (response) {
            console.log('Payment Captured', response)
            setStatus('success')
        })

        rzpy.on('payment.failed', function (response) {
            console.log('Payment Failed', response)
            onFailed()
            setStatus('failed')
        })

        rzpy.on('order.paid', function (response) {
            console.log('Order paid', response)
            onSuccess()
            setStatus('success')
        })

        rzpy.open()
    }

    return (
        <div>
            <SuchiInfoModal
                order={order}
                open={open}
                setOpen={setOpen}
                onDone={data => {
                    setOpen(false)
                    displayRazorpay();
                    // alert('Payment is currently disablede')
                }}
            />
            <Button
                positive
                icon
                labelPosition='right'
                // onClick={displayRazorpay}
                onClick={() => setOpen(true)}
            >
                Buy Now
                <Icon name='arrow right' />
            </Button>
        </div>
    )
}

export default PaymentPage
