import React from "react";
import { useItemsFilter } from "../_actions/items";
import { useCurrentUser } from "../_actions/auth";
// import { Card } from "semantic-ui-react";
import Cards from "./Cards";
import { useDeleteItem } from "../_actions/mutations/items";
import CandidateList from "../search/CandidateList";

export default function FavView() {
    const {
        data: fav_data,
        isLoading,
        isSuccess,
    } = useItemsFilter(
        "junction_directus_users_qd_matrimonial_profile",
        `?fields=*,qd_matrimonial_profile_id.*&filter[directus_users_id][_eq]=${localStorage.getItem(
            "userid"
        )}`
    );

    //   const
    console.log("favdata", fav_data);

    // return <>{isSuccess && <CandidateList data={fav_data} mode="fav" />}</>;

    return (
        <>
            <div
                style={{
                    //   display: "flex",
                    //   flexWrap: "wrap",
                    //   justifyContent: "center",
                    display: "grid",
                    gridTemplateColumns: "repeat(auto-fit, minmax(22em, 1fr))",
                    gap: "10px",
                }}
            >
                {fav_data?.map((ele, idx) => {
                    return (
                        <Cards
                            data={ele.qd_matrimonial_profile_id}
                            mode="fav"
                        />
                    );
                })}
            </div>
        </>
    );
}
