import React from "react";
import Screen from "./commons/Screen";
import BlockView from "./home/BlockView";
import FavView from "./home/FavView";

export default function Favrouite() {
  return (
    <Screen title="My Favorite Profiles">
      <FavView />
    </Screen>
  );
}
