import { atom, useAtom } from "jotai";
import { atomFamily } from "jotai/utils";
import React, { useEffect } from "react";
import { Icon } from "semantic-ui-react";

const msgAtom = atomFamily((id) => atom(id));

function MessageBox({
    type = "info",
    ukey,
    title,
    content,
    actions,
    allow_close = false,
}) {
    const [show, setShow] = useAtom(msgAtom(ukey || title));

    // useEffect(() => {
    //     setShow(true);
    // }, []);

    if (!show) {
        return <></>;
    }

    return (
        <div className={`msg ${type}`}>
            <h4>{title}</h4>
            {allow_close && (
                <Icon
                    name="close"
                    style={{
                        position: "absolute",
                        top: 10,
                        right: 10,
                    }}
                    onClick={() => setShow(false)}
                    link
                />
            )}
            {content && Array.isArray(content) && (
                <p>
                    {content.map((item) => (
                        <div>{item}</div>
                    ))}
                </p>
            )}
            {content && typeof content === "string" && <p>{content}</p>}
            {actions?.length > 0 && <div>{actions.map((act) => act)}</div>}
        </div>
    );
}

export default MessageBox;
