import styles from "./styles/PrintInput.module.css";
import React from "react";
import { Button, Icon } from "semantic-ui-react";

function PrintInput({ heading="Vikas Mandal Mulund Suchi Print", btn_label = "Print Suchi" }) {
    return (
        <div className={styles.wrapper}>
            <div className={styles.card}>
                <h2 className={styles.heading}>
                    {heading}
                </h2>
                <p>
                    Preffered browser: <b>Google Chrome</b>
                </p>
                <p>
                    <b>Print Settings</b>
                    <br />
                    Layout: <b>Landscape</b>
                    <br />
                    More Settings {">"} Page Size: <b>A4</b>
                    <br />
                    More Settings {">"} Margin: <b>none</b>
                    <br />
                    More Settings {">"} Options:{" "}
                    <b>Enable Background graphics</b>
                    <br />
                </p>
                <Button
                    icon
                    labelPosition="left"
                    onClick={() => {
                        window.print();
                    }}
                    primary
                    size="big"
                >
                    <Icon name="print" />
                    {btn_label}
                </Button>
                <p className={styles.scroll_label}>
                    Scroll down to see preview
                </p>
                <i>(Preview may have overlapping content)</i>
                <Icon name="arrow down" />
            </div>
        </div>
    );
}

export default PrintInput;
