import React, { useMemo, useState } from "react";
import SubscriptionCard from "./SubscriptionCard";
import { useItemsFilterNew } from "../_actions/items";
import MessageBox from "../commons/MessageBox";
import { getTime } from "../_helpers/dt";
import SearchHome from "../search/SearchHome";

function BuySearchSubscription({
    subscription_plan_id = "online_search",
    gender,
    show_only_buy_card = false,
    settings,
    is_tester,
}) {
    const { isLoading, isSuccess, data, refetch } = useItemsFilterNew(
        "qd_subscriptions",
        `?filter[user][_eq]=$CURRENT_USER&filter[plan][_eq]=${subscription_plan_id}`,
        true
    );
    const [sub, setSub] = useState(null);

    console.log("sub data", data?.data);

    const is_subscription_valid = useMemo(() => {
        if (data?.data?.[0]) {
            setSub(data?.data?.[0]);
        }
        const end_date = new Date(data?.data?.[0]?.end_date);
        const today = new Date();
        if (end_date) {
            return end_date >= today;
        }
        return false;
    }, [data]);
    return (
        <div style={{ paddingBottom: "10px" }}>
            {isSuccess && is_subscription_valid && (
                <MessageBox
                    type="success"
                    title="You have valid search subscription"
                    content={`Your subscrition is valid till ${getTime(
                        sub?.end_date,
                        "od"
                    )}`}
                    allow_close={true}
                />
            )}

            {isSuccess && !is_subscription_valid && (
                <SubscriptionCard
                    sub={sub}
                    subscription_plan_id={subscription_plan_id}
                    refetch={refetch}
                    is_tester={is_tester}
                />
            )}
            {isSuccess && !show_only_buy_card && is_subscription_valid && (
                <SearchHome
                    gender={gender}
                    show_gender_switch={
                        settings?.enable_gender_switch || is_tester
                    }
                // gender="Male"
                />
            )}
        </div>
    );
}
//     return <SubscriptionCard subscription_plan_id="online_search" />;
// }

export default BuySearchSubscription;
