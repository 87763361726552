import React, { useCallback, useMemo } from "react";
import { useItemsFilter } from "../_actions/items";
import Cards from "./Cards";

export default function SaveNoteView() {
    const { data, isLoading, isSuccess } = useItemsFilter(
        "matrimonial_profile_notes",
        `?filter[user_created][_eq]=$CURRENT_USER&fields=id,note,matrinonial_profile.qd_matrimonial_profile_id.*`
    );

    const processed_data = useMemo(() => {
        if (isSuccess) {
            const out = {};
            data.forEach((item) => {
                const profile =
                    item?.matrinonial_profile[0]?.qd_matrimonial_profile_id;
                console.log("Now keys", Object.keys(out));
                console.log("Checking with ", profile?.id);
                if (Object.keys(out).includes(profile?.id?.toString())) {
                    const existing_item = out[profile?.id];
                    console.log("profile exists", existing_item);
                    out[profile?.id] = {
                        ...existing_item,
                        note: `${existing_item?.note}, ${item.note}`,
                    };
                } else {
                    out[profile?.id] = {
                        block_id: item.id,
                        profile: profile,
                        note: item.note,
                    };
                }
            });
            return out;
        }
        return {};
    }, [data, isSuccess]);

    console.log("Process data", processed_data);

    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(22em, 1fr))",
                gap: "10px",
            }}
        >
            {Object.keys(processed_data)?.map((key, idx) => {
                const item = processed_data[key];
                return (
                    <Cards
                        data={item?.profile}
                        mode="note"
                        block_id={item?.block_id}
                        note={item?.note}
                    />
                );
            })}
        </div>
    );
}

// export default function SaveNoteView() {
//     const { data: note_data, isLoading } = useItemsFilter(
//         "matrimonial_profile_notes",
//         `?filter[user_created][_eq]=$CURRENT_USER&fields=id,note,matrinonial_profile.qd_matrimonial_profile_id.*`
//     );

//     return (
//         <div
//             style={{
//                 display: "grid",
//                 gridTemplateColumns: "repeat(auto-fit, minmax(22em, 1fr))",
//                 gap: "10px",
//             }}
//         >
//             {note_data?.map((ele, idx) => {
//                 console.log(ele);
//                 return (
//                     <Cards
//                         data={
//                             ele?.matrinonial_profile[0]
//                                 ?.qd_matrimonial_profile_id
//                         }
//                         mode="note"
//                         block_id={ele.id}
//                         note={ele.note}
//                     />
//                 );
//             })}
//         </div>
//     );
// }
