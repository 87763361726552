import React, { useEffect, useState } from "react";
import { Button, Form, Message } from "semantic-ui-react";
import { useLogin, useMe } from "../_actions/auth";
import { saveUser } from "../_helpers/auth";
import { history } from "../_helpers/history";
import { queryClient } from "../_helpers/query";
import CenterCard from "../commons/CenterCard";
import { useNavigate } from "react-router-dom";

function Login() {
    const [user, setUser] = useState({ email: "", password: "" });
    const navigate = useNavigate();
    const { login, loading, success, errors } = useLogin();
    const { isLoading, isSuccess, data } = useMe();
    const isLoggedIn = isSuccess && data?.data !== undefined;

    useEffect(() => {
        if (isSuccess) {
            saveUser(data);
        }
    }, [isSuccess, data]);

    useEffect(() => {
        queryClient.invalidateQueries("user");
    }, []);

    console.log("Location", history.location);

    const sendHome = () => {
        let from = history.location?.pathname || "/candidate/view";
        if (from.startsWith("/login")) {
            from = "/";
        }
        console.log("Senfing to ", from);
        navigate(from);
    };

    useEffect(() => {
        if (success && !loading) {
            sendHome();
        }
        if (isLoggedIn) {
            sendHome();
        }
    }, [success, loading, isLoggedIn]);

    const handleChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    if (isLoading) {
        // return <AppSplash msg="Auto Logging in..." />;
        return <h3>Auto logging in...</h3>;
    }

    //   const loginNow = () => {
    //     let mobile = user.mobile;
    //     if (!mobile.toString().startsWith("91")) {
    //       mobile = `91${mobile}`;
    //     }
    //     if (!isValidPhoneNumber(mobile)) {
    //       // valid error
    //       // setErr({
    //       //     mobile: "Please enter valid format",
    //       // });
    //     } else {
    //       const email = `${mobile}@vmm.com`;

    //       console.log("Email", email);
    //       login({
    //         email: email,
    //         password: user.password,
    //       });
    //     }
    //   };

    const loginNow = () => {
        const email = user.email;

        console.log("Email", email);
        login({
            email: email,
            password: user.password,
        });
    };

    return (
        <CenterCard height="100vh">
            <h3 style={{ textAlign: "center" }}>Login</h3>
            {errors.length > 0 &&
                errors.map((item) => (
                    <div key={item.message}>
                        <Message error content={item.message} />
                        <br></br>
                    </div>
                ))}
            <Form error={!!errors} onSubmit={loginNow}>
                <Form.Field required>
                    <label>Email</label>
                    <input
                        required
                        placeholder="Email"
                        name="email"
                        value={user.mobile}
                        onChange={handleChange}
                    />
                </Form.Field>
                <Form.Field required>
                    <label>Password</label>
                    <input
                        required
                        type="password"
                        placeholder="Password"
                        name="password"
                        value={user.password}
                        onChange={handleChange}
                    />
                </Form.Field>
                <Form.Field
                    //   control={Button}
                    as={"p"}
                    onClick={() => {
                        navigate("/admin/reset-password");
                    }}
                    content={"Forget Password?"}
                    //   label="Forgot Password?"

                    style={{
                        color: "#6473fa",
                        backgroundColor: "white",
                        cursor: "pointer",
                    }}
                />
                <Button type="submit" loading={loading} color="red" fluid>
                    Login
                </Button>
            </Form>
            <div
                style={{
                    padding: "7px",
                    textAlign: "center",
                    width: "100%",
                }}
            >
                <a href="/register">New user? create account</a>
            </div>
        </CenterCard>
    );
}

export default Login;

function isValidPhoneNumber(phoneNumber) {
    const regex = /^91\d{10}$/;
    return regex.test(phoneNumber);
}
