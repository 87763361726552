import React, { useEffect, useState } from "react";
import CardsLg from "../CardsLg";
import {
    useItems,
    useItemsFilter,
    useMeSeprateItems,
} from "../../_actions/items";
import Screen from "../../commons/Screen";
import { useParams } from "react-router-dom";
import Profile from "./Profile";
import Profession from "./Profession";
import "./styles/Profile.css";
import Tabs from "./Tabs";
import Family from "./Family";
import { useMediaQuery } from "@uidotdev/usehooks";

export default function DetailPage() {
    const isMobile = useMediaQuery("only screen and (max-width : 768px)");
    const { id } = useParams();
    const [active_tab, setActiveTab] = useState("general");
    const { data } = useItemsFilter(
        `qd_matrimonial_profile/${id}`,
        "?fields=*.*"
    );

    // console.log(data);

    const isFav = () => {
        const isFavarr = data?.directus_users?.filter(
            (e) => e?.directus_users_id == localStorage.getItem("userid")
        );
        return isFavarr?.length > 0 ? isFavarr[0].id : false;
    };

    const getActive = (data) => {
        setActiveTab(data);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Screen
            // title="Profile View"
            >
                <div
                    style={{
                        display: "flex",
                        flexWrap: "wrap",
                        // justifyContent: "space-evenly",
                    }}
                >
                    <CardsLg data={data} isFav={isFav()} />
                    <div
                        style={{
                            margin: isMobile
                                ? "40px 0 10px 0"
                                : "0 auto 0 auto",
                            // border: "1px dotted blue",
                        }}
                    >
                        <Tabs setActive={getActive} active={active_tab} />
                        {active_tab === "general" && <Profile data={data} />}
                        {active_tab === "profession" && (
                            <Profession data={data} />
                        )}
                        {active_tab === "family" && <Family data={data} />}
                    </div>
                </div>
                {/* <ScrollToTop /> */}
            </Screen>
        </>
    );
}
