import "semantic-ui-css/semantic.min.css";
import { queryClient } from "./_helpers/query";
import { QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { history } from "./_helpers/history";
import AppRoutes from "./AppRoutes";
import "./styles/main.css";
import { restoreAccessToken, setAuthToken } from "./_helpers/auth";

const access_token = restoreAccessToken();
if (access_token) {
    setAuthToken(access_token);
} else {
    setAuthToken();
}

function App() {
    return (
        <BrowserRouter history={history}>
            <QueryClientProvider client={queryClient}>
                <AppRoutes />
            </QueryClientProvider>
        </BrowserRouter>
    );
}

export default App;
