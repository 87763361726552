import React, { useEffect, useState } from "react";
import SettingsSidebar from "./SettingSidebar";
import styles from "./styles/Settings.module.css";
import { Outlet } from "react-router-dom";
import { useCurrentRole } from "../_actions/roles";
import { useCurrentUser } from "../_actions/auth";
import { Checkbox } from "semantic-ui-react";
import { useItemsFilter } from "../_actions/items";
import { useUpdateItem } from "../_actions/mutations/items";
import ProfileStatusBox from "./ProfileStatusBox";

function Settings() {
    const { role_name } = useCurrentRole();
    const user_me = useCurrentUser();
    const { data: settingInfo } = useItemsFilter(
        "admin_setting",
        "?fields=isactive_search,isactive_suchibook"
    );

    const updateStatus = useUpdateItem("admin_setting");

    const handleOnChange = (e, data) => {
        updateStatus.mutate({ id: "", data: { [data.name]: data.checked } });
    };

    // (user_me?.matrimonial_profiles[0]?.status);
    const is_profile_created = user_me?.matrimonial_profiles?.length > 0;
    let status = user_me?.matrimonial_profiles[0]?.status;
    let rejection_reason = user_me?.matrimonial_profiles[0]?.rejection_reason;

    return (
        <>
            <div
                style={{
                    padding: "20px 10px 0px 10px",
                    marginBottom: "-10px",
                }}
            >
                <ProfileStatusBox
                    status={status}
                    is_profile_created={is_profile_created}
                    rejection_reason={rejection_reason}
                />
            </div>

            <div className={styles.wrapper}>
                <SettingsSidebar />

                <div className={styles.main}>
                    <Outlet />
                </div>
            </div>
        </>
    );
}

export default Settings;
