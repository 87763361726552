import React, { useEffect, useMemo } from "react";
import { useItemsFilter } from "../_actions/items";
import BuySuchiCard from "./BuySuchiCard";
import BuySuchOnHold from "./BuySuchiOnHold";
import { useCreateOrderMutation } from "../_actions/mutations/items";
import { useCurrentUser } from "../_actions/auth";
import { SUCHI_PRICE } from "../constants";

function BuySuchiAll() {
    const orderCreateMutation = useCreateOrderMutation(() => { });

    const { isLoading, isSuccess, data } = useItemsFilter(
        "qd_order",
        "?filter[user][_eq]=$CURRENT_USER&filter[is_archived][_eq]=false",
        true
    );
    const user = useCurrentUser();

    console.log("User ", user);

    useEffect(() => {
        if (isSuccess && data.length === 0 && orderCreateMutation.isIdle) {
            console.log("Creating new order");
            orderCreateMutation.mutate({
                amount: SUCHI_PRICE * 100,
                user: user?.id,
            });
        }
    }, [isSuccess]);

    const is_pending_order = useMemo(() => {
        return data?.some((item) => item.status === "pending");
    }, [data]);

    return (
        <div style={{ padding: "10px 0" }}>
            {isSuccess && data?.length > 0 && (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        paddingBottom: "10px",
                    }}
                >
                    {data.map((item) => (
                        <BuySuchiCard
                            // <BuySuchOnHold
                            order={item}
                            buyMore={() => {
                                console.log("Creating more new order");
                                orderCreateMutation.mutate({
                                    amount: SUCHI_PRICE * 100,
                                    user: user?.id,
                                });
                            }}
                            is_pending_order={is_pending_order}
                        />
                    ))}
                </div>
            )}
        </div>
    );
}

export default BuySuchiAll;
