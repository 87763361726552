import React, { useMemo } from "react";
import MessageBox from "../commons/MessageBox";
import { Button, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";

function ProfileStatusBox({ status, is_profile_created, rejection_reason }) {
    const data = useMemo(() => {
        if (!is_profile_created) {
            return {
                type: "info",
                title: "Create your candidate profile",
                content:
                    "Fill all your information of your candidate to move forward.",
                actions: [
                    <Link to="/candidate/create">
                        <Button positive icon labelPosition="right">
                            Create Profile
                            <Icon name="arrow right" />
                        </Button>
                    </Link>,
                ],
            };
        }
        if (status === "Draft") {
            return {
                type: "info",
                title: "Your account is in draft state. please complete your profile",
                content:
                    "Your candidate profile is in draft state. complete it to publish",
                actions: [
                    <Link to="/candidate/edit">
                        <Button positive icon labelPosition="right">
                            Complete Profile
                            <Icon name="arrow right" />
                        </Button>
                    </Link>,
                ],
            };
        } else if (status === "Active") {
            return { type: "success", title: "Your accout is active" };
        } else if (status === "Pending") {
            return {
                type: "info",
                title: "Your account is pending for approval",
                content:
                    "You will receive mail once your profile is approved/rejected",
            };
        } else if (status === "Blocked") {
            return { type: "error", title: "Your account is blocked" };
        } else if (status === "Rejected") {
            return {
                type: "error",
                title: "Your account is rejected",
                content: rejection_reason,
            };
        }
        return {};
    }, [status, is_profile_created]);

    return (
        <div>
            {data?.type && (
                <MessageBox
                    type={data.type}
                    title={data.title}
                    content={data.content}
                    actions={data.actions || []}
                />
            )}
        </div>
    );
}

export default ProfileStatusBox;
