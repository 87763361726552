import React, { useMemo, useState } from "react";
import PaymentManager from "./PaymentManager";
import { useItemsFilterNew } from "../_actions/items";
import { getTime } from "../_helpers/dt";
import { Button, Header, Icon, Segment } from "semantic-ui-react";
import MessageBox from "../commons/MessageBox";
import {
    SEARCH_SUBSCRIPTION_PRICE,
    SEARCH_SUBSCRIPTION_PRICE_TESTER,
} from "../constants";

function SubscriptionCard({
    sub,
    subscription_plan_id = "online_search",
    refetch,
    is_tester = false,
}) {
    return (
        <Segment placeholder>
            <Header icon>
                <Icon
                    name="search"
                    style={{ color: "#0003", fontSize: "3em" }}
                />
                Online Search Subscription
                <br />
                <div
                    style={{
                        marginTop: "10px",
                        color: "blue",
                    }}
                >
                    INR{" "}
                    {is_tester
                        ? SEARCH_SUBSCRIPTION_PRICE_TESTER
                        : SEARCH_SUBSCRIPTION_PRICE}
                    /-
                    {is_tester && " (for tester)"}
                </div>
            </Header>
            {!sub?.end_date && (
                <p style={{ textAlign: "center", fontSize: "1.1em" }}>
                    *This will allow you to search for other candiates online.
                </p>
            )}
            {sub?.end_date && (
                <p
                    style={{
                        color: "red",
                        textAlign: "center",
                        fontSize: "1.1em",
                    }}
                >
                    Your subscription expired on {getTime(sub?.end_date, "od")}
                </p>
            )}
            <PaymentManager
                order_type="subscription"
                order_data={{
                    subscription_plan_id: subscription_plan_id,
                }}
                amount={
                    is_tester
                        ? SEARCH_SUBSCRIPTION_PRICE_TESTER
                        : SEARCH_SUBSCRIPTION_PRICE
                }
                onSuccess={() => {
                    refetch();
                    setTimeout(refetch, 1000);
                    setTimeout(refetch, 2000);
                }}
                onFailed={() => {}}
                trigger={
                    <Button positive labelPosition="right" icon>
                        <Icon name="arrow right" />
                        {sub?.end_date > 0
                            ? "Renew Subscription"
                            : "Buy Subscription"}
                    </Button>
                }
            />
        </Segment>
    );
}

export default SubscriptionCard;
