import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "semantic-ui-react";

export default function TermsAndCondition() {
  const navigate = useNavigate("/");
  return (
    <div
      style={{
        margin: "1.5em",
      }}
    >
      <div>
        <Button
          primary
          floated="right"
          onClick={() => {
            navigate("/register");
          }}
        >
          I accept
        </Button>
      </div>
      <strong>
        <b>Terms &amp; conditions</b>
      </strong>
      <p />
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>Dear User,</p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        Welcome to Vikas Mandal Mulund website.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        Vikas Mandal Mulund, is a public charitable trust registered under
        Mumbai Public Trust Act, 1950 vide Registration No. BOM-E 4412, ESTD.
        1970. We are also registered with Public Health Department, BMC
        Registration No: T Ward/Reg No 4 dated 6th July 2016. Our registered
        address - 101, Jai Laxminarayan Society, Lokmanya Tilak Road, Mulund
        (East), Mumbai 400081.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        From the year 2015, Vikas Mandal, Mulund is providing users or members
        the facility of registering profile information online for prospective
        brides and grooms to facilitate lawful marriages between persons of Leva
        Patidar community. Anyone desirous of using the services offered will be
        required to register their information online and become a member of
        Vikas Mandal Mulund’s Vadhu Var Suchi for the next one year. All such
        members desiring to register and communicating with other members of the
        Vadhu Var Suchi for the next one year must carefully read the following
        terms and conditions of use and should follow the instructions as
        provided in the registration process.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        ""User" or ""Member" means any person who accesses or avails the site of
        the Mandal for the purpose of hosting, publishing, sharing, transacting,
        displaying or uploading information or views and includes other persons
        jointly participating in using the site of the Mandal.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        By using / registering as a member of www.vikasmandalmulund.com, the
        member / user accepts to abide by the terms and conditions (hereinafter
        referred to as "Agreement") set forth herein. This agreement sets forth
        the terms and conditions for the membership of Vikas Mandal’s Vadhu Var
        Suchi (hereinafter referred to as "Suchi") and may be subject to
        modifications / amendments by Vikas Mandal Mulund (hereinafter referred
        to as "Mandal") from time to time.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        <strong>ELIGIBILITY CRITERIA</strong>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        www.vikasmandalmulund.com ((hereinafter referred to as "website")
        website is intended to provide a platform for facilitating lawful
        marriages between persons of Leva Patidar community, who are legally
        competent to enter into such matrimonial relation under the laws of the
        country by which they are governed.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        In order to become / register as a member of the Suchi, the member must
        have attained the marriageable age according to the laws of the country
        which are applicable to and which govern the member / user.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        By becoming a member and / or using this website , the member / user
        represents and warrants that he / she has the right, authority, capacity
        and is legally competent to enter into this Agreement and that the
        member / user is not prohibited or prevented by any applicable law in
        force for the time being or any other order or decree or injunction from
        any court, tribunal or any such competent authority restraining the
        member / user from entering into this Agreement. Any membership / use of
        the services provided by this website is void where the same is
        expressly prohibited under law or any order of any competent authority.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        If at any point of time Mandal has a reason to believe that the
        registered user is ineligible to be a member or that the member has
        misrepresented or not disclosed any material information about his / her
        eligibility or is in breach of the terms and conditions of this
        Agreement, the Mandal reserves the right to terminate the membership and
        the right to use the services provided by Mandal through its website
        without assigning any reasons thereof and without any compensation in
        any form/ shape or manner to such member.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        In case membership is discontinued for any reason, new profile creation
        by the same email id for a member shall not be allowed. In India, users
        below 18 years age are not authorized to use these matrimony services at
        the Website.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>TERMS OF USE</p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        Vikas Mandal Mulund mobile application specific terms
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        By downloading or using the App, these terms will automatically apply to
        you – you should make sure therefore that you read them carefully before
        using the app. You’re not allowed to copy, or modify the app, any part
        of the app, or our trademarks in any way. You’re not allowed to attempt
        to extract the source code of the app, and you also shouldn’t try to
        translate the app into other languages, or make derivative versions. The
        app itself, and all the trade marks, copyright, database rights and
        other intellectual property rights related to it, still belong to Vikas
        Mandal Mulund.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        Vikas Mandal Mulund is committed to ensuring that the app is as useful
        and efficient as possible. For that reason, we reserve the right to make
        changes to the app or to charge for its services, at any time and for
        any reason. We will never charge you for the app or its services without
        making it very clear to you exactly what you’re paying for.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        The VikasMandal app stores and processes personal data that you have
        provided to us, in order to provide our Service. It’s your
        responsibility to keep your phone and access to the App secure. We
        therefore recommend that you do not jailbreak or root your phone, which
        is the process of removing software restrictions and limitations imposed
        by the official operating system of your device. It could make your
        phone vulnerable to malware/viruses/malicious programs, compromise your
        phone’s security features and it could mean that the VikasMandal app
        won’t work properly or at all.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        The App does use third party services that declare their own Terms and
        Conditions. The links to Terms and Conditions of third party service
        providers used by the app as as below:
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        Google Play Services
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        Google Analytics for Firebase
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        Firebase Crashlytics
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        You should be aware that there are certain things that Vikas Mandal
        Mulund will not take responsibility for. Certain functions of the app
        will require the app to have an active internet connection. The
        connection can be Wi-Fi, or provided by your mobile network provider,
        but Vikas Mandal Mulund cannot take responsibility for the app not
        working at full functionality if you don’t have access to Wi-Fi, and you
        don’t have any of your data allowance left.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        If you’re using the app outside of an area with Wi-Fi, you should
        remember that your terms of the agreement with your mobile network
        provider will still apply. As a result, you may be charged by your
        mobile provider for the cost of data for the duration of the connection
        while accessing the app, or other third party charges. In using the app,
        you’re accepting responsibility for any such charges, including roaming
        data charges if you use the app outside of your home territory (i.e.
        region or country) without turning off data roaming. If you are not the
        bill payer for the device on which you’re using the app, please be aware
        that we assume that you have received permission from the bill payer for
        using the app.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        Along the same lines, Vikas Mandal Mulund cannot always take
        responsibility for the way you use the app i.e. You need to make sure
        that your device stays charged – if it runs out of battery and you can’t
        turn it on to avail the Service, Vikas Mandal Mulund cannot accept
        responsibility.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        With respect to Vikas Mandal Mulund’s responsibility for your use of the
        App, when you’re using the App, it’s important to bear in mind that
        although we endeavour to ensure that it is updated and correct at all
        times, we do rely on third parties to provide information to us so that
        we can make it available to you. Vikas Mandal Mulund accepts no
        liability for any loss, direct or indirect, you experience as a result
        of relying wholly on this functionality of the app.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        At some point, we may wish to update the app. The app is currently
        available on Android – the requirements (including for any additional
        systems we decide to extend the availability of the app to) may change,
        and you’ll need to download the updates if you want to keep using the
        app. Vikas Mandal Mulund does not promise that it will always update the
        app so that it is relevant to you and/or works with the Android &amp;
        iOS version that you have installed on your device. However, you promise
        to always accept updates to the application when offered to you, We may
        also wish to stop providing the app, and may terminate use of it at any
        time without giving notice of termination to you. Unless we tell you
        otherwise, upon any termination, (a) the rights and licenses granted to
        you in these terms will end; (b) you must stop using the app, and (if
        needed) delete it from your device.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        The Mandal’s Suchi is intended for the personal use of the individual
        members / users of the website and not for any commercial use by any
        member / user.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        The Mandal reserves the right to monitor the activity &amp; status of
        the account of each member and further block the account of a member
        based on such review in its sole discretion.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        Any member / user is not permitted to register his / her multiple
        profiles and the Mandal reserves the right to deactivate any or all
        multiple profiles of a single member without any compensation whatsoever
        in respect of such multiple profile(s).
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        Any member / user of the website is not permitted to,
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        : engage / indulge in advertising to or solicitation of other members /
        users in any manner to buy or sell any products or services through the
        service provided by the website without the express written permission
        of the Mandal.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        : transmit any chain letters or junk emails to other Suchi members.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        : using any information obtained from the services provided by the Suchi
        in order to harass, abuse or harm any another person or in order to
        contact, advertise to, solicit, or sell to any other member / user any
        products or services without their prior explicit consent.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        : using any information obtained from the services provided by the Suchi
        in respect of other members for personal / commercial use and / or in an
        illegal manner and / or for purposes other than as intended under this
        Agreement.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        : send any obscene / unwarranted communication to any other members /
        users of the Suchi.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        : post any unwarranted / obscene content / material in his / her profile
        and / or on the website .
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        In order to protect the website, Suchi and / or its members / users from
        any abuse / misuse, the Mandal reserves the right to restrict the number
        of profiles, communications, contacts, responses and emails which a
        member may send to other members / users to a number which the Mandal
        deems appropriate in its sole discretion.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        The member / user shall not be permitted to use any automated process or
        any other programs / scripts to view content on or to communicate /
        contact / respond / interact with the website users or its other
        members.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        The Mandal shall have the right to regulate, screen, track and / or
        filter any communication, information, message or any material that
        members might send to the other members / users at its sole discretion,
        without assigning any reason and without any notice in respect of the
        same to the member / user.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        Membership status of the member shall not be displayed in the profile
        content and the Mandal reserves the right to delete any such reference
        made by the member in this regard.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        <strong>TERMINATION</strong>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        The member is free to terminate his / her membership at any time during
        the subsistence of this Agreement for any reason by giving prior written
        notice to the Mandal. In the event of such termination, the member shall
        not be entitled to any compensation in any form / shape or manner.
        Member's access to website as well as the membership shall be terminated
        effective upon sending notice at the email address provided by the
        member in the application form at the time of becoming a member.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        In the event of any breach of the terms and conditions of this Agreement
        by the member / user, the Mandal shall have the right to terminate the
        membership of such member / user at its sole discretion without giving
        any notice in respect of the same. Immediately upon termination of the
        membership by the Mandal, the member / user shall not be entitled to use
        / avail any services provided by the Mandal. Further, the member shall
        not be entitled to any compensation whatsoever.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        <strong>PROPRIETARY / OWNERSHIP RIGHTS</strong>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        All the contents of the website and the Suchi, including text, graphics,
        logos, pictures, icons, images, audio clips, video clips, digital
        downloads, data compilations, trade marks, trade dress, software,
        copyrighted information is the exclusive property of Vikas Mandal,
        Mulund or its content suppliers and is subject to and protected by the
        applicable laws of India and international Copyright and Trade Mark laws
        and the member / user acknowledges the same.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        The Mandal provides its members / users limited license to access and
        make personal use of the website www.vikasmandalmulund.com for purposes
        as specified in this Agreement. The members / users of the website are
        restricted from copying, modifying, publishing, transmitting,
        distributing, performing, selling or otherwise using any such
        information of the website or the Suchi, in any manner whatsoever, which
        is proprietary in nature, save and except the information which is in
        the public domain or for which express permission has been given by the
        Mandal.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        Any act or attempt in violation of any of the above clauses on the part
        of the member / user shall constitute a breach of this Agreement and
        shall give exclusive rights to the Mandal to terminate the membership of
        such member without any compensation.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        <strong>
          RIGHTS IN RESPECT OF THE CONTENTS POSTED ON Vikas Mandal’s Vadhu Var
          Suchi
        </strong>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        The member understands and agrees that the Mandal may delete any
        content, photographs and profiles (hereinafter collectively referred to
        as "Content") that in the Mandal’s view contravenes any of the
        provisions of this Agreement and which is offensive, obscene, illegal or
        that might act to the detriment of either the Mandal or any of its
        registered members / users, including but not limited to
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        content in any manner which,
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        : promotes physical harm of any kind, racism, bigotry or hatred against
        any group or individual and is patently offensive to the community.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        : causes harassment to another person.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        : provides information about illegal activities such as buying or making
        of illegal weapons, providing or creating computer viruses or violating
        someone's privacy.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        : contains hidden images or pages (those not linked to or from another
        accessible page).
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        : provides information about illegal activities such as buying or making
        of illegal weapons, providing or creating computer viruses or violating
        someone's privacy.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        : transmission of any spam, junk mail, chain letters or unsolicited mass
        mailing.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        : false, misleading profiles or profiles which promote illegal
        activities or conduct that is defamatory, abusive, obscene, threatening,
        libelous.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        : promotes any information which is an unauthorized copy of some
        copyrighted works, links to pirated computer programs, music or
        providing information to circumvent the manufacture installed copy
        protected devices.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        : providing restricted passwords to access pages.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        : display of any lascivious, sexually explicit or pornographic material
        or any content which tends to incite or incites people under the age of
        18 years in a sexual or violent manner, or solicits information for
        anyone under 18 years.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        : soliciting passwords or personal identifying information for unlawful
        or commercial purposes from any other users.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        The member hereby grants to the Mandal and to authorities of the Mandal,
        an unconditional and irrevocable license to distribute, reproduce,
        modify, adapt, use, display and perform publicly any Content submitted
        or made available by the member for inclusion on publicly accessible
        areas of the website including but without limitation the member's
        contact details.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        The Mandal or any member of the management committee of the Mandal does
        not guarantee the accuracy, authenticity, quality or integrity of the
        Content posted on the website by the members and has no control over
        such Content. The member or the person from which the Content originated
        shall be solely responsible for transmission of all information, data,
        text, photographs, messages and graphic or pictorial representations,
        tags or any other Content whether publicly posted or privately
        transmitted or otherwise made available to the Mandal or any of its
        mgmt. committee members. Other members, users or any other person shall
        not hold the
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        Mandal is not responsible for any Content that is uploaded, displayed,
        posted, e-mailed, transmitted or otherwise made available through the
        services provided by the Mandal. The Mandal shall not be responsible in
        the event of such Content being false / wrong / untrue / incorrect /
        inaccurate / fabricated. Under no circumstances will the Mandal be
        liable for any content, errors and / or omissions in any Content, or any
        loss or damage resulting from any kind of the use of any Content posted,
        emailed, transmitted or otherwise made available through the service.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        The Mandal may require the member to provide any documentary or material
        evidence supporting the Content posted by the member on the website and
        reserves the right to check and verify the authenticity of the Content
        posted by the member or user on the website. In the event the member
        fails to furnish such information, the Mandal may either remove such
        Content or may terminate the membership of such member without any
        compensation whatsoever.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        For the purposes of this Agreement, the member represents and warrants
        that the member has the authority to grant to the Mandal a perpetual,
        non-exclusive, irrevocable, and worldwide license to use, copy, perform,
        distribute and display such information and Content, as may be required
        for the purposes of any derivative work or for incorporating in other
        works by posting the information and Content in any public domain of the
        Mandal and to grant, authorize and sublicense the same.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        The Mandal shall have the power to investigate and to take appropriate
        legal action in its sole discretion to investigate against any member
        who violates this Agreement, including removing the offending content /
        communication / data from the website and terminating the membership of
        such member without any compensation whatsoever.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        The members shall use the services of the website in a manner that is
        conducive and consistent with the applicable, local, state and federal
        laws, rules and regulations.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        <strong>COPYRIGHT POLICY</strong>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        Any member / user shall not post, distribute or reproduce any
        copyrighted material, trade mark or any other proprietary information on
        www.vikasmandalmulund.com unless expressly authorised to do so by the
        owner thereof.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        In the event that any member or user has a reason to believe that any of
        the material / information posted on the website has been copied from
        his / her work in such a way that it constitutes infringement of his /
        her copyright in such work, such member / user shall provide the Mandal
        with the following information in order to enable the Mandal to take
        appropriate action.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        <strong>
          Description and particulars of the infringing content that is located
          on the website.
        </strong>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        letter specifying that such user / member is the bonafide user of the
        copyright or trade mark or any other proprietary information as the case
        may be and that he / she has firm belief that the disputed user is not
        the owner of the copyright or trade mark.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        Personal information of such user / member including the name, address,
        contact details, etc.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        A statement to the effect that the details as provided are true and
        would invite the penalty of perjury if proven wrong.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        Details of all notices of copyright and / or trade mark infringement,
        orders, injunction applications or any other legal proceeding that have
        been filed / are pending in the appropriate forums.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        If the copyrighted information or trade mark or and other proprietary
        information has been assigned / licensed to the member / user, then a
        letter from such owner specifying that such user / member is the
        bonafide user of such copyright or trade mark or other proprietary
        information and is authorised to take action in respect of the same.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        <strong>MEMBER DISPUTES</strong>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        The member is solely responsible for their interactions with other
        members listed in the Suchi. The Mandal reserves the right but does not
        have any obligation under this Agreement to monitor the disputes between
        the members of the Suchi. Further, the Mandal also reserves the right to
        take appropriate action against errant members but is not obliged to
        share such action details with other members.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        The Mandal shall not be responsible / liable in respect of any
        interactions or transactions between the members themselves.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        <strong>DISCLAIMERS</strong>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        The information provided in the Suchi is on an "as is" and "as
        available" basis and therefore the Mandal makes no representations or
        warranties of any sort whether express or implied as to the operation or
        any information, content, materials or products included on this site.
        The member expressly agrees that the member is accessing this website at
        his / her own sole risk and shall not hold the Mandal liable /
        responsible for any damage caused to him / her by use of the website .
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        The Mandal does not warrant that the site or the server or any e-mail
        sent by the Mandal is free of viruses, worms, Trojans or any other
        harmful programs and the Mandal or its licensees shall not be liable for
        any damages including but not limited to the direct, indirect,
        incidental, punitive and consequential damages, of any kind arising from
        the use of the website, or the services of the website .
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        The Mandal will not be liable for any kind of inaccurate information or
        content posted on the website in connection with the Mandal’s service,
        whether such inaccurate information is posted by members / users or is
        due to any malfunction of its servers, computers, equipment or machines
        and mechanism associated with the utilization of the services.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        The Mandal absolves itself from any liabilities caused due to any
        problems including but not limited to, technical malfunctions to the
        telephone lines, LAN, network, computer line-in systems, softwares,
        service providers, failure of email on account of a internet traffic
        congestion with the Mandal’s service.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        The member shall in no way hold the Mandal liable for any loss or damage
        caused to any member / user arising out of or subsequent to any
        relations established pursuant to the use of the services.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        The Mandal disclaims any liability or responsibility expressly,
        whatsoever and howsoever arising as a result of any Content posted on
        the website made available to the Mandal by any member / user of the
        website and / or any third party.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        The Mandal shall not be responsible or liable for any illegal Content
        posted on the website by any member / user of the website and / or any
        third party.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        <strong>LIMITATION ON LIABILITY</strong>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        In no event shall Vikas Mandal be liable for the acts of the third
        parties for any kind of damages whether direct or indirect including
        loss of profits, arising from the use of the Mandal’s website or
        services provided on the website or the Suchi.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        <strong>GOVERNING LAW AND JURISDICTION</strong>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        All matters arising out of or relating to this Agreement including any
        dispute arising under this Agreement between any member / user and the
        Mandal and / or involving the website www.vikasmandalmulund.com shall be
        governed by the laws of India and be subject to the exclusive
        jurisdiction of the competent courts of Mumbai.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        <strong>INDEMNITY</strong>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        The member / user agrees to indemnify and hold Vikas Mandal, Mulund, its
        affiliates, subsidiaries, agents, officers, employees and other partners
        harmless from any liability, loss, demand or claim made by any other
        member / user / third party due to or arising out of the use of the
        website www.vikasmandalmulund.com or the services provided by the
        Mandal, including reasonable attorney's fees, in violation of this
        Agreement and / or arising from a breach of these terms of use and / or
        any breach of the member / user representations and warranties and / or
        any fraudulent act on the part of the member / user.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        <strong>GENERAL</strong>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        By becoming a member of the Mandal’s website and thereby agreeing to
        list the candidate information in the Suchi, the member agrees to
        receive emails and / or SMSs from Vikas Mandal, Mulund. The member may
        manage subscription to these emails by emailing to the mandal or giving
        express notice in writing for the same. The member further confirms that
        as on the date of registration, the member is not on the DND (Do not
        disturb) subscribers list with any telecom operator or authority. In the
        event that the member is a DND subscriber, the member agrees not to
        object to any message sent to him / her by Vikas Mandal, Mulund or any
        of its affiliates. The user/Member grants permission to The Mandal for
        contacting user/Member, through any electronic/ physical media, for
        further information about the programs and projects of the Mandal.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        Each provision of this Agreement shall be considered severable and if
        for any reason any provision of this Agreement is determined by the
        court of law to be invalid or unenforceable and contrary to Indian laws
        or existing or future applicable laws, such invalidity shall not impair
        the operation of or affect those provisions of this Agreement which are
        valid. In such case, the invalid or unenforceable provision shall be
        amended in such reasonable manner as achieves the intention of this
        Agreement without illegality or if the same is not possible, then such
        provision may be severed from this Agreement and the remaining
        provisions of this Agreement shall remain in full force and effect.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        The provisions of this Agreement can be amended from time to time by
        Vikas Mandal, Mulund.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        This Agreement shall be deemed to be accepted upon the completion and
        use of the Registration process for membership of this website and all
        members will be deemed to have read, understood and accepted the terms
        and conditions before registering as a member with the website .
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        <strong>Changes to This Terms and Conditions</strong>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        We may update our Terms and Conditions from time to time. Thus, you are
        advised to review this page periodically for any changes. We will notify
        you of any changes by posting the new Terms and Conditions on this page.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        These terms and conditions are effective as of 1<sup>st</sup> July 2023.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        <strong>Contact Us</strong>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        If you have any questions or suggestions about our Terms and Conditions,
        do not hesitate to contact us at support@vikasmandalmulund.com
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.28cm" }}>
        (check Box) I have read and agree to the Terms of Use &amp; Privacy
        Policy
      </p>
    </div>
  );
}
