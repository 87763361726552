import React from "react";
import { Link } from "react-router-dom";
import { Button, Icon } from "semantic-ui-react";

function NoCandidate({ text, action }) {
    return (
        <div
            style={{
                width: "100%",
                height: "250px",
                border: "1px dashed #ccc",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                color: "#ccc",
                fontSize: "1.2em",
            }}
        >
            <Icon name="user" />
            <p>{text}</p>
            {action}
        </div>
    );
}

export default NoCandidate;
