import React from "react";
import Screen from "../commons/Screen";
import ListSubscriptions from "./ListSubscriptions";
import { BASE_URL } from "../constants";
import { Button, Icon } from "semantic-ui-react";
import { restoreAccessToken } from "../_helpers/auth";

function SubscriptionManagement() {
    return (
        <Screen
            title="Search Subscriptions"
            actions={[
                <a
                    href={`${BASE_URL}items/qd_subscriptions?sort=-start_date&limit=-1&filter[status][_eq]=active&fields=user.first_name,user.last_name,user.email,start_date,user.matrimonial_profiles.id,payments.payment_id,payments.order_id&export=csv&access_token=${restoreAccessToken()}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    <Button icon labelPosition="left" primary>
                        <Icon name="download" /> Download
                    </Button>
                </a>,
            ]}
        >
            <ListSubscriptions />
        </Screen>
    );
}

export default SubscriptionManagement;
