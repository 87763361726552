import axios from 'axios'
import { BASE_URL } from '../constants'
import { queryClient } from '../_helpers/query'
import { useMutation, useQuery } from 'react-query'
import {
  publicAxios,
  resetTokens,
  restoreRefreshToken,
  saveTokens,
  secureAxios,
  setAuthToken
} from '../_helpers/auth'
import { useMemo, useState } from 'react'
import { useAtom } from 'jotai'
import { authAtom } from '../db/authDb'
import { history } from '../_helpers/history'

const log = console.log

export const useRegister = (
  onRegisterSuccess = () => {},
  onFailed = () => {}
) => {
  const [, setAuth] = useAtom(authAtom)
  return useMutation(
    newItem => publicAxios.post('/qe_auth/register', newItem),
    // .then((res) => res.data),
    {
      onSuccess: (res, variables, context) => {
        log('On Register', res)
        const access_token = res?.data?.access_token
        const refresh_token = res?.data?.refresh_token
        saveTokens(res?.data)
        setAuthToken(access_token)
        setAuth({
          isAuth: true,
          access_token: access_token,
          refresh_token: refresh_token
        })
        onRegisterSuccess(res)
      },
      onError: res => {
        onFailed(res?.response?.data)
      }
    }
  )
}

export const useLogin = () => {
  const [, setAuth] = useAtom(authAtom)
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])

  function login (data) {
    setLoading(true)
    publicAxios
      .post('/auth/login', data, null)
      .then(res => {
        const access_token = res?.data?.access_token
        const refresh_token = res?.data?.refresh_token
        saveTokens(res?.data)
        setAuthToken(access_token)
        setAuth({
          isAuth: true,
          access_token: access_token,
          refresh_token: refresh_token
        })
        setSuccess(true)
      })
      .catch(err => {
        if (err.response) {
          if (err.response.status === 401) {
            setAuthToken()
          }
          const data = err.response.data
          setErrors(data?.errors)
        } else if (err.request) {
          console.log('Request is made but response not received.')
        } else {
          console.log('Something went wrong')
        }
        setSuccess(false)
      })
      .finally(() => setLoading(false))
  }

  return {
    loading,
    success,
    errors,
    login
  }
}

export const useVerifyOTP = (onVerificationSuccess = () => {}) => {
  return useMutation(
    otp => secureAxios.post('/qe_auth/verify_otp', { otp }),
    // .then((res) => res.data),
    {
      onSuccess: (res, variables, context) => {
        log('Response received', res)
        queryClient.invalidateQueries(['user'])
        onVerificationSuccess(res)
      }
    }
  )
}

export const useSendOTP = (onOTPSent = () => {}) => {
  return useMutation(
    () => secureAxios.post('/qe_auth/verify_mobile'),
    // .then((res) => res.data),
    {
      onSuccess: (res, variables, context) => {
        log('Response received', res)
        onOTPSent(res)
      }
    }
  )
}

export const useLogout = () => {
  const [, setAuth] = useAtom(authAtom)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  function logout () {
    setLoading(true)
    secureAxios
      .post('/auth/logout', {
        refresh_token: restoreRefreshToken()
      })
      .then(res => {
        setSuccess(true)
      })
      .catch(err => {})
      .finally(() => {
        resetTokens()
        setAuthToken()
        setAuth({
          isAuth: false,
          access_token: null,
          refresh_token: null
        })
        setLoading(false)
        queryClient.invalidateQueries()
        history.push('/login')
        // queryClient.invalidateQueries();
      })
  }

  return {
    logout,
    loading,
    success
  }
}

export const useMe = () => {
  return useQuery(
    ['items', 'user'],
    () =>
      secureAxios.get(
        '/users/me?fields=*,matrimonial_profiles.*,block_profile.qd_matrimonial_profile_id.*,is_reactivated'
      ),
    // .then((res) => res?.data),
    {
      // retry: 0,
      // cacheTime: 0,
      //token valid time
      // staleTime: 1000 * 1,
    }
  )
}

export const useCurrentUser = () => {
  const { data, isSuccess } = useMe()

  const user = useMemo(() => {
    const local_data = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user'))
      : null

    let user = local_data?.data
    if (isSuccess) {
      user = data?.data
    }
    return user
  }, [isSuccess, data])

  return user
}

export const useChangePassword = (onSuccess = () => {}) => {
  return useMutation(
    data => secureAxios.patch('/users/me', data).then(res => res.data),
    {
      onSuccess: (data, variables, context) => {
        onSuccess(data)
      }
    }
  )
}
