import React, { useState, useEffect } from "react";
import { Button, Form, Input, Modal, TextArea } from "semantic-ui-react";
import { useUpdateItem, useUpdateItemNew } from "../_actions/mutations/items";

function SuchiInfoModal({ order, open, setOpen, onDone = () => {} }) {
    const [payload, setPayload] = useState({
        // name: "Shivam Agrawal",
        // address: "Sandiya road",
        // city: "Pipariya",
        // pincode: 461775,
        // mobile: "8871830420",
    });
    const [err, setErr] = useState("");
    const updateMutation = useUpdateItemNew("qd_order", () => {
        setOpen(false);
        onDone();
    });

    useEffect(() => {
        if (Object.keys(order).length > 0) {
            setPayload({
                name: order.name,
                address: order.address,
                city: order.city,
                pincode: order.pincode,
                mobile: order.mobile,
                alternate_mobile: order.alternate_mobile,
            });
        }
    }, [order]);

    const handleSubmit = () => {
        // setOpen(false);
        // setPayload({});
        // onDone(payload);

        updateMutation.mutate({
            id: order.id,
            data: payload,
        });
        // (payload);
    };

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
        >
            <Modal.Header>Buy Suchi Online</Modal.Header>
            <Modal.Content>
                <p>Note: please mention the delivery address.</p>
                <Modal.Description>
                    <Form>
                        <Form.Field required>
                            <label>Full Name</label>
                            <input
                                required
                                value={payload.name}
                                onChange={(e) => {
                                    setPayload({
                                        ...payload,
                                        name: e.target.value,
                                    });
                                }}
                            />
                        </Form.Field>
                        <Form.Field required>
                            <label>Address</label>
                            <TextArea
                                value={payload.address}
                                onChange={(e) => {
                                    setPayload({
                                        ...payload,
                                        address: e.target.value,
                                    });
                                }}
                            />
                        </Form.Field>
                        <Form.Group widths="equal">
                            <Form.Field required>
                                <label>City</label>
                                <input
                                    required
                                    value={payload.city}
                                    onChange={(e) => {
                                        setPayload({
                                            ...payload,
                                            city: e.target.value,
                                        });
                                    }}
                                />
                            </Form.Field>

                            <Form.Field required>
                                <label>Pincode</label>
                                <input
                                    required
                                    type="number"
                                    value={payload.pincode}
                                    maxLength={6}
                                    onChange={(e) => {
                                        setPayload({
                                            ...payload,
                                            pincode: e.target.value.toString(),
                                        });
                                    }}
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Field required>
                                <label>Mobile No</label>
                                <input
                                    required
                                    type="number"
                                    value={payload.mobile}
                                    max={10}
                                    onChange={(e) => {
                                        setPayload({
                                            ...payload,
                                            mobile: e.target.value.toString(),
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Alternate Mobile No</label>
                                <input
                                    type="number"
                                    maxLength={10}
                                    value={payload.alternate_mobile}
                                    onChange={(e) => {
                                        setPayload({
                                            ...payload,
                                            alternate_mobile: e.target.value,
                                        });
                                    }}
                                />
                            </Form.Field>
                        </Form.Group>
                    </Form>
                    <p
                        style={{
                            marginTop: "1em",

                            color: "red",
                        }}
                    >
                        {err}
                    </p>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button color="black" onClick={() => setOpen(false)}>
                    Nope
                </Button>
                <Button
                    type="submit"
                    content="Save and Continue"
                    labelPosition="right"
                    icon="checkmark"
                    onClick={handleSubmit}
                    positive
                    loading={updateMutation.isLoading}
                />
            </Modal.Actions>
        </Modal>
    );
}

export default SuchiInfoModal;
