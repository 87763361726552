import React from "react";
import styles from "./styles/Screen.module.css";

function Screen({ title, icon, children, actions = [], wrapper_styles = {} }) {
    return (
        <div className={styles.wrapper} style={wrapper_styles}>
            <div className={styles.header}>
                <div className={styles.title_wrapper}>
                    {icon}
                    <h3 className={styles.title}>{title}</h3>
                </div>
                {actions.length > 0 && (
                    <div className={styles.action_wrapper}>{actions}</div>
                )}
            </div>
            <div className={styles.main}>{children}</div>
        </div>
    );
}

export default Screen;
