import React from "react";
import styles from "./styles/AdminPanel.module.css";
import { Outlet } from "react-router-dom";
import AdminSidebar from "./AdminSidebar";
import { useCurrentRole } from "../_actions/roles";
import Screen from "../commons/Screen";
import AccessDenied from "../commons/AccessDenied";

function AdminPanel() {
  const { role_name } = useCurrentRole();

  if (role_name !== "admin") {
    return (
      <Screen>
        <AccessDenied />
      </Screen>
    );
  }

  return (
    <div className={styles.wrapper}>
  
      <AdminSidebar />

      <div className={styles.main}>
        <Outlet />
      </div>
    </div>
  );
}

export default AdminPanel;
