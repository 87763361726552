import React, { useState } from "react";
import { useDeleteFile, useUploadFile } from "../_actions/files";
import { getImageURL } from "../_helpers/files";
import { Button, Icon, Modal } from "semantic-ui-react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import ImageCropper from "./ImageCropper";

const log = {
    d: (val, key) => console.log(key, val),
};

const ImageField = ({ field, aspect, value, onChange, many = false }) => {
    const [open_cropper, setOpenCropper] = useState(false);
    const [local_file, setLocalFile] = useState(null);
    const [croppedImageUrl, setCroppedImageUrl] = useState("");

    const fileMutation = useUploadFile((data, variables, context) => {
        log.d(data, "Success Data");
        if (many) {
            let new_val = Array.isArray(value) ? value : [];
            new_val.push({
                directus_files_id: data.id,
            });
            onChange(new_val);
        } else {
            onChange(data.id);
        }
    });

    const fileMutationdelete = useDeleteFile((data, variables, context) => {
        log.d(data, "Success Data");
    });

    // Create a reference to the hidden file input element
    const hiddenFileInput = React.useRef(null);

    // Programatically click the hidden file input element
    // when the Button component is clicked
    const handleClick = (event) => {
        event.preventDefault();
        hiddenFileInput.current.click();
    };
    // Call a function (passed as a prop from the parent component)
    // to handle the user-selected file
    const onFile = (eve) => {
        console.log("Got Image");
        const file = eve.target.files[0];
        console.log(file);
        if (file) {
            if (typeof file === "object" && file !== null) {
                const formData = new FormData();
                //formData.append("title", "Some title")
                formData.append("file", file);

                // if (iconid !== "") {
                if (value) {
                    console.log(value);
                    fileMutationdelete.mutate(value);
                }
                fileMutation.mutate(formData);
            }
        }
    };

    const uploadImage = (ready_img) => {
        // if (ready_img) {
        // if (typeof ready_img === "object" && ready_img !== null) {
        const formData = new FormData();
        //formData.append("title", "Some title")
        formData.append("file", ready_img);

        // if (iconid !== "") {
        if (value) {
            console.log(value);
            fileMutationdelete.mutate(value);
        }
        fileMutation.mutate(formData);
        // }
        // }
    };

    const onLocalFileSelect = (eve) => {
        const file = eve.target.files[0];
        setLocalFile(file);
        setOpenCropper(true);
    };

    return (
        <>
            {!many && !value && (
                <div className="img_add_block" onClick={handleClick}>
                    {fileMutation.isLoading ? (
                        <Icon name="asterisk" loading />
                    ) : (
                        <Icon name="plus" />
                    )}
                    <span>
                        {fileMutation.isLoading
                            ? "Uploading photo..."
                            : "Select Photo"}
                    </span>
                </div>
            )}
            {!many && value && (
                <div className="preview_wrapper">
                    <img
                        src={getImageURL(value)}
                        className="preview_img"
                        alt="preview"
                    />
                    <div className="preview_overlay">
                        <Button inverted size="tiny" onClick={handleClick}>
                            Edit
                        </Button>
                        <Button
                            color="red"
                            size="tiny"
                            onClick={() => onChange(null)}
                        >
                            Delete
                        </Button>
                    </div>
                </div>
            )}

            <input
                type="file"
                ref={hiddenFileInput}
                onChange={onLocalFileSelect}
                style={{ display: "none" }}
            />

            {many && Array.isArray(value) && (
                <div className="img-group">
                    {value.map((item, index) => (
                        <div className="preview_wrapper" key={index}>
                            <img
                                src={getImageURL(item?.directus_files_id)}
                                className="preview_img"
                                alt="preview"
                            />
                            <div className="preview_overlay">
                                <Button
                                    color="red"
                                    size="tiny"
                                    onClick={() => {
                                        const new_val = [...value];
                                        new_val.splice(index, 1);
                                        onChange(new_val);
                                    }}
                                >
                                    Delete
                                </Button>
                            </div>
                        </div>
                    ))}
                    <div className="img_add_block" onClick={handleClick}>
                        {fileMutation.isLoading ? (
                            <Icon name="asterisk" loading />
                        ) : (
                            <Icon name="plus" />
                        )}
                        <span>
                            {fileMutation.isLoading
                                ? "Uploading photo..."
                                : "Add Photo"}
                        </span>
                    </div>
                </div>
            )}
            <ImageCropper
                open={open_cropper}
                setOpen={setOpenCropper}
                aspect={aspect}
                init_src={local_file}
                onFinished={(img) => {
                    uploadImage(img);
                    setOpenCropper(false);
                }}
            />
        </>
    );
};
export default ImageField;
