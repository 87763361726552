import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { Button, Icon, List } from "semantic-ui-react";
import { getImageURL, getProfilePhoto } from "../_helpers/files";
import { HIDE_NA } from "../constants";
import styles from "./styles/CandidateDetailView.module.css";

function CandidateDetailView({ data = {}, admin = false }) {
    console.log("Profile Data", data);

    const mobiles = useMemo(() => {
        const addrs = data.addresses;
        const all = addrs
            .filter((item) => !!item.mobile)
            .map((item) => item.mobile);
        return all || [];
    }, [data?.addresses]);

    // console.log("All moibles", mobiles);

    const height = useMemo(() => {
        if (data.height > 0) {
            const inches = data.height / 2.54;
            const feet = Math.floor(inches / 12);
            const remainingInches = Math.round(inches % 12);
            return {
                feet: feet,
                inch: remainingInches,
            };
        }
        return null;
    }, [data.height]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <img
                    src={getProfilePhoto(data.main_photo, data.gender)}
                    className={styles.main_photo}
                    alt="Main"
                />
                <div>
                    <h2>
                        {data.first_name} {data.middle_name} {data.last_name}
                        {"  "}
                        {data.petname && (
                            <span
                                style={{ color: "#aaa", fontStyle: "italic" }}
                            >
                                ({data.petname})
                            </span>
                        )}
                        {data.gender === "Male" && (
                            <Icon name="man" style={{ color: "#3d9ad4" }} />
                        )}
                        {data.gender === "Female" && (
                            <Icon name="woman" style={{ color: "pink" }} />
                        )}
                    </h2>
                    <List size="large">
                        {data.location && (
                            <List.Item
                                icon="map marker alternate"
                                content={data?.location?.name}
                            />
                        )}
                        {data.brithday && (
                            <List.Item
                                icon="calendar outline"
                                content={`${data.brithday}, ${data.birth_time}, ${data?.birth_place?.name}`}
                                style={{ paddingTop: "7px" }}
                            />
                        )}
                        {data.designation && (
                            <List.Item
                                icon="send"
                                content={`${data.designation}, ${
                                    data.organization_name || ""
                                }`}
                                style={{ paddingTop: "7px" }}
                            />
                        )}
                        <List.Item
                            icon="money bill alternate outline"
                            content={`${data.income} ${data.income_currency} per annum`}
                            style={{ paddingTop: "7px" }}
                        />
                        {data.education?.name && (
                            <List.Item
                                icon="graduation cap"
                                content={data.education.name}
                                style={{ paddingTop: "7px" }}
                            />
                        )}
                        {data.gotra?.name && (
                            <List.Item
                                icon="circle outline"
                                content={data.gotra.name}
                                style={{ paddingTop: "7px" }}
                            />
                        )}
                        {data.blood_group?.name && (
                            <List.Item
                                icon="tint"
                                content={data.blood_group.name}
                                style={{ paddingTop: "7px" }}
                            />
                        )}
                        {height && (
                            <List.Item
                                icon="arrows alternate vertical"
                                content={`${height.feet} feet ${height.inch} inch`}
                                style={{ paddingTop: "7px" }}
                            />
                        )}
                    </List>

                    <div className={styles.action_wrapper}>
                        {mobiles.length > 0 ? (
                            mobiles.map((mob) => (
                                <a href={`tel:${mob}`}>
                                    <Button positive icon labelPosition="left">
                                        Call
                                        <Icon name="call" />
                                    </Button>
                                </a>
                            ))
                        ) : (
                            <p>No mobile number provided</p>
                        )}
                        {data.email && (
                            <a href={`mailto:${data.email}`}>
                                <Button primary icon labelPosition="left">
                                    Mail
                                    <Icon name="mail" />
                                </Button>
                            </a>
                        )}
                    </div>
                    <SocialBar data={data} />
                </div>
            </div>
            <div className={styles.two_col_wrapper}>
                <div className={styles.section}>
                    <h4 className={styles.section_heading}>
                        Personal Information
                    </h4>
                    <TableView
                        data={{
                            Email: data.email,
                            Gender: data.gender,
                            Petname: data.petname,
                            "Birth Date": data.brithday,
                            "Birth Time": data.birth_time,
                            "Birth Place": data.new_birth_place
                                ? data.new_birth_place
                                : data.birth_place?.name,
                            Height: height
                                ? `${height.feet} feet ${height.inch} inch`
                                : "NA",
                            Weight: data.weight && `${data.weight} kg`,
                            Complexion: data.complexion?.name,
                            "Body Build": data.body_build?.name,
                            Gotra: data.gotra?.name,
                            "Blood Group": data.blood_group?.name,
                            "Native Place": data.new_native_place
                                ? data.new_native_place
                                : data.native_place?.name,
                            "Maternal Surname": data.maternal_surname,
                            "Marital Status": data.marital_status,
                            "Horoscope Matching": data.match_horoscope
                                ? "Required"
                                : "Not required",
                            "Work Location": data?.new_location
                                ? data?.new_location
                                : data?.location?.name,
                            "Is Handicapped": data.handicapped ? "Yes" : "No",
                            "Mentioned Disability": data?.mention_disability,
                            Country: data?.new_country
                                ? data?.new_country
                                : data?.country?.name,
                        }}
                    />
                </div>
                <div>
                    {data.main_video && (
                        <div className={styles.section}>
                            <video
                                controls
                                width="100%"
                                style={{
                                    borderRadius: "5px",
                                    overflow: "hidden",
                                    border: "1px solid #ccc",
                                }}
                            >
                                <source
                                    src={getImageURL(data.main_video)}
                                    type="video/mp4"
                                />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    )}
                    {data?.serial_number && (
                        <div className={styles.section}>
                            <h4 className={styles.section_heading}>
                                Suchi Number: {data.serial_number}
                            </h4>
                        </div>
                    )}
                    {data?.user?.first_name && (
                        <div className={styles.section}>
                            <h4 className={styles.section_heading}>
                                Login Information
                            </h4>
                            <TableView
                                data={{
                                    "First Name": data?.user?.first_name,
                                    "Last Name": data?.user?.last_name,
                                    Email: data?.user?.email,
                                }}
                            />
                        </div>
                    )}
                    <div className={styles.section}>
                        <h4 className={styles.section_heading}>
                            Family Overview
                        </h4>
                        <TableView
                            data={{
                                // "Job Type": data.job_type?.name,
                                // "Company Name": data.organization_name,
                                Brothers: `${
                                    data.brothers_married || "0"
                                } married, ${
                                    data.brothers_unmarried || "0"
                                } unmarried`,
                                Sisters: `${
                                    data.sisters_married || "0"
                                } married, ${
                                    data.sisters_unmarried || "0"
                                } unmarried`,
                            }}
                        />
                    </div>
                    <div className={styles.section}>
                        <h4 className={styles.section_heading}>
                            Education Overview
                        </h4>
                        <TableView
                            data={{
                                "Education Name": data.education?.name,
                                "Education Stream": data.education_stream,
                            }}
                        />
                    </div>
                    <div className={styles.section}>
                        <h4 className={styles.section_heading}>
                            Professional Overview
                        </h4>
                        <TableView
                            data={{
                                "Job Type": data.job_type?.name,
                                "Company Name": data.organization_name,
                                "Income (per annum)": data.income
                                    ? `${data.income} ${data.income_currency}`
                                    : null,
                                Occupation: data.occupation,
                                Designaton: data.designation,
                            }}
                        />
                    </div>
                </div>
            </div>

            <div className={styles.section}>
                <h4 className={styles.section_heading}>Partner Expectation</h4>
                <p>{data.partner_expectations}</p>
            </div>

            <div className={styles.address_wrapper}>
                {data.addresses.map((address, index) => (
                    <AddressView
                        title={`Address ${index + 1}`}
                        address={address}
                        name={address.name}
                    />
                ))}
            </div>
            <div
                className={styles.section}
                style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    gap: "10px",
                }}
            >
                {data.photos.map((item) => {
                    return (
                        <img
                            style={{
                                height: "200px",
                                width: "auto",
                                objectFit: "contain",
                            }}
                            src={getProfilePhoto(
                                item.directus_files_id,
                                data.gender
                            )}
                            alt="other"
                        />
                    );
                })}
            </div>
            <div className={styles.section}>
                {/* <Button>Approve</Button>
                <Button>Approve</Button> */}
                {/* <ProfileActions item={data} view={false} /> */}
            </div>
        </div>
    );
}

export default CandidateDetailView;

function TableView({ data, min_width }) {
    return (
        <table className={styles.table}>
            {Object.keys(data).map((key) => {
                const value = data[key];
                if (value) {
                    return (
                        <tr>
                            <td className={styles.key}>{key}</td>
                            <td>{data[key]}</td>
                        </tr>
                    );
                }
                if (!HIDE_NA) {
                    return (
                        <tr>
                            <td className={styles.key}>{key}</td>
                            <td>NA</td>
                        </tr>
                    );
                }
                return null;
            })}
        </table>
    );
}

export function AddressView({ title, address, name = null }) {
    return (
        <div className={styles.section}>
            <h4 className={styles.section_heading}>{title}</h4>
            <p>{name}</p>
            <p>{address?.address}</p>
            <p>
                {address?.city}, {address?.pincode}
            </p>
            <p>
                Mobile: {address?.mobile}, {address?.mobile_alternative}
            </p>
        </div>
    );
}

export function SocialBar({ data }) {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                paddingTop: "10px",
            }}
        >
            {data.social_facebook && (
                <Link to={data.social_facebook} target="_blank">
                    <Icon link name="facebook" size="large" color="blue" />
                </Link>
            )}
            {data.social_linkedin && (
                <Link to={data.social_facebook} target="_blank">
                    <Icon link name="linkedin" size="large" color="blue" />
                </Link>
            )}
            {data.social_twitter && (
                <Link to={data.social_facebook} target="_blank">
                    <Icon link name="twitter" size="large" color="skyblue" />
                </Link>
            )}
        </div>
    );
}
