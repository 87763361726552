import React, { useState } from "react";
import { Button, Icon, Input } from "semantic-ui-react";

function SearchSearch({ value, onChange, isMobile }) {
    return (
        <Input
            icon="search"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            placeholder="Search Candidates"
            fluid={isMobile}
            style={{
                width: isMobile ? "120px" : "auto",
            }}
        />
    );
}

export default SearchSearch;

export function GenderSwitch({ value, setValue }) {
    return (
        <Button.Group style={{ fontSize: "1.2em" }}>
            <Button
                icon
                color={value === "Male" ? "blue" : "default"}
                onClick={() => setValue("Male")}
            >
                <Icon name="mars" color="white" />
            </Button>
            <Button
                icon
                color={value === "Female" ? "pink" : "default"}
                onClick={() => setValue("Female")}
            >
                <Icon name="female homosexual" color="white" />
            </Button>
        </Button.Group>
    );
}
