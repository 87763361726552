import React, { useState } from "react";
import Screen from "../commons/Screen";
import AllProfiles from "../showcase/AllProfiles";
import DownloadProfilesButton from "./DownloadProfilesButton";
import { Input } from "semantic-ui-react";
import { useDebounce } from "@uidotdev/usehooks";
import ColdMailSendProfileBtn from "../mail/ColdMailSendProfileBtn";

function CandidatesReactivated() {
    const [search_query, setSearchQuery] = useState("");
    const debounced_search_query = useDebounce(search_query, 300);
    return (
        <Screen
            title="Reactivated Candidates"
            wrapper_styles={{
                marginTop: "-30px",
            }}
            actions={[
                <Input
                    icon="search"
                    value={search_query}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />,
                <ColdMailSendProfileBtn status="Active" />,
                <DownloadProfilesButton
                    //  status="Active"
                    status={false}
                    filter={"&filter[user][is_reactivated][_eq]=true&filter[user_created][date_created][_lt]=2024-07-01"}
                />,
            ]}
        >
            <AllProfiles
                // status="Active"
                status={false}
                filter={"&filter[user][is_reactivated][_eq]=true&filter[user_created][date_created][_lt]=2024-07-01"}
                search_query={
                    debounced_search_query?.length > 2
                        ? `search=${debounced_search_query}`
                        : ""
                }
                note="Candidates registered before 01-07-2024 and have reactivated the account."
            />
        </Screen>
    );
}

export default CandidatesReactivated;
