import { getTime } from "../_helpers/dt";
import styles from "./styles/MailCard.module.css";
import React, { useEffect, useState } from "react";

function MailCard({ data }) {
    const [stats, setStats] = useState({
        pending: 0,
        success: 0,
        failed: 0,
    });

    useEffect(() => {
        const temp = data.to_profile.reduce(
            (acc, obj) => ((acc[obj.status] = (acc[obj.status] || 0) + 1), acc),
            {}
        );
        const is_completed =
            data.to_profile.length === (temp.success || 0) + (temp.failed || 0);
        setStats({
            ...temp,
            total: data.to_profile.length,
            is_completed: is_completed,
        });
    }, [data.to_profile]);

    console.log("Stats", stats);
    return (
        <div className={styles.wrapper}>
            <div
                className={styles.stats}
                style={{
                    borderLeft: `5px solid ${
                        stats.is_completed ? "#34eb9b" : "#8ebbfa"
                    }`,
                }}
            >
                <table>
                    <tr className={styles.total}>
                        <td>Total</td>
                        <td>{stats.total || 0}</td>
                    </tr>
                    <tr className={styles.pending}>
                        <td style={{ paddingRight: "15px" }}>Pending</td>
                        <td>{stats.pending || 0}</td>
                    </tr>
                    <tr className={styles.success}>
                        <td>Success</td>
                        <td>{stats.success || 0}</td>
                    </tr>
                    <tr className={styles.failed}>
                        <td>Failed</td>
                        <td>{stats.failed || 0}</td>
                    </tr>
                </table>
            </div>
            <div className={styles.mail}>
                <div className={styles.subject}>{data.subject}</div>
                <div className={styles.body}>
                    <div className={styles.body_heading}>
                        {data.body_heading}
                    </div>
                    {/* <p>{data.body_content}</p> */}
                    <div
                        dangerouslySetInnerHTML={{ __html: data.body_content }}
                        className={styles.body_content}
                    />
                </div>
                <div className={styles.dt}>
                    Created {getTime(data.date_created)}
                </div>
            </div>
        </div>
    );
}

export default MailCard;
