import React, { useState } from "react";
import {
  Button,
  Dropdown,
  Form,
  Input,
  Modal,
  Select,
} from "semantic-ui-react";
import { useCreateItem } from "../_actions/mutations/items";
import { useItems } from "../_actions/items";
// import { ids } from "webpack";

function CreateLookupDialog({ trigger, type }) {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({
    lookup: type,
    name: "",
  });
  const mutation = useCreateItem("qdb_lookup", null, () => {
    setOpen(false);
    setData({});
  });
  const holiday_mutation = useCreateItem("qp_pm_holidays");

  const { data: get_data, isLoading, isSuccess } = useItems("qdb_lookup");

  const saveValue = () => {
    type === "Holidays"
      ? holiday_mutation.mutate(data, {
          onSuccess: () => {
            setOpen(false);
            setData({});
          },
        })
      : mutation.mutate({
          ...data,
          lookup: type,
        });
  };

  // (get_data);
  // (data);

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={trigger}
      size="tiny"
    >
      <Modal.Header>Add New {type}</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>Name</label>
            <input
              placeholder="Value"
              value={data.name}
              onChange={(e) => setData({ ...data, name: e.target.value })}
            />
            {type === "Holidays" ? (
              <>
                <Form.Field
                  control={Select}
                  selection
                  options={
                    isSuccess &&
                    get_data
                      ?.filter((e) => e.lookup === "State")
                      .map((ele, idx) => ({
                        key: ele.idx,
                        value: ele.name,
                        text: ele.name,
                      }))
                  }
                  label="State"
                  placeholder="State"
                  onChange={(e, { value }) => {
                    setData({ ...data, state: value });
                  }}
                  value={data.state}
                />

                <Form.Field
                  control={Input}
                  type="date"
                  label="Date"
                  placeholder="Date"
                  value={data.date}
                  onChange={(e) => {
                    setData({
                      ...data,
                      date: e.target.value,
                    });
                  }}
                />
              </>
            ) : (
              ""
            )}
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button
          content="Save"
          labelPosition="right"
          icon="checkmark"
          onClick={saveValue}
          positive
          loading={mutation.isLoading}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default CreateLookupDialog;
