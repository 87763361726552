import React, { useState } from "react";
import { Dropdown, Form } from "semantic-ui-react";
import { useItemsFilter } from "../_actions/items";
import { useCreateItem } from "../_actions/mutations/items";

function LookupDropdown({ type, label, value, onChange, width }) {
    const createMutation = useCreateItem(
        "qdb_lookup",
        null,
        () => {},
        (data) => {
            console.log("Item added", data);
            if (data?.id) {
                onChange(data.id);
            }
        }
    );
    const { isSuccess, isLoading, data } = useItemsFilter(
        "qdb_lookup",
        // `?filter[lookup][_eq]=${type}&`,
        `?limit=-1&filter={"_and":[{"_or":[{"user_created":{"_eq":"$CURRENT_USER"}},{"record_type":{"_eq":"standard"}}]},{"lookup":{"_eq":"${type}"}}]}`,
        !!type
    );

    const options = isSuccess
        ? data?.map((item) => ({
              key: item.id,
              value: item.id,
              text: item.name,
          }))
        : [];

    const addItem = (e, { value }) => {
        createMutation.mutate({
            lookup: type,
            name: value,
            record_type: "custom",
            // record_type: "standard",
        });
    };

    return (
        <Form.Field required {...(width ? { width: width } : {})}>
            <label>{label}</label>
            <Dropdown
                placeholder={label}
                fluid
                search
                selection
                options={options}
                loading={isLoading || createMutation.isLoading}
                value={value}
                onChange={(e, { value }) => onChange(value)}
                closeOnChange={true}
                allowAdditions
                onAddItem={addItem}
            />
        </Form.Field>
    );
}

export default LookupDropdown;
