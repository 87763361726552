import React from "react";
import "./styles/Profile.css";
import "semantic-ui-css/semantic.min.css";
import { Divider, Form, Segment, TextArea } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import Family from "./Family";
import { TimeShowAmPm, cmToFeetAndInches } from "../../utils";
import { getImageURL, getProfilePhoto } from "../../_helpers/files";

export default function Profile({ data }) {
    //   const navigate = useNavigate();
    // console.log(data);

    return (
        <>
            <div>
                <div className="parent">
                    <div className="child2">
                        <div className="child2_info">
                            <h2>
                                {data?.first_name}
                                {""} {data?.middle_name} {""}
                                {data?.last_name}
                            </h2>
                            <p className="aboutme_text">
                                Suchi Number: {data?.serial_number}
                            </p>
                            <p className="aboutme_text">
                                Partner Expectation :
                            </p>
                            <p className="partner_expectation">
                                {data?.partner_expectations}
                            </p>
                            <div className="user_personal_details">
                                <div>
                                    <p>
                                        {" "}
                                        <span> Date of Birth: </span>{" "}
                                        <span className="bold_div">
                                            {" "}
                                            {new Date(
                                                data?.brithday
                                            ).toDateString()}
                                        </span>{" "}
                                    </p>
                                    <p>
                                        <span>Birth Place :</span>{" "}
                                        <span className="bold_div">
                                            {" "}
                                            {data?.birth_place?.name
                                                ? data?.birth_place?.name
                                                : data?.new_birth_place}{" "}
                                        </span>
                                    </p>
                                    <p>
                                        {" "}
                                        <span> Height (In Feet): </span>{" "}
                                        <span className="bold_div">
                                            {Math.round(
                                                cmToFeetAndInches(data?.height)
                                                    .feet
                                            )}
                                            '
                                            {Math.round(
                                                cmToFeetAndInches(data?.height)
                                                    .inches
                                            )}
                                            "
                                        </span>{" "}
                                    </p>
                                    <p>
                                        <span>Body Type: </span>{" "}
                                        <span className="bold_div">
                                            {" "}
                                            {data?.body_build?.name}{" "}
                                        </span>
                                    </p>
                                    <p>
                                        {" "}
                                        <span> Complexion: </span>{" "}
                                        <span className="bold_div">
                                            {" "}
                                            {data?.complexion?.name}
                                        </span>
                                    </p>
                                    <p>
                                        {" "}
                                        <span> Horoscope Matching: </span>
                                        <span className="bold_div">
                                            {" "}
                                            {data?.match_horoscope
                                                ? "Yes"
                                                : "No"}{" "}
                                        </span>{" "}
                                    </p>
                                </div>

                                <div className="user_div">
                                    {" "}
                                    <p>
                                        <span>Marital Status : </span>{" "}
                                        <span className="bold_div">
                                            {" "}
                                            {data?.marital_status}{" "}
                                        </span>
                                    </p>
                                    <p>
                                        {" "}
                                        <span>Birth Time: </span>{" "}
                                        <span className="bold_div">
                                            {data?.birth_time
                                                ? TimeShowAmPm(data?.birth_time)
                                                : ""}
                                        </span>
                                    </p>
                                    <p>
                                        {" "}
                                        <span>Weight (Weight in KG):</span>{" "}
                                        <span className="bold_div">
                                            {" "}
                                            {data?.weight}{" "}
                                        </span>
                                    </p>
                                    <p>
                                        <span>Blood Group: </span>{" "}
                                        <span className="bold_div">
                                            {data?.blood_group?.name}{" "}
                                        </span>
                                    </p>
                                    <p>
                                        {" "}
                                        <span>Gotra:</span>{" "}
                                        <span className="bold_div">
                                            {" "}
                                            {data?.gotra?.name}{" "}
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <Divider />

                            {data?.main_video && (
                                <>
                                    <video
                                        controls
                                        width="100%"
                                        style={{
                                            borderRadius: "5px",
                                            overflow: "hidden",
                                            border: "1px solid #ccc",
                                        }}
                                    >
                                        <source
                                            src={getImageURL(
                                                data?.main_video?.id
                                            )}
                                            type="video/mp4"
                                        />
                                        Your browser does not support the video
                                        tag.
                                    </video>
                                    <Divider />
                                </>
                            )}
                            <p className="gallery_text">Gallery :</p>
                            <div className="gallery_div">
                                {data?.photos?.map((ele, idx) => {
                                    return (
                                        <img
                                            className="gallery_images"
                                            src={getProfilePhoto(
                                                ele?.directus_files_id,
                                                data?.gender
                                            )}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
