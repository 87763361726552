import React from "react";
import { Menu } from "semantic-ui-react";
import styles from "./styles/AdminPanel.module.css";
// import log from "cslog";
import { Link, useLocation } from "react-router-dom";

const log = {
    d: (value, name) => console.log(name, value),
};

function AdminSidebar({ permissions = { lookup_create: true } }) {
    const location = useLocation();

    return (
        <>
            <div className={styles.sidebar}>
                <Menu vertical fluid>
                    <Menu.Item header>General</Menu.Item>

                    <Link key="order_management" to="/admin/orders/ordered">
                        <Menu.Item
                            name="Orders"
                            active={location?.pathname.startsWith(
                                "/admin/orders/ordered"
                            )}
                        />
                    </Link>
                    <Link key="past_order_management" to="/admin/past_orders/ordered">
                        <Menu.Item
                            name="Past Orders"
                            active={location?.pathname.startsWith(
                                "/admin/past_orders/ordered"
                            )}
                        />
                    </Link>
                    <Link
                        key="search_subscriptions"
                        to="/admin/search_subscriptions"
                    >
                        <Menu.Item
                            name="Subscriptions"
                            active={location?.pathname.startsWith(
                                "/admin/search_subscriptions"
                            )}
                        />
                    </Link>

                    <Link key="all_users" to="/admin/users/all">
                        <Menu.Item
                            name="All Users"
                            active={location?.pathname === "/admin/users/all"}
                        />
                    </Link>
                    <Link key="mail_dashboard" to="/admin/mail_dashboard">
                        <Menu.Item
                            name="Bulk Mail Reports"
                            active={
                                location?.pathname === "/admin/mail_dashboard"
                            }
                        />
                    </Link>
                    <Link key="print_suchi" to="/print_suchi" target="_blank">
                        <Menu.Item
                            name="Print Suchi"
                            active={location?.pathname === "/print_suchi"}
                        />
                    </Link>
                    {true && (
                        <Link key="tv" to="/tv" target="_blank">
                            <Menu.Item
                                name="TV"
                                active={location?.pathname === "/tv"}
                            />
                        </Link>
                    )}
                    {true && (
                        <Link key="tv" to="/tv_pdf" target="_blank">
                            <Menu.Item
                                name="TV PDF"
                                active={location?.pathname === "/tv_pdf"}
                            />
                        </Link>
                    )}
                </Menu>
                <Menu vertical fluid>
                    <Menu.Item header>Candidates</Menu.Item>

                    <Link key="candidates_all" to="/admin/candidates/all">
                        <Menu.Item
                            name="All Candidates"
                            active={
                                location?.pathname === "/admin/candidates/all"
                            }
                        />
                    </Link>
                    <Link key="candidates_new" to="/admin/candidates/new">
                        <Menu.Item
                            name="New Candidates"
                            active={
                                location?.pathname === "/admin/candidates/new"
                            }
                        />
                    </Link>
                    <Link key="candidates_reactivated" to="/admin/candidates/reactivated">
                        <Menu.Item
                            name="Reactivated Candidates"
                            active={
                                location?.pathname === "/admin/candidates/reactivated"
                            }
                        />
                    </Link>
                    <Link key="candidates_deactivated" to="/admin/candidates/deactivated">
                        <Menu.Item
                            name="Deactivated Candidates"
                            active={
                                location?.pathname === "/admin/candidates/deactivated"
                            }
                        />
                    </Link>
                    <Link
                        key="pending_candidates"
                        to="/admin/candidates/pending"
                    >
                        <Menu.Item
                            name="Pending Candidates"
                            active={
                                location?.pathname ===
                                "/admin/candidates/pending"
                            }
                        />
                    </Link>
                    <Link key="active_candidates" to="/admin/candidates/active">
                        <Menu.Item
                            name="Active Candidates"
                            active={
                                location?.pathname ===
                                "/admin/candidates/active"
                            }
                        />
                    </Link>
                    <Link
                        key="married_candidates"
                        to="/admin/candidates/married"
                    >
                        <Menu.Item
                            name="Married Candidates"
                            active={
                                location?.pathname ===
                                "/admin/candidates/married"
                            }
                        />
                    </Link>
                    <Link
                        key="rejected_candidates"
                        to="/admin/candidates/rejected"
                    >
                        <Menu.Item
                            name="Rejected Candidates"
                            active={
                                location?.pathname ===
                                "/admin/candidates/rejected"
                            }
                        />
                    </Link>
                    <Link key="draft_candidates" to="/admin/candidates/draft">
                        <Menu.Item
                            name="Draft Candidates"
                            active={
                                location?.pathname === "/admin/candidates/draft"
                            }
                        />
                    </Link>

                </Menu>
            </div>
        </>
    );
}

export default AdminSidebar;
