import React from "react";
import Screen from "../commons/Screen";
import AllProfiles from "../showcase/AllProfiles";
import DownloadProfilesButton from "./DownloadProfilesButton";
import ColdMailSendProfileBtn from "../mail/ColdMailSendProfileBtn";

function CandidatesDraft() {
    return (
        <Screen
            title="Draft Candidates"
            wrapper_styles={{
                marginTop: "-30px",
            }}
            actions={[
                <ColdMailSendProfileBtn status="Draft" />,
                <DownloadProfilesButton status="Draft" />,
            ]}
        >
            <AllProfiles
                status="Draft"
                show_last_updated={true}
                call_btn={true}
            />
        </Screen>
    );
}

export default CandidatesDraft;
