import React from "react";
import { Dropdown, Form } from "semantic-ui-react";
import { useItemsFilter } from "../_actions/items";

function RelationDropdown({
    collection,
    pk = "id",
    display_field = "name",
    displayFunc,
    filter_string = "",
    many = false,
    label,
    value,
    onChange,
    all = true,
    placeholder,
    fluid = true,
}) {
    const { isSuccess, isLoading, data } = useItemsFilter(
        collection,
        filter_string,
        !!collection
    );
    // console.log(filter_string)
    const options = isSuccess
        ? data.map((item) => ({
              key: item[pk],
              value: item[pk],
              text: displayFunc?.(item) || item[display_field],
          }))
        : [];

    //   useEffect(() => {
    //     if (options) {
    //       options.push({ key: "all", value: "all", text: "All" });
    //     }
    //   }, [options]);

    // log.d(options, "Options");

    return (
        <Form.Field>
            {label && <label>{label}</label>}
            <Dropdown
                placeholder={placeholder || label || "Select"}
                fluid={fluid}
                search
                selection
                options={[
                    ...options,
                    ...[
                        all
                            ? {
                                  key: "all",
                                  value: "all",
                                  text: `${
                                      collection === "qp_pm_projects"
                                          ? "All Projects"
                                          : "All Users"
                                  }`,
                              }
                            : {},
                    ],
                ]}
                loading={isLoading}
                value={value}
                onChange={(e, { value }) => onChange(value)}
                closeOnChange={!many}
                multiple={many}
                // defaultValue={{ key: "all", value: "all", text: "All" }}
            />
        </Form.Field>
    );
}

export default RelationDropdown;
