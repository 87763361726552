import React, { useEffect } from "react";
import { Navigate, Route, Outlet, useLocation } from "react-router-dom";
import { useLogout, useMe } from "../_actions/auth";
import { history } from "../_helpers/history";
// import AppSplash from "./AppSplash";

function PrivateRoute() {
    const { isLoading, isSuccess, data, refetch } = useMe();
    const isLoggedIn = isSuccess && data?.data !== undefined;
    const is_mobile_verified = isSuccess && data?.data?.email_verified;
    const { logout } = useLogout();

    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        if (isSuccess) {
            if (Object.keys(data?.data).length <= 1) {
                console.log("Logging out..");
                logout();
            }
        }
    }, [isSuccess]);

    console.log("HomeMe", data);

    if (isLoading) {
        // return <AppSplash />;
        return (
            <div
                style={{
                    width: "100%",
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <h2>Loading...</h2>
            </div>
        );
    }

    if (!isLoggedIn) {
        return <Navigate to="/login" state={{ from: history.location }} />;
    }

    // if (isSuccess && Object.keys(data?.data).length <= 1) {
    //     return <Navigate to="/login" state={{ from: history.location }} />;
    // }

    if (!is_mobile_verified) {
        return <Navigate to="/verify_otp" state={{ from: history.location }} />;
    }
    return <Outlet />;
}

export default PrivateRoute;
