import styles from "./styles/PrintIndex.module.css";
import React, { useEffect, useState } from "react";
import ProfileCard from "./ProfileCard";
import Page from "./Page";
import { useItemsFilterNew } from "../_actions/items";
import PrintInput from "./PrintInput";
import PageGrid from "./PageGrid";
import IndexPages from "./IndexPages";
import PageGridTV from "./PageGridTV";

const TOTAL_PAGES = 1020; //750
// =============================

const COUNT = TOTAL_PAGES;
const PER_PAGE = 1;
const OFFSET = 0;
const FILTER_MS = "Unmarried";
const FILTER_GENDER = "Male";

const PAGE_NO_OFFSET = 0;
const SERIAL_NO_OFFSET = 0;

const PAGE_HEADING = "UNMARRIED MALES";

function PrintTV() {
    const [meta, setMeta] = useState({
        total_records: 1,
        total_pages: 1,
    });
    const { isLoading, isSuccess, data } = useItemsFilterNew(
        "qd_matrimonial_profile",
        // `?filter[status][_eq]=Active&filter[marital_status][_eq]=${FILTER_MS}&filter[gender][_eq]=${FILTER_GENDER}&limit=${COUNT}&offset=${OFFSET}&meta=*&sort=serial_number&fields=*,native_place.name,birth_place.name,location.name,education.name,addresses.*,addresses.country.name,gotra.name,blood_group.name,complexion.name`,
        `?filter[status][_eq]=Active&limit=${COUNT}&offset=${OFFSET}&meta=*&sort=serial_number&fields=*,native_place.name,birth_place.name,location.name,education.name,addresses.*,addresses.country.name,gotra.name,blood_group.name,complexion.name`,
        true,
        true
    );

    useEffect(() => {
        if (isSuccess) {
            const total =
                COUNT < data?.meta?.filter_count
                    ? COUNT
                    : data?.meta?.filter_count;
            // const total = COUNT;
            setMeta({
                total_records: total,
                total_pages: Math.ceil(total / PER_PAGE),
            });
        }
    }, [isSuccess, data]);

    return (
        <div
        // className={styles.bg}
        >
            <PrintInput
                heading="Vikas Mandal Mulund TV PDF Print"
                btn_label="Print TV PDF"
            />
            {true &&
                isSuccess &&
                Array.from({ length: TOTAL_PAGES }, (_, i) => i + 1).map(
                    (page_no) => {
                        return (
                            <PageGridTV
                                page_no={page_no}
                                data={data?.data?.find(
                                    (item) => item.serial_number === page_no
                                )}
                            />
                        );
                    }
                )}
        </div>
    );
}

export default PrintTV;
