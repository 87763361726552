import React from "react";
import { Icon } from "semantic-ui-react";

function AccessDenied({
    title = "Access Denied",
    content = "You don't have permission to access this page",
    action,
}) {
    return (
        <div
            style={{
                width: "100%",
                height: "250px",
                border: "1px dashed #bbb",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                color: "#ccc",
                fontSize: "1.2em",
            }}
        >
            <Icon name="ban" style={{ fontSize: "1.5em" }} />
            <p style={{ margin: 0 }}>{title}</p>
            <p style={{ fontSize: ".9em", marginTop: "-7px" }}>{content}</p>
            {action}
        </div>
    );
}

export default AccessDenied;
