import React, { useMemo } from "react";
import Navbar from "./commons/Navbar";
import { Outlet } from "react-router-dom";
import styles from "./styles/Home.module.css";
import { useCurrentUser, useMe } from "./_actions/auth";
import { useMediaQuery } from "@uidotdev/usehooks";

function Home() {
    const isMobile = useMediaQuery("only screen and (max-width : 768px)");
    const user = useCurrentUser();

    return (
        <main className={styles.wrapper}>
            <Navbar
                user_name={`${user?.first_name} ${user?.last_name}`}
                isMobile={isMobile}
            />
            <div className={styles.main}>
                    <Outlet />
            </div>
        </main>
    );
}

export default Home;
