import { ADMIN_MAILS, ENABLE_MAIL } from "../constants";
import { useCreateItem } from "./mutations/items";

export function useSendMail() {
    const createMutation = useCreateItem("qdb_mail");

    const sendMail = (to_mail, subject, body_heading, body_content) => {
        if (ENABLE_MAIL) {
            createMutation.mutate({
                to_email: to_mail,
                subject,
                body_heading,
                body_content,
            });
        }
    };

    const sendMailToAdmin = (subject, body_heading, body_content) => {
        if (ENABLE_MAIL) {
            createMutation.mutate({
                to_email: ADMIN_MAILS,
                subject,
                body_heading,
                body_content,
            });
        }
    };

    return {
        sendMail,
        sendMailToAdmin,
        isSuccess: createMutation.isSuccess,
        isLoading: createMutation.isLoading,
    };
}
