import React from "react";
import Screen from "../commons/Screen";
import AllProfiles from "../showcase/AllProfiles";
import { useItemsFilter } from "../_actions/items";
import ProfileCards from "../showcase/ProfileCards";
import { useUpdateItem } from "../_actions/mutations/items";

function BookOrdered() {
    const { isLoading, isSuccess, data } = useItemsFilter(
        "directus_users",
        "?fields=id,qd_payments.*,matrimonial_profiles.*,delivery_address.*&filter[suchi_book_order][_eq]=ordered&limit=-1"
    );

    console.log("books ordered", data);

    return (
        <Screen
            // title="Pending Suchi Book Dispatch"
            wrapper_styles={{
                // marginTop: "-30px",
                padding: 0,
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                }}
            >
                {data?.map((ele, index) => {
                    return (
                        <ProfileCards
                            key={index}
                            item={ele?.matrimonial_profiles[0]}
                            address={
                                ele?.delivery_address
                                    ? ele?.delivery_address[0]
                                    : {}
                            }
                            // orderOn={ele.payment}
                            user_id={ele?.id}
                            mode={"pending"}
                        />
                    );
                })}
            </div>
        </Screen>
    );
}

export default BookOrdered;
