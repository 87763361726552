import React from "react";
import "./styles/Profile.css";

export default function Tabs({ active, setActive }) {
  return (
    <>
      <div className="btns2_div">
        <button
          className={
            active === "general" ? "child2_btns active-btn" : "child2_btns"
          }
          onClick={() => {
            setActive("general");
          }}
        >
          General
        </button>
        <button
          className={
            active === "family" ? "child2_btns active-btn " : "child2_btns"
          }
          onClick={() => {
            setActive("family");
          }}
        >
          Family
        </button>
        <button
          onClick={() => {
            setActive("profession");
          }}
          className={
            active === "profession" ? "child2_btns active-btn" : "child2_btns"
          }
        >
          Professional
        </button>
      </div>
    </>
  );
}
