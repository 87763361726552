import React, { useState, useMemo, useEffect } from "react";
import { Dropdown, Input } from "semantic-ui-react";

function HeightField({ value, setValue }) {
  // const data = useMemo(() => {
  //     const inches = value / 2.54;
  //     const feet = Math.floor(inches / 12);
  //     const remainingInches = Math.round(inches % 12);
  //     return {
  //         feet: feet,
  //         inch: remainingInches,
  //     };
  // }, [value]);

  // const handleChange = (name, val) => {
  //     if (name === "feet") {
  //         const totalInches = val * 12 + data.inch;
  //         const cm = totalInches * 2.54;
  //         setValue(cm);
  //     } else if (name === "inch") {
  //         const totalInches = data.feet * 12 + val;
  //         const cm = totalInches * 2.54;
  //         setValue(cm);
  //     }
  // };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Dropdown
        value={value.feet}
        onChange={(e, { value: new_value }) =>
          setValue({ ...value, feet: new_value })
        }
        options={[3, 4, 5, 6, 7, 8].map((item) => ({
          key: item,
          text: `${item} feet`,
          value: item,
        }))}
        selection
        fluid
      />
      <Dropdown
        value={value.inch}
        onChange={(e, { value: new_value }) =>
          setValue({ ...value, inch: new_value })
        }
        options={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => ({
          key: item,
          text: `${item} inches`,
          value: item,
        }))}
        selection
        fluid
      />
      {/* <Input
                name="height_feet"
                type="number"
                value={value.feet}
                onChange={(e) =>
                    setValue({ ...value, feet: parseInt(e.target.value) })
                }
                label={{ basic: false, content: "feet" }}
                labelPosition="right"
            />
            <Input
                name="height_inch"
                type="number"
                value={value.inch}
                onChange={(e) =>
                    setValue({ ...value, inch: parseInt(e.target.value) })
                }
                label={{ basic: false, content: "inch" }}
                labelPosition="right"
            /> */}
    </div>
  );
}

export default HeightField;
