import React from "react";
import { useCurrentUser } from "../_actions/auth";
import PaymentCard from "../home/payment_page/PaymentCard";
import BuySearchSubscription from "../payments/BuySearchSubscription";

export default function MyMemberShipProfile() {
    const user_me = useCurrentUser();

    // console.log(user_me);
    return (
        <>
            <h3>My Membership</h3>
            <BuySearchSubscription
                subscription_plan_id="online_search"
                show_only_buy_card={true}
            />
            <div
                style={{
                    display: "flex",
                    flexWrap: "wrap",
                }}
            >
                {new Date(user_me?.membership_valid_till) >= new Date() && (
                    <PaymentCard
                        title={"Subscription For Matching Profile Search"}
                        note={"This subscription is valid till May 2024"}
                        price={600}
                        setPrice={() => {}}
                        payload={""}
                        setPayload={() => {}}
                        pur={"profile_search"}
                        mode="activated"
                    />
                )}

                {(user_me?.suchi_book_order == "ordered" ||
                    user_me?.suchi_book_order == "dispatched") && (
                    <PaymentCard
                        title={"Book Suchi online"}
                        note={
                            "It will be delivered to your address provider while registration"
                        }
                        price={600}
                        setPrice={() => {}}
                        payload={""}
                        setPayload={() => {}}
                        pur={"profile_search"}
                        mode="activated"
                        status={user_me?.suchi_book_order}
                    />
                )}
            </div>
        </>
    );
}
