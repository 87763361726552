import React, { Fragment } from 'react'
import { useCurrentUser } from './_actions/auth'
import { useItemsFilter } from './_actions/items'
import MessageBox from './commons/MessageBox'
import Screen from './commons/Screen'
import BuySearchSubscription from './payments/BuySearchSubscription'
import BuySuchiAll from './payments/BuySuchiAll'
import ProfileStatusBox from './settings/ProfileStatusBox'
import MarkMarriedMessage from './auth/MarkMarriedMessage'
import { Button, Icon } from 'semantic-ui-react'
import ReactivationBox from './settings/ReactivationBox'
import UpdateEducationModal from './settings/UpdateEducationModal'


function HomePage() {
    const { isSuccess: successSettings, data: settings } = useItemsFilter(
        'admin_setting',
        '?fields=*'
    )
    const user_me = useCurrentUser()

    console.log(user_me)
    localStorage.setItem('userid', user_me?.id)

    console.log('Settings', settings)

    console.log('Me', user_me)
    const is_profile_created = user_me?.matrimonial_profiles?.length > 0
    console.log('Profile created', is_profile_created)

    const profile = user_me?.matrimonial_profiles?.[0]

    const profile_status = user_me?.matrimonial_profiles[0]?.status
    let rejection_reason = user_me?.matrimonial_profiles[0]?.rejection_reason

    // const gender = user_me?.matrimonial_profiles?.[0]?.gender;
    const gender = user_me?.matrimonial_profiles?.[0]?.gender || 'Male'

    let enable_search =
        (successSettings && settings?.enable_search_subscription) ||
        user_me?.is_tester
    enable_search = false

    const is_reactivated = user_me?.is_reactivated

    console.log("Profile on Homepage", profile)

    // const educations = profile?.new_education;

    return (
        <>
            <Screen title='Home'>
                {!is_reactivated && <ReactivationBox profile={profile} />}
                {is_profile_created && <UpdateEducationModal profile={profile} />}
                {is_reactivated && (
                    <Fragment>
                        {/* {profile?.status === "Active" && profile?.serial_number && ( */}
                        {profile?.status === 'Active' && profile?.serial_number && (
                            <MessageBox
                                type='success'
                                title={`Your suchi number is ${profile?.serial_number}`}
                            // allow_close={true}
                            />
                        )}
                        <ProfileStatusBox
                            status={profile_status}
                            is_profile_created={is_profile_created}
                            rejection_reason={rejection_reason}
                        />
                        <MarkMarriedMessage />
                        {enable_search && gender && (
                            <BuySearchSubscription
                                subscription_plan_id='online_search'
                                gender={gender === 'Male' ? 'Female' : 'Male'}
                                settings={settings}
                                is_tester={user_me?.is_tester}
                            />
                        )}
                        {/* <DonationCard /> */}

                        {enable_search && !gender && (
                            <MessageBox
                                type='warn'
                                title='Complete your candidate profile to enable search'
                                content='Search functionality is only available for candidates whose profile is created.'
                                allow_close={true}
                            />
                        )}
                        {!enable_search && (
                            <MessageBox
                                type='info'
                                title='Profile search functionality is currently disabled till further notice.'
                            />
                        )}
                        {true && <BuySuchiAll />}
                    </Fragment>
                )}
            </Screen>
        </>
    )
}

export default HomePage
