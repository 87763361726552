import { useMutation } from "react-query";
import { secureAxios } from "../_helpers/auth";

export const useUploadFile = (onSuccess) => {
    return useMutation(
        (formData) => {
            return secureAxios.post(`/files`, formData).then((res) => res.data);
        },
        {
            onSuccess: onSuccess,
        }
    );
};

export const useDeleteFile = (onSuccess) => {
    return useMutation(
        (id) => {
            return secureAxios.delete(`/files/${id}`).then((res) => res.data);
        },
        {
            onSuccess: (data, variables, context) => {
                onSuccess(data);
            },
        }
    );
};
