import React, { useEffect, useState } from "react";
import { useItemsFilter, useItemsFilterNew } from "../_actions/items";
import { Button, Icon, Pagination, Tab, Table } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { getTime } from "../_helpers/dt";

function UserList({ filter, search_query = "", per_page = 10 }) {
    const [total, setTotal] = useState(1);
    const [num_page, setNumPage] = useState(1);
    const [page_no, setPageNo] = useState(1);
    const { isLoading, isSuccess, data } = useItemsFilterNew(
        "directus_users",
        `?limit=${per_page}&offset=${
            per_page * (page_no - 1)
        }&meta=filter_count&${filter}&sort=-date_created&${search_query}`,
        true,
        true
    );

    useEffect(() => {
        setPageNo(1);
    }, [filter, search_query]);

    useEffect(() => {
        if (isSuccess) {
            const total_records = data?.meta?.filter_count;
            if (total_records > 0) {
                setTotal(total_records);
                setNumPage(Math.ceil(total_records / per_page));
            }
            console.log("User Response", data);
        }
    }, [isSuccess, data]);

    return (
        <div>
            <p>Total records: {total}</p>
            <Table celled>
                <Table.Header>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Email</Table.HeaderCell>
                    <Table.HeaderCell>Mobile</Table.HeaderCell>
                    <Table.HeaderCell>Date Created</Table.HeaderCell>
                    <Table.HeaderCell>Actions</Table.HeaderCell>
                </Table.Header>
                <Table.Body>
                    {isSuccess &&
                        data?.data?.map((user) => {
                            return (
                                <Table.Row>
                                    <Table.Cell>
                                        {user.first_name} {user.last_name}
                                    </Table.Cell>
                                    <Table.Cell>{user.email}</Table.Cell>
                                    <Table.Cell>{user.mobile}</Table.Cell>
                                    <Table.Cell>
                                        {getTime(user.date_created)}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {user?.matrimonial_profiles?.length >
                                            0 && (
                                            <Link
                                                to={`/admin/candidates/view/${user.matrimonial_profiles?.[0]}`}
                                            >
                                                <Button icon>
                                                    <Icon name="eye" />
                                                </Button>
                                            </Link>
                                        )}
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                </Table.Body>
            </Table>
            <Pagination
                activePage={page_no}
                totalPages={num_page}
                onPageChange={(e, { activePage }) => setPageNo(activePage)}
            />
        </div>
    );
}

export default UserList;
