import React from "react";
import styles from "./styles/AllProfiles.module.css";
import { getProfilePhoto } from "../_helpers/files";
import { Button, Icon } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { useUpdateItem } from "../_actions/mutations/items";
import { AddressView } from "../profile/CandidateDetailView";
export default function ProfileCards({
    item,
    user_id,
    mode,
    address = null,
    name = null,
}) {
    const navigate = useNavigate("/");

    const updateStatus = useUpdateItem("directus_users");

    // console.log(item);

    const dispatch = () => {
        updateStatus.mutate({
            id: user_id,
            data: { suchi_book_order: "dispatched" },
        });
    };

    const markDelivered = () => {
        updateStatus.mutate({
            id: user_id,
            data: { suchi_book_order: "delivered" },
        });
    };
    return (
        <>
            <div className={styles.item_wrapper}>
                <div className={styles.item}>
                    <img
                        src={getProfilePhoto(item?.main_photo, item?.gender)}
                        alt="profile"
                    />
                    <div>
                        <h4>
                            {item?.first_name} {item?.last_name}
                        </h4>
                        <p>{item?.native_place?.name}</p>
                    </div>
                </div>
                <AddressView
                    title={"Delivery Address"}
                    address={address}
                    name={name}
                />
                <div
                    style={{
                        backgroundColor: "#DBDBDB",
                        display: "flex",
                        padding: "0.5em",
                    }}
                >
                    <Button
                        primary
                        onClick={() => {
                            navigate(`/profile/${item.id}`);
                        }}
                        icon
                        labelPosition="left"
                    >
                        <Icon name="eye" />
                        View
                    </Button>
                    {mode === "pending" && (
                        <Button
                            color="green"
                            loading={updateStatus.isLoading}
                            onClick={dispatch}
                            icon
                            labelPosition="left"
                        >
                            <Icon name="truck" />
                            Dispatch
                        </Button>
                    )}
                    {mode === "dispatched" && (
                        <Button
                            positive
                            onClick={markDelivered}
                            loading={updateStatus.isLoading}
                            icon
                            labelPosition="left"
                        >
                            <Icon name="checkmark" />
                            Mark delivered
                        </Button>
                    )}
                </div>
            </div>
        </>
    );
}
