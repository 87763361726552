import React, { useState } from "react";
import { Button, Form, Icon, Input, Modal, TextArea } from "semantic-ui-react";
import LookupDropdown from "../forms/LookupDropdown";

function AddressPicker({ value, setValue }) {
    const [open, setOpen] = useState(false);
    // const [value, setValue] = useState([]);
    const [address, setAddress] = useState({});

    const addAddress = () => {
        setValue([...value, address]);
        setAddress({});
    };

    return (
        <Form.Field required>
            <label>
                Addresses{" "}
                <label
                    style={{
                        fontWeight: "100",
                        fontSize: "13px",
                        marginLeft: "3px",
                    }}
                >
                    (If you want to edit address please delete exiting address
                    and add new)
                </label>
            </label>
            <Button onClick={() => setOpen(true)}>Add New Address</Button>
            <Modal
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                size="tiny"
            >
                <Modal.Header>Add New Address</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Field>
                            <label>Name</label>
                            <Input
                                value={address.name}
                                onChange={(e) =>
                                    setAddress({
                                        ...address,
                                        name: e.target.value,
                                    })
                                }
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Address</label>
                            <TextArea
                                value={address.address}
                                onChange={(e) =>
                                    setAddress({
                                        ...address,
                                        address: e.target.value,
                                    })
                                }
                            />
                        </Form.Field>

                        <Form.Field>
                            <label>City</label>
                            <Input
                                value={address.city}
                                onChange={(e) =>
                                    setAddress({
                                        ...address,
                                        city: e.target.value,
                                    })
                                }
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Pincode</label>
                            <Input
                                type="number"
                                value={address.pincode}
                                onChange={(e) =>
                                    setAddress({
                                        ...address,
                                        pincode: e.target.value,
                                    })
                                }
                            />
                        </Form.Field>
                        <LookupDropdown
                            type="Country"
                            label="Country"
                            value={address.country}
                            onChange={(newVal) =>
                                setAddress({ ...address, country: newVal })
                            }
                        />
                        {/* <Form.Field>
                            <label>Country</label>
                            <Input
                                type="text"
                                value={address.country}
                                onChange={(e) =>
                                    setAddress({
                                        ...address,
                                        country: e.target.value,
                                    })
                                }
                            />
                        </Form.Field> */}
                        <Form.Field>
                            <label>Mobile Number</label>
                            <Input
                                type="number"
                                value={address.mobile}
                                onChange={(e) =>
                                    setAddress({
                                        ...address,
                                        mobile: e.target.value,
                                    })
                                }
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Mobile Number Alternative</label>
                            <Input
                                type="number"
                                value={address.mobile_alternative}
                                onChange={(e) =>
                                    setAddress({
                                        ...address,
                                        mobile_alternative: e.target.value,
                                    })
                                }
                            />
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        positive
                        icon
                        labelPosition="right"
                        onClick={() => {
                            addAddress();
                            setOpen(false);
                        }}
                    >
                        Add
                        <Icon name="arrow right" />
                    </Button>
                </Modal.Actions>
            </Modal>
            <div
                style={{
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    flexWrap: "wrap",
                }}
            >
                {value.map((adr, index) => (
                    <div
                        style={{
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                            padding: "15px",
                            color: "#777",
                        }}
                    >
                        <div>{adr.name}</div>
                        <div>{adr.address}</div>
                        <div>
                            {adr.city}, {adr.pincode}
                        </div>
                        <div>
                            Mobile: {adr.mobile}, {adr.mobile_alternative}
                        </div>
                        <Button
                            basic
                            fluid
                            onClick={() => {
                                const new_val = [...value];
                                new_val.splice(index, 1);
                                setValue(new_val);
                            }}
                            style={{ marginTop: "10px" }}
                        >
                            Delete
                        </Button>
                    </div>
                ))}
            </div>
        </Form.Field>
    );
}

export default AddressPicker;
