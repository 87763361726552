import React, { Fragment, useEffect, useState } from "react";
import {
    Button,
    Checkbox,
    Dropdown,
    Form,
    Icon,
    Input,
    Modal,
} from "semantic-ui-react";
import { produce } from "immer";
import LookupDropdown from "../forms/LookupDropdown";

function SearchFilters({ value, onChange, gender, search_term }) {
    const [open, setOpen] = useState(false);
    const [filters, setFilters] = useState({});

    const applyFilters = () => {
        const temp = [
            { gender: { _eq: gender } },
            { status: { _eq: "Active" } },
        ];
        if (filters.marital_status) {
            temp.push({ marital_status: { _eq: filters.marital_status } });
        }
        if (filters.from_dob) {
            temp.push({ brithday: { _gte: filters.from_dob } });
        }
        if (filters.to_dob) {
            temp.push({ brithday: { _lte: filters.to_dob } });
        }
        if (filters.from_height) {
            temp.push({ height: { _gte: filters.from_height } });
        }
        if (filters.to_height) {
            temp.push({ height: { _lte: filters.to_height } });
        }
        if (filters.work_country && filters.work_country.length > 0) {
            let temp_country = filters.work_country;
            if (
                [
                    "usa",
                    "united states",
                    "united state",
                    "united stat",
                    "united",
                    "states",
                    "state",
                    "us",
                    "america",
                    "americ",
                ].includes(filters.work_country?.toLowerCase())
            ) {
                temp_country = "USA";
            }
            temp.push({ new_country: { _icontains: temp_country } });
        }
        if (filters.work_location && filters.work_location.length > 0) {
            temp.push({ new_location: { _contains: filters.work_location } });
        }
        if (filters.education) {
            temp.push({ education: { _eq: filters.education } });
        }
        if (filters.specialization && filters.specialization.length > 0) {
            temp.push({
                education_stream: { _contains: filters.specialization },
            });
        }
        if (filters.job_type) {
            temp.push({ job_type: { _eq: filters.job_type } });
        }
        if (filters.match_horoscope !== undefined) {
            temp.push({ match_horoscope: { _eq: filters.match_horoscope } });
        }
        if (filters.is_handicapped !== undefined) {
            temp.push({ is_handicapped: { _eq: filters.is_handicapped } });
        }

        // =============================
        if (search_term && search_term?.length > 0) {
            onChange(
                JSON.stringify({
                    _and: [
                        { _and: temp },
                        {
                            _or: [
                                { first_name: { _icontains: search_term } },
                                { middle_name: { _icontains: search_term } },
                                { last_name: { _icontains: search_term } },
                            ],
                        },
                    ],
                })
            );
        } else {
            onChange(JSON.stringify({ _and: temp }));
        }

        setOpen(false);
    };

    useEffect(() => {
        applyFilters();
    }, [gender, search_term]);

    return (
        <Modal
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            trigger={
                <Button icon labelPosition="right">
                    Filter <Icon name="filter" />
                </Button>
            }
        >
            <Modal.Header>Filter Candidates</Modal.Header>
            <Modal.Content scrolling>
                <Form>
                    <Form.Group>
                        <Form.Field>
                            <label>Marital Status</label>
                            <Dropdown
                                placeholder="Select"
                                selection
                                options={[
                                    {
                                        key: "unmarried",
                                        text: "Unmarried",
                                        value: "Unmarried",
                                    },
                                    {
                                        key: "divorced",
                                        text: "Divorced",
                                        value: "Divorced",
                                    },
                                ]}
                                value={filters.marital_status}
                                onChange={(e, { value }) =>
                                    setFilters({
                                        ...filters,
                                        marital_status: value,
                                    })
                                }
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>.</label>
                            <Button
                                icon
                                onClick={() => {
                                    setFilters(
                                        produce((df) => {
                                            // delete df.marital_status;
                                            df.marital_status = null;
                                        })
                                    );
                                }}
                            >
                                <Icon name="times" />
                            </Button>
                        </Form.Field>
                    </Form.Group>
                    <Form.Group>
                        <Form.Field>
                            <label>From DoB</label>
                            <Input
                                type="date"
                                value={filters.from_dob}
                                onChange={(e) =>
                                    setFilters({
                                        ...filters,
                                        from_dob: e.target.value,
                                    })
                                }
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>To DoB</label>
                            <Input
                                type="date"
                                value={filters.to_dob}
                                onChange={(e) =>
                                    setFilters({
                                        ...filters,
                                        to_dob: e.target.value,
                                    })
                                }
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>.</label>
                            <Button
                                icon
                                onClick={() => {
                                    setFilters(
                                        produce((df) => {
                                            df.from_dob = undefined;
                                            df.to_dob = undefined;
                                        })
                                    );
                                }}
                            >
                                <Icon name="times" />
                            </Button>
                        </Form.Field>
                    </Form.Group>
                    <Form.Group>
                        <Form.Field>
                            <label>From Height</label>
                            <Dropdown
                                selection
                                options={getHeightOptions()}
                                value={filters.from_height}
                                onChange={(e, { value }) =>
                                    setFilters({
                                        ...filters,
                                        from_height: value,
                                    })
                                }
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>To Height</label>
                            <Dropdown
                                selection
                                options={getHeightOptions()}
                                value={filters.to_height}
                                onChange={(e, { value }) =>
                                    setFilters({
                                        ...filters,
                                        to_height: value,
                                    })
                                }
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>.</label>
                            <Button
                                icon
                                onClick={() => {
                                    setFilters(
                                        produce((df) => {
                                            df.from_height = null;
                                            df.to_height = null;
                                        })
                                    );
                                }}
                            >
                                <Icon name="times" />
                            </Button>
                        </Form.Field>
                    </Form.Group>

                    <Form.Group>
                        <Form.Field>
                            <label>Work Country</label>
                            <Input
                                action={
                                    <Button
                                        icon
                                        onClick={() => {
                                            setFilters(
                                                produce((df) => {
                                                    df.work_country = "";
                                                })
                                            );
                                        }}
                                    >
                                        <Icon name="times" />
                                    </Button>
                                }
                                value={filters.work_country}
                                onChange={(e) =>
                                    setFilters({
                                        ...filters,
                                        work_country: e.target.value,
                                    })
                                }
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Work Location (city)</label>
                            <Input
                                action={
                                    <Button
                                        icon
                                        onClick={() => {
                                            setFilters({
                                                ...filters,
                                                work_location: "",
                                            });
                                        }}
                                    >
                                        <Icon name="times" />
                                    </Button>
                                }
                                value={filters.work_location}
                                onChange={(e) =>
                                    setFilters({
                                        ...filters,
                                        work_location: e.target.value,
                                    })
                                }
                            />
                        </Form.Field>
                    </Form.Group>
                    <Form.Group>
                        <LookupDropdown
                            type="Education Name"
                            label="Education"
                            value={filters.education}
                            onChange={(new_val) =>
                                setFilters({
                                    ...filters,
                                    education: new_val,
                                })
                            }
                            width={4}
                        />
                        <Form.Field>
                            <label>Specialization</label>
                            <Input
                                action={
                                    <Button
                                        icon
                                        onClick={() => {
                                            setFilters({
                                                ...filters,
                                                specialization: "",
                                                education: null,
                                            });
                                        }}
                                    >
                                        <Icon name="times" />
                                    </Button>
                                }
                                value={filters.specialization}
                                onChange={(e) =>
                                    setFilters({
                                        ...filters,
                                        specialization: e.target.value,
                                    })
                                }
                            />
                        </Form.Field>
                    </Form.Group>
                    <Form.Group>
                        <LookupDropdown
                            type="Job Type"
                            label="Job Type"
                            value={filters.job_type}
                            onChange={(new_val) =>
                                setFilters({
                                    ...filters,
                                    job_type: new_val,
                                })
                            }
                            width={4}
                        />
                        <Form.Field>
                            <label>.</label>
                            <Button
                                icon
                                onClick={() => {
                                    setFilters(
                                        produce((df) => {
                                            df.job_type = null;
                                        })
                                    );
                                }}
                            >
                                <Icon name="times" />
                            </Button>
                        </Form.Field>
                    </Form.Group>
                    <Form.Field>
                        {/* <Checkbox
                            checked={filters.match_horoscope}
                            onChange={(e, data) => {
                                setFilters({
                                    ...filters,
                                    match_horoscope: data.checked,
                                });
                            }}
                        /> */}
                        <label>Do match horoscope</label>
                        <Button.Group>
                            <Button
                                primary={filters.match_horoscope}
                                onClick={() => {
                                    setFilters({
                                        ...filters,
                                        match_horoscope: true,
                                    });
                                }}
                            >
                                Yes
                            </Button>
                            <Button
                                primary={filters.match_horoscope === false}
                                onClick={() => {
                                    setFilters({
                                        ...filters,
                                        match_horoscope: false,
                                    });
                                }}
                            >
                                No
                            </Button>
                            <Button
                                primary={filters.match_horoscope === undefined}
                                onClick={() => {
                                    setFilters({
                                        ...filters,
                                        match_horoscope: undefined,
                                    });
                                }}
                            >
                                All
                            </Button>
                        </Button.Group>
                    </Form.Field>
                    <Form.Field>
                        <label>Is Handicapped</label>
                        <Button.Group>
                            <Button
                                primary={filters.is_handicapped}
                                onClick={() => {
                                    setFilters({
                                        ...filters,
                                        is_handicapped: true,
                                    });
                                }}
                            >
                                Yes
                            </Button>
                            <Button
                                primary={filters.is_handicapped === false}
                                onClick={() => {
                                    setFilters({
                                        ...filters,
                                        is_handicapped: false,
                                    });
                                }}
                            >
                                No
                            </Button>
                            <Button
                                primary={filters.is_handicapped === undefined}
                                onClick={() => {
                                    setFilters({
                                        ...filters,
                                        is_handicapped: undefined,
                                    });
                                }}
                            >
                                All
                            </Button>
                        </Button.Group>
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button color="black" onClick={() => setOpen(false)}>
                    Cancel
                </Button>
                <Button
                    icon
                    labelPosition="right"
                    primary
                    onClick={applyFilters}
                >
                    Apply
                    <Icon name="arrow right" />
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

export default SearchFilters;

function getHeightOptions() {
    const options = [];
    for (let i = 4; i < 9; i++) {
        for (let j = 0; j < 12; j++) {
            const cm = i * 30.48 + j * 2.54;
            options.push({ key: cm, text: `${i} feet ${j} inch`, value: cm });
        }
    }
    return options;
}
