import React, { useState } from "react";
import Screen from "../commons/Screen";
import AllProfiles from "../showcase/AllProfiles";
import DownloadProfilesButton from "./DownloadProfilesButton";
import { Input } from "semantic-ui-react";
import { useDebounce } from "@uidotdev/usehooks";
import ColdMailSendProfileBtn from "../mail/ColdMailSendProfileBtn";

function CandidatesAll() {
    const [search_query, setSearchQuery] = useState("");
    const debounced_search_query = useDebounce(search_query, 300);
    return (
        <Screen
            title="All Candidates"
            wrapper_styles={{
                marginTop: "-30px",
            }}
            actions={[
                <Input
                    icon="search"
                    value={search_query}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />,
                <ColdMailSendProfileBtn status={null} />,
                <DownloadProfilesButton status={null} />,
            ]}
        >
            <AllProfiles
                search_query={
                    debounced_search_query?.length > 2
                        ? `search=${debounced_search_query}`
                        : ""
                }
            />
        </Screen>
    );
}

export default CandidatesAll;
