import React from "react";
import Screen from "../commons/Screen";
import ColdMailSendProfileBtn from "../mail/ColdMailSendProfileBtn";
import AllProfiles from "../showcase/AllProfiles";
import DownloadProfilesButton from "./DownloadProfilesButton";

function CandidatesRejected() {
    return (
        <Screen
            title="Rejected Candidates"
            wrapper_styles={{
                marginTop: "-30px",
            }}
            actions={[
                <ColdMailSendProfileBtn status="Rejected" />,
                <DownloadProfilesButton status="Rejected" />,
            ]}
        >
            <AllProfiles status="Rejected" />
        </Screen>
    );
}

export default CandidatesRejected;
