import React from "react";
import Screen from "./commons/Screen";
import SaveNoteView from "./home/SaveNoteView";

export default function SaveNoteProfiles() {
  return (
    <Screen title={"Save with Notes Profiles"}>
      <>
        <SaveNoteView />
      </>
    </Screen>
  );
}
