import React from "react";
import Screen from "../commons/Screen";

function CandidateEdit() {
    return (
        <Screen>
            <h3>Candidate View</h3>
        </Screen>
    );
}

export default CandidateEdit;
