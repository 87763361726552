import React from "react";
import Screen from "../commons/Screen";
import { useNavigate, useParams } from "react-router-dom";
import { useSingleItem } from "../_actions/items";
import CandidateDetailView from "../profile/CandidateDetailView";
import { Button, Icon } from "semantic-ui-react";

const PROFILE_FIELDS =
    "fields=*,native_place.name,birth_place.name,complexion.name,body_build.name,blood_group.name,location.name,country.name,job_type.name,addresses.*,photos.*,user_created.*,education.name,education_stream,user.first_name,user.last_name,user.email";

function CandidateView() {
    const { id } = useParams();
    const navigate = useNavigate("/");
    const { isSuccess, isLoading, data } = useSingleItem(
        "qd_matrimonial_profile",
        id,
        `?${PROFILE_FIELDS}`,
        !!id
    );

    return (
        <Screen
            // title="My Candidate"
            wrapper_styles={{
                marginTop: "-70px",
            }}
            actions={[]}
        >
            <Button
                primary
                style={{ float: "right", margin: "1em" }}
                onClick={() => {
                    navigate(`/candidate/edit?user=${data?.user_created?.id}`);
                }}
            >
                <Icon name="edit" />
                Act as user
            </Button>
            {isSuccess && data && (
                <CandidateDetailView data={data} admin={true} />
            )}
        </Screen>
    );
}

export default CandidateView;
