import React, { useState, useEffect } from "react";
import CenterCard from "../commons/CenterCard";
import { Form, Button, Checkbox } from "semantic-ui-react";
import { useRegister } from "../_actions/auth";
import { history } from "../_helpers/history";
import { useNavigate } from "react-router-dom";
import { generateOtp } from "../utils";
import { useCreateItem } from "../_actions/mutations/items";

function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

function Register() {
    const navigate = useNavigate();
    const otp = generateOtp();
    const [data, setData] = useState({
        first_name: "",
        last_name: "",
        mobile: "",
        password: "",
        confirm_password: "",
    });
    const [err, setErr] = useState({});
    const [isaccepted, setIsaccepted] = useState(false);
    const mutation = useRegister(
        (res) => {
            //success
            console.log("Sendig to home");
            navigate("/verify_otp?status=sent");
        },
        (err) => {
            console.log("Error while register", err);
            const err_str = JSON.stringify(err);
            if (err_str.includes("RECORD_NOT_UNIQUE")) {
                alert("User already registered. Please login");
                navigate("/login");
            }
        }
    );

    const send_nt = useCreateItem("qdb_mail");

    const registerNow = () => {
        let mobile = data.mobile;
        if (
            !mobile.toString().startsWith("91") ||
            mobile.toString().length === 10
        ) {
            mobile = `91${mobile}`;
        }

        if (!isValidPhoneNumber(mobile)) {
            // valid error
            setErr({
                mobile: "Please enter valid 10-digit mobile no.",
            });
        } else if (!isValidEmail(data.email)) {
            setErr({
                email: "Please enter valid email address",
            });
        } else {
            //   const email = `${mobile}@vmm.com`;
            const email = data.email;
            if (data.password !== data.confirm_password) {
                setErr({
                    password: "Password do not match",
                });
            } else {
                mutation.mutate(
                    {
                        ...data,
                        // mobile,
                        email,
                        // send_otp: true,
                        otp: otp,
                    },
                    {
                        onSuccess: (data) => {
                            console.log(data);
                            const payload = {
                                to_email: email,
                                subject: "Verify Your Account with OTP",
                                body_heading:
                                    "Complete the Verification Process\n",
                                body_content: `Dear ${data.user.first_name} ${data.user.last_name},\n
                  Thank you for registering with our service. To complete the account verification process, please use the following One-Time Password (OTP):\n\n
                  OTP:${otp}\n\n
                  Please enter this OTP on the verification page to confirm your email address. This OTP is valid for a limited time only. Do not share this OTP with anyone for security reasons.
                  If you did not initiate this registration or need any assistance, please contact our support team immediately at [Contact Information].
                  Thank you for choosing our service.\n
                  Best regards,\n
                  Qureal Technologies\n
                            `,
                            };
                            send_nt.mutate(payload);
                        },
                    }
                );
            }
        }
    };

    return (
        <CenterCard width="400px">
            <h3 style={{ textAlign: "center" }}>Create Account</h3>
            <Form error={mutation.isError} onSubmit={registerNow}>
                <Form.Field error={!!err.mobile}>
                    <label>Mobile Number</label>
                    <input
                        type="number"
                        placeholder="Mobile Number"
                        name="mobile"
                        maxLength="10"
                        value={data.mobile}
                        onChange={(e) => {
                            setData({ ...data, mobile: e.target.value });
                        }}
                    />
                    {err.mobile && <p className="error">{err.mobile}</p>}
                </Form.Field>
                <Form.Group widths="equal">
                    <Form.Field required>
                        <label>First Name</label>
                        <input
                            required
                            placeholder="First Name"
                            name="first_name"
                            value={data.first_name}
                            onChange={(e) =>
                                setData({ ...data, first_name: e.target.value })
                            }
                        />
                    </Form.Field>
                    <Form.Field required>
                        <label>Last Name</label>
                        <input
                            required
                            placeholder="Last Name"
                            name="last_name"
                            value={data.last_name}
                            onChange={(e) =>
                                setData({ ...data, last_name: e.target.value })
                            }
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Field required>
                    <label>Email</label>
                    <input
                        required
                        type="email"
                        placeholder="Email"
                        name="email"
                        value={data.email}
                        onChange={(e) =>
                            setData({ ...data, email: e.target.value })
                        }
                    />
                    {err.email && <p className="error">{err.email}</p>}
                </Form.Field>
                <Form.Field required>
                    <label>Password</label>
                    <input
                        required
                        type="password"
                        placeholder="Password"
                        name="password"
                        value={data.password}
                        onChange={(e) => {
                            setData({ ...data, password: e.target.value });
                        }}
                    />
                </Form.Field>
                <Form.Field required>
                    <label>Confirm Password</label>
                    <input
                        required
                        type="password"
                        placeholder="Confirm Password"
                        name="confirm_password"
                        value={data.confirm_password}
                        onChange={(e) => {
                            setData({
                                ...data,
                                confirm_password: e.target.value,
                            });
                        }}
                    />
                </Form.Field>
                {err.password && <p className="error">{err.password}</p>}
                <Form.Field>
                    <div
                        style={{
                            display: "flex",
                        }}
                    >
                        <input
                            type="checkbox"
                            id="terms_and_condition"
                            onChange={(e) => {
                                setIsaccepted(e.target.checked);
                            }}
                        />
                        <label
                            style={{
                                marginLeft: "5px",
                            }}
                        >
                            I accept the{" "}
                            <a
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    navigate("/terms-and-conditions");
                                }}
                            >
                                terms and conditions
                            </a>{" "}
                            of this website
                        </label>
                    </div>
                </Form.Field>
                <Button
                    type="submit"
                    loading={mutation.isLoading}
                    color="red"
                    fluid
                    disabled={!isaccepted}
                >
                    Register
                </Button>
            </Form>
            <div
                style={{
                    padding: "7px",
                    textAlign: "center",
                    width: "100%",
                }}
            >
                <a href="/login">Already a user? login now</a>
            </div>
        </CenterCard>
    );
}

export default Register;

function isValidPhoneNumber(phoneNumber) {
    const regex = /^\d{12}$/;
    return regex.test(phoneNumber);
}
