import React from "react";
import styles from "./styles/Settings.module.css";
import { Menu, Button, Icon, Divider } from "semantic-ui-react";
import { useItems } from "../_actions/items";
// import log from "cslog";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CreateLookupTypeDialog from "./CreateLookupTypeDialog";
import { useState } from "react";
import { useCurrentRole } from "../_actions/roles";

const log = {
    d: (value, name) => (name, value),
};

function SettingsSidebar({ permissions = { lookup_create: true } }) {
    const { role_name } = useCurrentRole();
    const { isSuccess, isLoading, data } = useItems(
        "qdb_lookup_type",
        null,
        role_name === "admin"
    );

    const location = useLocation();

    log.d(data, "Lookup Type Data");
    ///projects/archived
    return (
        <>
            <div className={styles.sidebar}>
                <Menu vertical fluid>
                    <Menu.Item header>Settings</Menu.Item>
                    {role_name === "admin" && (
                        <Link key="admin_setting" to="/settings/admin_setting">
                            <Menu.Item
                                name="Admin Settings"
                                active={
                                    location?.pathname ===
                                    "/settings/admin_setting"
                                }
                            />
                        </Link>
                    )}

                    <Link key="reset_password" to="/settings/reset_password">
                        <Menu.Item
                            name="Reset Password"
                            active={
                                location?.pathname ===
                                "/settings/reset_password"
                            }
                        />
                    </Link>
                    <Link key="mark_married" to="/settings/mark_married">
                        <Menu.Item
                            name="Update Status"
                            active={
                                location?.pathname === "/settings/mark_married"
                            }
                        />
                    </Link>
                    {role_name === "admin" || (
                        <>
                            <Link
                                key="my_membership"
                                to="/settings/my_membership"
                            >
                                <Menu.Item
                                    name="My Membership"
                                    active={
                                        location?.pathname ===
                                        "/settings/my_membership"
                                    }
                                />
                            </Link>
                            <Link key="fav_members" to="/fav_members">
                                <Menu.Item
                                    name="Favorite Profiles"
                                    active={
                                        location?.pathname === "/fav_members"
                                    }
                                />
                            </Link>
                            <Link key="fav_members" to="/save_note_members">
                                <Menu.Item
                                    name="My Noted Profiles"
                                    active={
                                        location?.pathname ===
                                        "/save_note_members"
                                    }
                                />
                            </Link>
                            <Link key="block_members" to="/blocked_members">
                                <Menu.Item
                                    name="Blocked Profiles"
                                    active={
                                        location?.pathname ===
                                        "/blocked_members"
                                    }
                                />
                            </Link>
                        </>
                    )}
                </Menu>
                {role_name === "admin" && (
                    <Menu
                        vertical
                        fluid
                        style={{
                            marginTop: "0px",
                            maxHeight: "60vh",
                            overflowY: "auto",
                        }}
                    >
                        <Menu.Item header>Lookups</Menu.Item>
                        {isSuccess &&
                            data.map((item) => (
                                <Link
                                    key={item.name}
                                    to={`/settings/lookup/${item.name}`}
                                >
                                    <Menu.Item
                                        name={item.name}
                                        active={
                                            location?.pathname ===
                                            `/settings/lookup/${item.name}`
                                        }
                                    />
                                </Link>
                            ))}
                        <Menu.Item>
                            {permissions.lookup_create && (
                                <div className={styles.action_wrapper}>
                                    <CreateLookupTypeDialog
                                        trigger={
                                            <Button
                                                icon
                                                labelPosition="left"
                                                fluid
                                            >
                                                <Icon name="plus" />
                                                New Lookup
                                            </Button>
                                        }
                                    />
                                </div>
                            )}
                        </Menu.Item>
                    </Menu>
                )}
            </div>
        </>
    );
}

export default SettingsSidebar;
