import React, { useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import { useCreateItem } from "../_actions/mutations/items";

function CreateLookupTypeDialog({ trigger }) {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState({
        name: "",
    });
    const mutation = useCreateItem("qdb_lookup_type", null, () => {
        setOpen(false);
        setData({});
    });

    const saveValue = () => {
        mutation.mutate({
            ...data,
        });
    };

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={trigger}
            size="tiny"
        >
            <Modal.Header>Add New Lookup Type</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Field>
                        <label>Lookup Name</label>
                        <input
                            placeholder="Name"
                            value={data.name}
                            onChange={(e) =>
                                setData({ ...data, name: e.target.value })
                            }
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button color="black" onClick={() => setOpen(false)}>
                    Cancel
                </Button>
                <Button
                    content="Save"
                    labelPosition="right"
                    icon="checkmark"
                    onClick={saveValue}
                    positive
                    loading={mutation.isLoading}
                />
            </Modal.Actions>
        </Modal>
    );
}

export default CreateLookupTypeDialog;
