import React, { useEffect, useState } from "react";
import Screen from "../commons/Screen";
import { useItemsFilterNew } from "../_actions/items";
import MailCard from "./MailCard";
import { Pagination } from "semantic-ui-react";

function MailDashboard({ per_page = 5 }) {
    const [total, setTotal] = useState(1);
    const [num_page, setNumPage] = useState(1);
    const [page_no, setPageNo] = useState(1);
    const { isLoading, isSuccess, data } = useItemsFilterNew(
        "qdb_cold_mail",
        `?fields=*,to_profile.status&sort=-date_created&limit=${per_page}&offset=${
            per_page * (page_no - 1)
        }&meta=filter_count`,
        true,
        true
    );

    useEffect(() => {
        if (isSuccess) {
            const total_records = data?.meta?.filter_count;
            if (total_records > 0) {
                setTotal(total_records);
                setNumPage(Math.ceil(total_records / per_page));
            }
            console.log("User Response", data);
        }
    }, [isSuccess, data]);

    console.log("Mail Data", data);

    return (
        <Screen
            title="Bulk Mail"
            wrapper_styles={{
                marginTop: "-30px",
            }}
        >
            {isSuccess &&
                data?.data?.map((item) => {
                    return <MailCard data={item} />;
                })}
            <Pagination
                activePage={page_no}
                totalPages={num_page}
                onPageChange={(e, { activePage }) => setPageNo(activePage)}
            />
        </Screen>
    );
}

export default MailDashboard;
