import React, { useRef } from "react";
import { useState } from "react";
import { Button, Modal, Form, Icon } from "semantic-ui-react";
import { useUploadFile } from "../_actions/files";
import { getImageURL } from "../_helpers/files";

function VideoPicker({ value, onChange }) {
    const [open, setOpen] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [previewURL, setPreviewURL] = useState(null);
    const [error, setError] = useState("");
    const hiddenFileInput = useRef(null);
    const uploadMutation = useUploadFile((data) => {
        //onSuccess
        // alert(`Video uploaded: ${JSON.stringify(data)}`);
        onChange(data?.id);
        setOpen(false);
    });

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > 20 * 1024 * 1024) {
                setError("File size exceeds the maximum limit of 20MB.");
            } else {
                setError("");
                setSelectedVideo(file);

                // Create a URL for video preview
                const videoURL = URL.createObjectURL(file);
                setPreviewURL(videoURL);
            }
        }
    };

    const uploadVideo = () => {
        if (selectedVideo) {
            const formData = new FormData();
            formData.append("file", selectedVideo);
            uploadMutation.mutate(formData);
        }
    };

    return (
        <div>
            <Modal
                size="small"
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                trigger={
                    <div style={{ paddingBottom: "10px" }}>
                        <Form.Field>
                            <label>Video Profile</label>
                            <Button icon labelPosition="left">
                                <Icon name="upload" />
                                {value ? "Change Video" : "Upload Video"}
                            </Button>
                            {value && (
                                <Button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        onChange(null);
                                    }}
                                >
                                    Remove
                                </Button>
                            )}
                        </Form.Field>
                        {value && (
                            <video
                                controls
                                width="400"
                                style={{
                                    borderRadius: "5px",
                                    overflow: "hidden",
                                    border: "1px solid #ccc",
                                }}
                            >
                                <source
                                    src={getImageURL(value)}
                                    type="video/mp4"
                                />
                                Your browser does not support the video tag.
                            </video>
                        )}
                    </div>
                }
            >
                <Modal.Header>Create Video Profile</Modal.Header>
                <Modal.Content>
                    <input
                        type="file"
                        accept="video/*"
                        onChange={handleFileChange}
                        ref={hiddenFileInput}
                        style={{ display: "none" }}
                    />
                    <p>Max file size: 20MB</p>
                    <Button
                        onClick={(e) => {
                            e.preventDefault();
                            hiddenFileInput.current.click();
                        }}
                        icon
                        labelPosition="left"
                    >
                        <Icon name="upload" />
                        {selectedVideo ? "Change Video" : "Select Video"}
                    </Button>
                    {error && <p style={{ color: "red" }}>{error}</p>}
                    {selectedVideo && (
                        <div style={{ marginTop: "15px" }}>
                            <video controls width="400">
                                <source src={previewURL} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    )}
                </Modal.Content>
                <Modal.Actions>
                    <Button color="black" onClick={() => setOpen(false)}>
                        Cancel
                    </Button>
                    <Button
                        icon
                        positive
                        labelPosition="right"
                        onClick={uploadVideo}
                        loading={uploadMutation.isLoading}
                    >
                        Upload
                        <Icon name="arrow right" />
                    </Button>
                </Modal.Actions>
            </Modal>
        </div>
    );
}

export default VideoPicker;
