import React, { useEffect, useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Button, Icon, Modal } from "semantic-ui-react";

function ImageCropper({
  open,
  setOpen,
  aspect, // 1/1
  init_src,
  onFinished = () => {},
}) {
  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState({ aspect: aspect });
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [imageRef, setImageRef] = useState(null);
  const [croppedImageBlob, setCroppedImageBlob] = useState(null);

  useEffect(() => {
    if (init_src) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setSrc(reader.result));
      reader.readAsDataURL(init_src);
    }
  }, [init_src]);

  // const onSelectFile = (e) => {
  //     if (e.target.files && e.target.files.length > 0) {
  //         const reader = new FileReader();
  //         reader.addEventListener("load", () => setSrc(reader.result));
  //         reader.readAsDataURL(e.target.files[0]);
  //     }
  // };

  const onImageLoaded = (image) => {
    setImageRef(image);
  };

  const onCropComplete = (crop) => {
    if (imageRef && crop.width && crop.height) {
      getCroppedImg(imageRef, crop)
        .then(({ croppedImageUrl, blob }) => {
          setCroppedImageUrl(croppedImageUrl);
          setCroppedImageBlob(blob);
        })
        .catch((err) => {
          console.error("Failed to create cropped image", err);
        });
    }
  };

  const getCroppedImg = (image, crop) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = "newFile.jpeg";
        window.URL.revokeObjectURL(croppedImageUrl);
        let fileUrl = window.URL.createObjectURL(blob);
        resolve({ croppedImageUrl: fileUrl, blob });
      }, "image/jpeg");
    });
  };

  const uploadCroppedImage = async () => {
    onFinished(croppedImageBlob);
  };

  return (
    <Modal
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
    >
      <Modal.Header style={{ textAlign: "center" }}>Crop Image</Modal.Header>
      <Modal.Content>
        <p style={{ textAlign: "center" }}>
          Note: When you mouse hover on image '+' will appear.
        </p>
        <p style={{ textAlign: "center" }}>
          Please hold the '+' and draw the square to select the portion of image
          to be uploaded.
        </p>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {src && (
            <ReactCrop
              src={src}
              crop={crop}
              ruleOfThirds
              onImageLoaded={onImageLoaded}
              onComplete={onCropComplete}
              onChange={(c) => setCrop(c)}
            />
          )}
        </div>
      </Modal.Content>
      <Modal.Actions style={{ textAlign: "center" }}>
        <Button color="black" onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button
          primary
          icon
          labelPosition="right"
          onClick={uploadCroppedImage}
          disabled={!croppedImageUrl}
        >
          Crop <Icon name="arrow right" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default ImageCropper;
