import React from "react";
import "./styles/Profile.css";
import { Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";

export default function Profession({ data }) {
  // const navigate = useNavigate();
  // console.log(data);
  return (
    <>
      <div>
        <div className="parent">
          <div className="child2">
            <div className="child2_info">
              <h2>
                {data?.first_name}
                {""} {data?.middle_name} {""}
                {data?.last_name}
              </h2>

              <div className="user_personal_details">
                <div>
                  <p
                    style={{
                      // overflow: "hidden",
                      maxWidth: "25em",
                    }}
                  >
                    {" "}
                    <span>Highest Education:</span>{" "}
                    <span className="bold_div"> {data?.education?.name}</span>{" "}
                  </p>
                  <p>
                    <span>Job Type:</span>{" "}
                    <span className="bold_div">{data?.job_type?.name} </span>
                  </p>
                  <p>
                    {" "}
                    <span>Work Location: </span>{" "}
                    <span className="bold_div">
                      {data?.new_location
                        ? data?.new_location
                        : data?.location?.name}
                      {data?.new_country
                        ? ", " + data?.new_country
                        : ", " + data?.country?.name}
                    </span>{" "}
                  </p>
                  <p>
                    {" "}
                    <span>Annual Income: </span>{" "}
                    <span className="bold_div">
                      {" "}
                      {data?.income_currency} {data?.income} PA
                    </span>{" "}
                  </p>
                </div>

                <div className="user_div">
                  {" "}
                  <p
                    style={{
                      // overflow: "hidden",
                      maxWidth: "18em",
                    }}
                  >
                    <span>Specialization: </span>{" "}
                    <span className="bold_div"> {data?.education_stream}</span>
                  </p>
                  <p
                    style={{
                      // overflow: "hidden",
                      maxWidth: "18em",
                    }}
                  >
                    {" "}
                    <span>Occupation: </span>{" "}
                    <span className="bold_div">{data.occupation}</span>
                  </p>
                  <p
                    style={{
                      // overflow: "hidden",
                      maxWidth: "18em",
                    }}
                  >
                    {" "}
                    <span>Designation: </span>{" "}
                    <span className="bold_div"> {data?.designation}</span>
                  </p>
                </div>
              </div>
              <div className="logo_div">
                {data.social_linkedin && (
                  <Link to={data.social_linkedin} target="_blank">
                    <Icon link name="linkedin" size="large" color="blue" />
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
