import React, { useState, useEffect } from "react";
import CenterCard from "../commons/CenterCard";
import { useMe, useSendOTP, useVerifyOTP } from "../_actions/auth";
import { Form, Button } from "semantic-ui-react";
import { useNavigate, Navigate } from "react-router-dom";
import { useQueryParams } from "../_helpers/params";
import { useCreateItem, useUpdateItem } from "../_actions/mutations/items";
import { generateOtp } from "../utils";

function VerifyOTP() {
    const query = useQueryParams();
    const new_otp = generateOtp();
    const [otp, setOTP] = useState("");
    const { isLoading, isSuccess, data, refetch } = useMe();
    const [status, setStatus] = useState("idle");
    const [err, setErr] = useState("");
    const [redirect_path, setRedirectPath] = useState(null);
    //   const mutationSendOTP = useSendOTP((res) => {
    //     console.log("OTP sent res", res);
    //     if (res.status === "success") {
    //       setStatus("sent");
    //     } else if (res.status === "error") {
    //       setRedirectPath("/");
    //     }
    //   });
    //   const mutationVerifyOTP = useVerifyOTP((res) => {
    //     console.log("Verification success. Sending home..");
    //     // navigate("/");
    //     setRedirectPath("/");
    //   });

    const updateUsermutation = useUpdateItem("directus_users");
    const send_nt = useCreateItem("qdb_mail");

    const VerifyOTP = () => {
        console.log(data);
        if (data?.data?.otp === otp) {
            console.log("verified");
            updateUsermutation.mutate(
                {
                    id: data?.data?.id,
                    data: { email_verified: true },
                },
                {
                    onSuccess: (data) => {
                        setRedirectPath("/");
                    },
                }
            );
        } else {
            setErr("OTP is Incorrect");
        }
    };

    const sendOtp = () => {
        updateUsermutation.mutate(
            {
                id: data?.data?.id,
                data: { otp: new_otp },
            },
            {
                onSuccess: () => {
                    const payload = {
                        to_email: email,
                        subject: `Verify Your Account with OTP ${new_otp}`,
                        body_heading: "Complete the Verification Process",
                        body_content: `Dear ${data?.data?.first_name} ${data?.data?.last_name},
            Thank you for registering with our service. To complete the account verification process, please use the following One-Time Password (OTP):
            OTP:${new_otp}
            Please enter this OTP on the verification page to confirm your email address. This OTP is valid for a limited time only. Do not share this OTP with anyone for security reasons.
            If you did not initiate this registration or need any assistance, please contact our support team immediately at [Contact Information].
            Thank you for choosing our service.
            Best regards,
            Vikas Mandal Mulund
                      `,
                    };
                    send_nt.mutate(payload, {
                        onSuccess: () => {
                            refetch();
                            setStatus("sent");
                        },
                    });
                },
            }
        );
    };

    //   const mobile = data?.data?.mobile;
    const email = data?.data?.email;

    useEffect(() => {
        if (isSuccess) {
            //   const mobile_verified = data?.data?.mobile_verified;
            const email_verified = data?.data?.email_verified;
            if (email_verified) {
                setRedirectPath("/");
            }
        }
    }, [isSuccess, data]);

    //   console.log("Me Data", data);

    useEffect(() => {
        setStatus(query.get("status"));
    }, [query]);

    if (redirect_path) {
        console.log("Redirecting to ", redirect_path);
        return <Navigate to={redirect_path} />;
    }
    return (
        <CenterCard height="100vh">
            <h3>Verify OTP</h3>
            {status === "sent" ? (
                <Form
                    error={updateUsermutation.isError}
                    onSubmit={() => {
                        // mutationVerifyOTP.mutate(otp);
                        VerifyOTP();
                    }}
                >
                    <p>OTP has been sent to {email}</p>
                    <Form.Field>
                        <label>Email OTP</label>
                        <input
                            placeholder="Enter OTP"
                            name="otp"
                            type="number"
                            value={otp}
                            onChange={(e) => setOTP(e.target.value)}
                        />
                    </Form.Field>
                    <Button
                        type="submit"
                        loading={updateUsermutation.isLoading}
                        color="red"
                        fluid
                    >
                        Verify
                    </Button>
                    {err ? (
                        <p
                            style={{
                                color: "red",
                                marginTop: "1em",
                            }}
                        >
                            {err}
                        </p>
                    ) : (
                        ""
                    )}
                </Form>
            ) : (
                <Form
                    error={updateUsermutation.isError}
                    onSubmit={() => {
                        sendOtp();
                    }}
                >
                    <p>Click to send OTP to {email}</p>

                    <Button
                        type="submit"
                        loading={updateUsermutation.isLoading}
                        color="red"
                        fluid
                    >
                        Send OTP
                    </Button>
                </Form>
            )}
        </CenterCard>
    );
}

export default VerifyOTP;
