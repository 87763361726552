import React from "react";
import "./styles/Profile.css";
import { Divider, Form, Icon, Segment, TextArea } from "semantic-ui-react";
import { Link, useNavigate } from "react-router-dom";
import { SocialBar } from "../../profile/CandidateDetailView";
// import Family from "./Family";

export default function Family({ data }) {
    // const navigate = useNavigate();

    console.log(data);

    return (
        <>
            <div>
                <div className="parent">
                    <div
                        className="child2"
                        style={{
                            width: "47em",
                        }}
                    >
                        <div className="child2_info">
                            <h2>
                                {data?.first_name}
                                {""} {data?.middle_name} {""}
                                {data?.last_name}
                            </h2>

                            <div className="user_personal_details">
                                <div>
                                    <p>
                                        {" "}
                                        <span>Location: </span>{" "}
                                        <span className="bold_div">
                                            {" "}
                                            {data?.location?.name
                                                ? data?.location?.name
                                                : data?.new_location}
                                        </span>{" "}
                                    </p>

                                    <p>
                                        {" "}
                                        <span> Brothers: </span>{" "}
                                        <span className="bold_div">
                                            {data?.brothers_married
                                                ? data?.brothers_married
                                                : 0}{" "}
                                            Married,{" "}
                                            {data?.brothers_unmarried
                                                ? data?.brothers_unmarried
                                                : 0}{" "}
                                            Unmarried{" "}
                                        </span>{" "}
                                    </p>
                                    {/* <p>
                    <span>Phone : </span>{" "}
                    <span className="bold_div">
                      {data?.addresses[0]?.mobile}
                    </span>
                  </p> */}
                                </div>

                                <div className="user_div">
                                    {" "}
                                    <p>
                                        <span>Native Place: </span>{" "}
                                        <span className="bold_div">
                                            {data?.native_place?.name || "-"}
                                        </span>
                                    </p>
                                    <p>
                                        {" "}
                                        <span>Maternal Surname: </span>{" "}
                                        <span className="bold_div">
                                            {data?.maternal_surname || "-"}
                                        </span>
                                    </p>
                                    <p>
                                        {" "}
                                        <span>Sisters</span>{" "}
                                        <span className="bold_div">
                                            {data?.sisters_married
                                                ? data?.sisters_married
                                                : 0}{" "}
                                            Married,{" "}
                                            {data?.sisters_unmarried
                                                ? data?.sisters_unmarried
                                                : 0}{" "}
                                            Unmarried{" "}
                                        </span>
                                    </p>
                                </div>
                            </div>

                            {data?.addresses?.map((ele, idx) => (
                                <>
                                    <p
                                        className="aboutme_text"
                                        style={{
                                            marginTop: "1em",
                                        }}
                                    >{`Address ${idx + 1}:`}</p>
                                    <Address address={ele} />
                                </>
                            ))}
                            <>
                                <p
                                    style={{
                                        marginTop: "1.2em",
                                    }}
                                >
                                    <b>Email: </b> {data?.email}
                                </p>
                            </>

                            <div className="logo_div">
                                {data?.social_facebook && (
                                    <Link
                                        to={data?.social_facebook}
                                        target="_blank"
                                    >
                                        <Icon
                                            link
                                            name="facebook"
                                            size="large"
                                            color="blue"
                                        />
                                    </Link>
                                )}
                                {data.social_twitter && (
                                    <Link
                                        to={data.social_twitter}
                                        target="_blank"
                                    >
                                        <Icon
                                            link
                                            name="twitter"
                                            size="large"
                                            color="skyblue"
                                        />
                                    </Link>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const Address = ({ address }) => {
    return (
        <>
            <div className="user_div">
                <p>
                    <span>Name: </span>{" "}
                    <span className="bold_div"> {address?.name}</span>
                </p>
                <p>{address.address}</p>
                <p>
                    {address.city}, {address.pincode}
                </p>
                <p>
                    Mobile: {address.mobile}, {address.mobile_alternative}
                </p>
            </div>
        </>
    );
};

// function AddressView({ title, address }) {
//   return (
//     <div className={styles.section}>
//       <h4 className={styles.section_heading}>{title}</h4>
//       <p>{address.address}</p>
//       <p>
//         {address.city}, {address.pincode}
//       </p>
//       <p>
//         Mobile: {address.mobile}, {address.mobile_alternative}
//       </p>
//     </div>
//   );
// }
