import React, { useState } from "react";
import Screen from "../commons/Screen";
import AllProfiles from "../showcase/AllProfiles";
import UserList from "./UserList";
import { Button, Dropdown, Icon, Input } from "semantic-ui-react";
import { BASE_URL } from "../constants";
import { restoreAccessToken } from "../_helpers/auth";
import { useDebounce } from "@uidotdev/usehooks";

function AllUsers() {
    const [filter, setFilter] = useState("&");
    const [search_query, setSearchQuery] = useState("");
    const debounced_search_query = useDebounce(search_query, 300);
    return (
        <Screen
            title="All Users"
            wrapper_styles={{
                marginTop: "-30px",
            }}
            actions={[
                <Input
                    icon="search"
                    value={search_query}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />,
                <Dropdown
                    options={[
                        { key: "all", text: "All", value: "&" },
                        {
                            key: "active_profile",
                            text: "Active Profile",
                            value: `filter={"_and":[{"matrimonial_profiles":{"status":{"_eq":"Active"}}}]}`,
                        },
                        {
                            key: "order_paid",
                            text: "Purchased Suchi",
                            value: `filter={"_and":[{"orders":{"status":{"_eq":"paid"}}}]}`,
                        },
                        {
                            key: "order_address_filled",
                            text: "Order Address Filled",
                            value: `filter={"_and":[{"orders":{"name":{"_nempty":true}}},{"orders":{"address":{"_nempty":true}}},{"orders":{"status":{"_eq":"pending"}}}]}`,
                        },
                        {
                            key: "order_not",
                            text: "Not Purchased Suchi",
                            value: `filter={"_and":[{"_or":[{"orders":{"status":{"_neq":"paid"}}},{"count(orders)":{"_eq":"0"}}]}]}`,
                        },
                    ]}
                    selection
                    value={filter}
                    onChange={(e, { value }) => setFilter(value)}
                />,
                <a
                    href={`${BASE_URL}users?${filter}&export=csv&limit=-1&access_token=${restoreAccessToken()}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    <Button icon labelPosition="left" primary>
                        <Icon name="download" /> Download
                    </Button>
                </a>,
            ]}
        >
            <UserList
                filter={filter}
                search_query={
                    debounced_search_query?.length > 2
                        ? `search=${debounced_search_query}`
                        : ""
                }
            />
        </Screen>
    );
}

export default AllUsers;
