import { useMutation } from 'react-query'
import { publicAxios, secureAxios } from '../../_helpers/auth'
import { queryClient } from '../../_helpers/query'

export const useCreateItem = (
  collection,
  extra_invalidate = null,
  onDone = () => {},
  onSuccess = () => {}
) => {
  const query =
    collection === 'directus_users' ? '/users' : `/items/${collection}`

  return useMutation(
    newItem => {
      return secureAxios.post(`${query}`, newItem).then(res => res.data)
    },
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(['items', collection])
        if (extra_invalidate) {
          queryClient.invalidateQueries(['items', extra_invalidate])
        }
        onSuccess(data)
      },
      onSettled: () => {
        onDone()
      }
    }
  )
}

export const useUpdateItem = (
  collection,
  extra_invalidate,
  onDone = () => {}
) => {
  let query = collection === 'directus_users' ? 'users' : `items/${collection}`

  return useMutation(
    updatedItem => {
      return secureAxios
        .patch(`/${query}/${updatedItem.id}`, updatedItem.data)
        .then(res => res.data)
    },
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(['items', collection])
      },
      onSettled: () => {
        onDone()
      }
    }
  )
}
export const useDeleteItem = (collection, id) => {
  let query = collection === 'directus_users' ? 'users/' : `items/${collection}`

  return useMutation(
    id => {
      return secureAxios.delete(`/${query}/${id}`).then(res => res.data)
    },
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(['items', collection])
      }
    }
  )
}

export const useLogin = (collection, extra_invalidate = null) => {
  // let query = `login`;

  return useMutation(
    newItem => {
      return publicAxios.post(`/auth/login`, newItem).then(res => res.data)
    },
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(['items', collection])
        if (extra_invalidate) {
          queryClient.invalidateQueries(['items', extra_invalidate])
        }
      }
    }
  )
}

export const useUpdateItemNew = (
  collection,
  onSuccess = () => {},
  extra_invalidate = null
) => {
  return useMutation(
    data => {
      return secureAxios
        .patch(`/items/${collection}/${data.id}`, data.data)
        .then(res => res.data)
    },
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(['items', collection])
        if (extra_invalidate) {
          queryClient.invalidateQueries(['items', extra_invalidate])
        }
        onSuccess()
      }
    }
  )
}

export const useUpdateMe = (onSuccess = () => {}) => {
  return useMutation(
    data => {
      return secureAxios.patch(`/users/me`, data).then(res => res.data)
    },
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(['users', 'me'])
        queryClient.invalidateQueries(['items', 'user'])
        onSuccess()
      }
    }
  )
}

export const useChangePassword = onSuccess => {
  return useMutation(
    data => secureAxios.patch('/users/me', data).then(res => res.data),
    {
      onSuccess: (data, variables, context) => {}
    }
  )
}

export const useSendForgetPasswordLink = onSuccess => {
  return useMutation(
    data =>
      publicAxios.post('/auth/password/request', data).then(res => res.data),
    {
      onSuccess: (data, variables, context) => {
        onSuccess(data)
      }
    }
  )
}

export const useForgetPassword = onSuccess => {
  return useMutation(
    data =>
      publicAxios.post('/auth/password/reset', data).then(res => res.data),
    {
      onSuccess: (data, variables, context) => {
        onSuccess(data)
      }
    }
  )
}

export const useCreateOrderMutation = onSuccess => {
  return useMutation(
    data => secureAxios.post('/qe_payment/order', data).then(res => res.data),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(['items', 'qd_order'])
        onSuccess(data)
      }
    }
  )
}

export const useRefreshOrder = onSuccess => {
  return useMutation(
    data =>
      secureAxios.post('/qe_payment/verify_order', data).then(res => res.data),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(['items', 'qd_order'])
        onSuccess(data)
      }
    }
  )
}
