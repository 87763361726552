import React, { useEffect, useState } from "react";
import { Icon } from "semantic-ui-react";
import { useMediaQuery } from "@uidotdev/usehooks";
import { useCurrentUser } from "../_actions/auth";

function WhatsAppButton() {
    const me = useCurrentUser();
    const isMobile = useMediaQuery("only screen and (max-width : 768px)");
    const whatsappNumber = "7021057077"; // Replace with the actual WhatsApp number
    const [message, setMessage] = useState("Hi"); // Replace with your default message

    useEffect(() => {
        if (me) {
            setMessage(`Hi, I am ${me.first_name} ${me.last_name}\n`);
        }
    }, [me]);

    const openWhatsAppChat = () => {
        // Create the WhatsApp URL with the phone number and message
        const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
            message
        )}`;

        // Open the WhatsApp chat in a new window or tab
        window.open(whatsappUrl, "_blank");
    };

    return (
        <div
            onClick={openWhatsAppChat}
            style={{
                position: "fixed",
                bottom: 10,
                right: 10,
                backgroundColor: "#25D366",
                display: "flex",
                flexDirection: "row",
                gap: 5,
                alignItems: "center",
                padding: isMobile ? "7px 10px" : "10px 20px",
                borderRadius: "30px",
                fontSize: "1.2em",
                color: "white",
                boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                cursor: "pointer",
            }}
        >
            <Icon name="whatsapp" size="big" />
            {!isMobile && <span>Connect with us</span>}
        </div>
    );
}

export default WhatsAppButton;
