import React from "react";
import { useState } from "react";
import { Button, Card, Form, Icon, Input } from "semantic-ui-react";
import { useChangePassword, useMe } from "../_actions/auth";
// import { logout } from "../_helper/constant/auth";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Screen from "../commons/Screen";
import { useUpdateItemNew } from "../_actions/mutations/items";
import MessageBox from "../commons/MessageBox";

export default function MarkMarried() {
    const {
        isSuccess: successMe,
        isLoading: loadingMe,
        data: dataMe,
    } = useMe();
    const mutationUpdate = useUpdateItemNew(
        "qd_matrimonial_profile",
        () => {},
        "user"
    );

    const profile = dataMe?.data?.matrimonial_profiles?.[0];

    const markMarried = () => {
        mutationUpdate.mutate({
            id: profile?.id,
            data: {
                status: "married",
                marital_status: "married",
                last_status: profile?.status,
            },
        });
    };

    const markUnmarried = () => {
        mutationUpdate.mutate({
            id: profile?.id,
            data: {
                status: profile?.last_status,
                marital_status: "unmarried",
            },
        });
    };

    if (!successMe) {
        return <p>Loading....</p>;
    }

    return (
        <Screen
            title="Update Marital Status"
            wrapper_styles={{
                marginTop: "-30px",
            }}
        >
            {profile?.status === "married" && (
                <MessageBox
                    type="success"
                    title={`Congratulations, You have marked ${profile?.first_name} ${profile?.last_name} as married`}
                    content="Since profile is married, we've stopped showing your profile in online search."
                    actions={[
                        <Button onClick={markUnmarried} primary>
                            Mark Unmarried
                        </Button>,
                    ]}
                />
            )}
            {profile?.status !== "married" && (
                <MessageBox
                    type="info"
                    title="Mark yourself married"
                    content={`If ${profile?.first_name} ${profile?.last_name} is married. mark married here.`}
                    actions={[
                        <Button
                            primary
                            onClick={markMarried}
                            icon
                            labelPosition="left"
                        >
                            <Icon name="checkmark" />
                            Mark Married
                        </Button>,
                    ]}
                />
            )}
        </Screen>
    );
}
