import styles from "./styles/CandidateCard.module.css";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Icon, Label, List, Popup } from "semantic-ui-react";
import { useCreateItem, useDeleteItem } from "../_actions/mutations/items";
import { getProfilePhoto } from "../_helpers/files";
import {
    capitalizeFirstLetter,
    cmToFeetAndInches,
    findAge,
    toStartCase,
} from "../utils";
import { formatTime } from "../_helpers/dt";

function CandidateCard({
    data,
    currUser,
    my_fav,
    mode = "view_profile",
    block_id = null,
    note,
}) {
    const [favorite, setFavorite] = useState(false);
    const navigate = useNavigate("");
    // console.log(my_fav);
    const createFav = useCreateItem(
        "junction_directus_users_qd_matrimonial_profile"
    );

    const removeFav = useDeleteItem(
        "junction_directus_users_qd_matrimonial_profile"
    );

    const removeNoteFav = useDeleteItem("matrimonial_profile_notes");

    const mutationUnblock = useDeleteItem(
        "junction_directus_users_qd_matrimonial_profile_1"
    );

    const handleUnblock = (id) => {
        mutationUnblock.mutate(id, {
            onSuccess: (data) => {
                console.log("sucess");
            },
        });
    };

    const handleRemoveNoteProfile = (id) => {
        removeNoteFav.mutate(id);
    };

    useEffect(() => {
        setFavorite(my_fav);
    }, []);

    // console.log(currUser);
    const toggleFav = (e) => {
        e.stopPropagation();
        if (my_fav) {
            removeFav.mutate(my_fav, {
                onSuccess: () => {
                    setFavorite(false);
                },
            });
        } else {
            createFav.mutate(
                {
                    directus_users_id: currUser,
                    qd_matrimonial_profile_id: data?.id,
                },
                {
                    onSuccess: (data) => {
                        setFavorite(!favorite);
                    },
                }
            );
        }
    };

    const height = useMemo(() => {
        if (data.height > 0) {
            const inches = data.height / 2.54;
            const feet = Math.floor(inches / 12);
            const remainingInches = Math.round(inches % 12);
            return {
                feet: feet,
                inch: remainingInches,
            };
        }
        return null;
    }, [data.height]);

    const mobiles = useMemo(() => {
        const addrs = data.addresses;
        const all = addrs
            .filter((item) => !!item.mobile)
            .map((item) => item.mobile);
        return all || [];
    }, [data?.addresses]);

    return (
        <div
            className={styles.wrapper}
            onClick={(e) => {
                navigate(`/profile/${data?.id}`);
            }}
        >
            {favorite ? (
                <Icon
                    name="heart"
                    className={styles.fav_icon}
                    style={{ color: "red", fontSize: "1.2em" }}
                    onClick={toggleFav}
                />
            ) : (
                <Icon
                    name="heart" //heart outline
                    className={styles.fav_icon}
                    style={{ color: "#ddd", fontSize: "1.2em" }}
                    onClick={toggleFav}
                />
            )}
            <img
                src={getProfilePhoto(data?.main_photo, data?.gender)}
                alt="profile_img"
                className={styles.profile_pic}
            />
            <h3 className={styles.name}>
                {toStartCase(
                    `${data?.first_name}${data?.middle_name ? ` ${data.middle_name}` : ""
                    } ${data?.last_name}`
                )}
            </h3>
            <table className={styles.highlight_table}>
                {data.location && (
                    <tr>
                        <td>
                            <Icon name="map marker alternate" color="blue" />
                        </td>
                        <td>{data?.location?.name}</td>
                    </tr>
                )}
                {data.brithday && (
                    <tr>
                        <td>
                            <List.Icon name="calendar outline" color="red" />
                        </td>
                        <td>{`${data.brithday}, ${formatTime(
                            data.birth_time
                        )}, ${toStartCase(data?.birth_place?.name || "")}`}</td>
                    </tr>
                )}
                {/* {data.brithday && (
                    <tr>
                        <td>
                            <List.Icon name="hourglass icon" color="green" />
                        </td>
                        <td>{findAge(data.brithday)} years</td>
                    </tr>
                )} */}
                {(data.designation || data.organization_name) && (
                    <tr>
                        <td>
                            <List.Icon name="briefcase" color="grey" />
                        </td>
                        <td>{`${data.designation}, ${data.organization_name || ""
                            }`}</td>
                    </tr>
                )}
                {data.income && (
                    <tr>
                        <td>
                            <List.Icon
                                name="money bill alternate outline"
                                color="green"
                            />
                        </td>
                        <td>{`${data.income} ${data.income_currency} per annum`}</td>
                    </tr>
                )}
                {data.education?.name && (
                    <tr>
                        <td>
                            <List.Icon name="graduation cap" color="blue" />
                        </td>
                        <td>{data.education?.name}</td>
                    </tr>
                )}
                {height && (
                    <tr>
                        <td>
                            <Icon
                                name="arrows alternate vertical"
                                color="grey"
                            />
                        </td>
                        <td>{`${height.feet} feet ${height.inch} inch`}</td>
                    </tr>
                )}
                {mobiles.length > 0 &&
                    mobiles.map((mob) => (
                        <tr>
                            <td>
                                <List.Icon name="phone" color="green" />
                            </td>
                            <td>{mob}</td>
                        </tr>
                    ))}
            </table>

            {(!data?.serial_number || data?.serial_number > 1300) && (
                <Label>Not printed in suchi</Label>
            )}
            <div style={{ height: "10px", width: "100%" }}></div>

            <div className={styles.action_bar}>
                {mode === "view_profile" ||
                    mode === "fav" ||
                    mode === "note" ? (
                    <Button
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/profile/${data?.id}`);
                        }}
                        color="red"
                        fluid
                    >
                        View Profile
                    </Button>
                ) : (
                    <Button
                        loading={mutationUnblock.isLoading}
                        onClick={(e) => {
                            e.stopPropagation();
                            // unblock_fun(block_);
                            handleUnblock(block_id);
                        }}
                        style={{
                            cursor: "pointer",
                            backgroundColor: "#EE3435",
                            color: "white",
                            border: "none",
                            padding: "5px",
                            width: "100%",
                        }}
                    >
                        Unblock
                    </Button>
                )}
                {mode === "note" && (
                    <Button
                        loading={removeNoteFav.isLoading}
                        onClick={(e) => {
                            e.stopPropagation();
                            // unblock_fun(block_);
                            handleRemoveNoteProfile(block_id);
                        }}
                        style={{
                            cursor: "pointer",
                            marginTop: "1em",
                            backgroundColor: "#EE3435",
                            color: "white",
                            border: "none",
                            padding: "5px",
                            width: "100%",
                        }}
                    >
                        Remove
                    </Button>
                )}
                <Popup
                    content={
                        favorite ? "Remove from favourite" : "Mark as favourite"
                    }
                    trigger={
                        <Button icon onClick={toggleFav}>
                            {favorite ? (
                                <Icon
                                    name="heart"
                                    style={{ color: "red", fontSize: "1.2em" }}
                                />
                            ) : (
                                <Icon
                                    name="heart" //heart outline
                                    style={{
                                        color: "white",
                                        fontSize: "1.2em",
                                    }}
                                />
                            )}
                        </Button>
                    }
                />
            </div>
        </div>
    );
}

export default CandidateCard;
