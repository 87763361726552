import React, { useState } from "react";
import { useItemsFilterNew } from "../_actions/items";
import {
    Button,
    Dropdown,
    Form,
    Icon,
    Modal,
    TextArea,
} from "semantic-ui-react";
import { useCreateItem } from "../_actions/mutations/items";

function ColdMailSendProfileBtn({ status }) {
    const [open, setOpen] = useState(false);
    const { isSuccess, isLoading, data } = useItemsFilterNew(
        "qd_matrimonial_profile",
        `?fields=id&limit=-1&meta=*&${
            status ? `filter[status][_eq]=${status}` : ""
        }`,
        open,
        false
    );
    const [input, setInput] = useState({
        subject: "",
        body_heading: "",
        body_content: "",
        priority: 3,
    });
    const createMutation = useCreateItem(
        "qdb_cold_mail",
        null,
        () => {},
        () => {
            setOpen(false);
        }
    );

    console.log("Email list", data);

    const sendMails = () => {
        if (isSuccess) {
            const ids = data.data.map((item) => ({
                // qd_matrimonial_profile_id: 505,
                qd_matrimonial_profile_id: item.id,
                priority: input.priority,
            }));
            const out = {
                ...input,
                body_content: input.body_content?.replace(/\n/g, "<br/>"),
                to_profile: ids,
                // to_profile: [{ qd_matrimonial_profile_id: 505 }],
            };
            // console.log("OUT", out);
            createMutation.mutate(out);
        }
    };

    return (
        <div>
            <Modal
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                open={open}
                trigger={
                    <Button icon disabled={isSuccess && data.data.length === 0}>
                        <Icon name="mail" />
                    </Button>
                }
            >
                <Modal.Header>Send Bulk Mails</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Group>
                            <Form.Field width={12} required>
                                <label>Subject</label>
                                <input
                                    placeholder="Subject"
                                    value={input.subject}
                                    onChange={(e) =>
                                        setInput({
                                            ...input,
                                            subject: e.target.value,
                                        })
                                    }
                                />
                            </Form.Field>
                            <Form.Field width={4}>
                                <label>Priority</label>
                                <Dropdown
                                    selection
                                    compact
                                    options={[
                                        { key: 1, text: "Very high", value: 1 },
                                        { key: 2, text: "High", value: 2 },
                                        { key: 3, text: "Normal", value: 3 },
                                        { key: 4, text: "Low", value: 4 },
                                        { key: 5, text: "Very low", value: 5 },
                                    ]}
                                    value={input.priority}
                                    onChange={(e, { value }) =>
                                        setInput({ ...input, priority: value })
                                    }
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Field>
                            <label>Body Heading</label>
                            <input
                                placeholder="Body heading"
                                value={input.body_heading}
                                onChange={(e) =>
                                    setInput({
                                        ...input,
                                        body_heading: e.target.value,
                                    })
                                }
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Body Content</label>
                            <TextArea
                                rows={10}
                                value={input.body_content}
                                onChange={(e) =>
                                    setInput({
                                        ...input,
                                        body_content: e.target.value,
                                    })
                                }
                            />
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <div
                            style={{
                                fontWeight: "bold",
                                fontSize: "1.1em",
                                display: "flex",
                                flexDirection: "column",
                                gap: "7px",
                                alignItems: "flex-start",
                            }}
                        >
                            {isLoading && "Loading receipient list..."}
                            {isSuccess && (
                                <span>{`Total receipients: ${data?.meta?.filter_count}`}</span>
                            )}
                            {isSuccess && (
                                <span>{`Estimated duration: ${Math.floor(
                                    data?.meta?.filter_count / 60
                                )} hours ${
                                    data?.meta?.filter_count % 60
                                } minutes`}</span>
                            )}
                        </div>
                        <div style={{ display: "flex", gap: "5px" }}>
                            <Button
                                color="black"
                                onClick={() => setOpen(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                content="Send Bulk Mail"
                                labelPosition="right"
                                icon="arrow right"
                                onClick={sendMails}
                                positive
                                disabled={
                                    !isSuccess ||
                                    input.subject.length === 0 ||
                                    // input.body_content.length === 0 ||
                                    createMutation.isLoading
                                }
                                loading={isLoading || createMutation.isLoading}
                            />
                        </div>
                    </div>
                </Modal.Actions>
            </Modal>
        </div>
    );
}

export default ColdMailSendProfileBtn;
