import React from "react";
import { Button, Header, Icon, Modal } from "semantic-ui-react";
import SearchPreference from "../SearchPreference";

export default function SearchPreferenceModal(prop) {
    return (
        <Modal
            onClose={() => prop.setOpen(false)}
            onOpen={() => prop.setOpen(true)}
            open={prop.open}
        >
            <Header>{prop.title}</Header>
            <Modal.Content>{prop.cmp}</Modal.Content>
            <Modal.Actions>
                <Button
                    basic
                    color="red"
                    inverted
                    onClick={() => prop.setOpen(false)}
                >
                    <Icon name="remove" /> No
                </Button>
                <Button
                    color="green"
                    inverted
                    onClick={() => {
                        prop.action();
                        prop.search_action();
                        prop.setOpen(false);
                    }}
                >
                    <Icon name="checkmark" /> {prop.action_label || "Yes"}
                </Button>
            </Modal.Actions>
        </Modal>
    );
}
